import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import Swal from 'sweetalert2';
import { PasswordService } from '../password.service';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';
const KS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-service-provider-description',
  templateUrl: './service-provider-description.component.html',
  styleUrls: ['./service-provider-description.component.css'],
})
export class ServiceProviderDescriptionComponent implements OnInit {
  //variable
  profileID: string = '';
  serviceProviderDescription: any;
  edit: boolean = false;
  email: string = '';
  nhgName: string = '';
  accountHolderName = '';
  upiID: string = '';
  adminCategory = [
    { value: 'type_1', label: 'District Farmlivelihood' },
    { value: 'type_2', label: 'District NULM' },
    { value: 'type_3', label: 'District ME' },
    { value: 'type_4', label: 'District SVEP' },
    { value: 'type_5', label: 'District Tribal' },
    { value: 'type_6', label: 'District SD' },
    { value: 'type_7', label: 'District AH' },
  ];

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  setCategoryLabel(): void {
    switch (this.category) {
      case 'type_1':
        this.categoryLabel = 'District Farmlivelihood';
        break;
      case 'type_2':
        this.categoryLabel = 'District NULM';
        break;
      case 'type_3':
        this.categoryLabel = 'District ME';
        break;
      case 'type_4':
        this.categoryLabel = 'District SVEP';
        break;
      case 'type_5':
        this.categoryLabel = 'District Tribal';
        break;
      case 'type_6':
        this.categoryLabel = 'District SD';
        break;
      case 'type_7':
        this.categoryLabel = 'District AH';
        break;
      default:
        this.categoryLabel = 'Unknown';
    }
  }

  //variables
  meUnitName: string = '';
  phoneNumber1: Number = null;
  whatsAppNumber: Number = null;
  localBodyType: string = '';
  localBodyName: string = '';
  wardName: string = '';
  wardNumber: string = '';
  districtName: string = '';
  accountDeleted: boolean = false;
  bankPassBookUrl: string[] = [];
  cdsDocumentUrl: string[] = [];

  gstDocumentUrl: string[] = [];
  GITagDocumentUrl: string[] = [];
  tradeMarkDocumentUrl: string[] = [];
  UdyamDocumentsProofUrls: string[] = [];

  category: string = '';
  bankIFSCcode: string = '';
  bankAccountNumber: string = '';
  bankBranch: string = '';
  bankName: string = '';
  meAppID: string = '';
  fssaiID: string = '';
  GSTNumber: string = '';
  CDSName: string = '';
  CDSNumber: string = '';
  buildingName: string = '';
  streetName: string = '';
  landmark: string = '';
  pincode: string = '';
  approvalStatus: string = '';
  categoryLabel: string = '';
  accountDelete: boolean = false;

  spinnerButtonForUpdate: boolean = false;

  spinnerForServiceProviderDescription: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private activatedRoute: ActivatedRoute,
    private QsIDsService: QsIDsService
  ) {}

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.profileID = queryParams['profileID'];
      console.log('Profile ID:', this.profileID);
    });

    if (this.profileID) {
      this.getServiceProviderDescription();
    }
  }

  roleTypeLable: any = null;
  roleType: any = null;
  onAdminCategorySelected(event: any) {
    // Set the selected roleType Lable
    this.roleTypeLable = event.target.options[event.target.selectedIndex].text;
    console.log('the roleTypeLable is', this.roleTypeLable);
    // Set the selected roleType value
    this.roleType = event.target.value;
    console.log('the roleType is', this.roleType);
  }

  districtLabel: any = null;
  districtValue: any = null;
  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
    console.log('this.districtLabel', this.districtLabel);
    console.log('this.districtValue', this.districtValue);
    this.districtName = this.districtValue;
    console.log('this.districtName', this.districtName);
  }

  passwordReset() {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const profileID = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to reset the password?',
      text: 'The new password is: ' + passwordReset,
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set('newKeyword', passwordReset)
          .set('profileID', profileID);

        const options = { headers, params };
        this.http
          .put(KS_ADMIN_URL + '/passwordResetKPSeller', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  deleteAccount() {
    const profileID = this.profileID;

    Swal.fire({
      title: 'Are you sure you want to delete the account?',
      icon: 'question',
      showCancelButton: true, // Display the cancel button
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel', // Text for the cancel button
      confirmButtonColor: 'rgb(38 117 79)',
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );
        const params = new HttpParams().set('profileID', profileID);

        const options = { headers, params };
        this.http
          .put(KS_ADMIN_URL + '/deleteAccountForSeller', {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
              this.getServiceProviderDescription();
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Password reset cancelled', '', 'info');
      }
    });
  }

  goBack() {
    this.location.back();
  }

  enableEdit() {
    this.edit = true;
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkAgeLength() {
    // Ensure age is within 0-99 range
    if (this.wardNumber.length > 6) {
      this.wardNumber = this.wardNumber.slice(0, 2);
    }
  }

  checkPincodeLength() {
    // Ensure age is within 0-99 range
    if (this.pincode.length > 7) {
      this.pincode = this.pincode.slice(0, 7);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  checkExperience(input: HTMLInputElement) {
    if (input.value.length > 2) {
      input.value = input.value.slice(0, 2);
      // this.experience = parseInt(input.value, 2);
    }
  }

  //to get the details
  getServiceProviderDescription() {
    this.spinnerForServiceProviderDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);
    this.http
      .get(KS_ADMIN_URL + '/kpSellerDescription', { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForServiceProviderDescription = false;
          this.serviceProviderDescription = data;
          console.log(
            'serviceProviderDescription',
            this.serviceProviderDescription
          );

          this.accountDelete = this.serviceProviderDescription.accountSatus;

          this.meUnitName = this.serviceProviderDescription.meUnitName;
          this.phoneNumber1 = this.serviceProviderDescription.phoneNumber;
          this.whatsAppNumber = this.serviceProviderDescription.whatsappNumber;
          this.localBodyType = this.serviceProviderDescription.localBodyType;
          this.localBodyName = this.serviceProviderDescription.localBodyName;

          this.wardName = this.serviceProviderDescription.address.wardName;
          this.wardNumber = this.serviceProviderDescription.address.wardNumber;

          this.accountDeleted = this.serviceProviderDescription.accountSatus;

          this.category = this.serviceProviderDescription.category;

          console.log('the category', this.category);

          if (this.category) {
            this.setCategoryLabel();
          }

          this.bankIFSCcode =
            this.serviceProviderDescription.bankDetails.ifscCode;
          this.bankAccountNumber =
            this.serviceProviderDescription.bankDetails.accountNumber;
          this.bankBranch =
            this.serviceProviderDescription.bankDetails.bankBranch;
          this.bankName = this.serviceProviderDescription.bankDetails.bankName;

          this.meAppID = this.serviceProviderDescription.meAppID;
          this.fssaiID = this.serviceProviderDescription.fssaiID;
          this.GSTNumber = this.serviceProviderDescription.gstNumber;
          this.CDSName = this.serviceProviderDescription.cdsName;
          this.CDSNumber =
            this.serviceProviderDescription.cdsRegistrationNumber;

          this.buildingName =
            this.serviceProviderDescription.address.buildingName;
          this.streetName = this.serviceProviderDescription.address.streetName;
          this.landmark = this.serviceProviderDescription.address.landMark;
          this.pincode = this.serviceProviderDescription.address.pinCode;
          this.districtName = this.serviceProviderDescription.address.district;

          this.approvalStatus = this.serviceProviderDescription.approvalStatus;
          this.cdsDocumentUrl =
            this.serviceProviderDescription.cdsDocumentsProofUrls;
          this.bankPassBookUrl =
            this.serviceProviderDescription.bankDetails.bankPassBookUrl;
          this.gstDocumentUrl =
            this.serviceProviderDescription.gstDocumentsProofUrls;

          this.GITagDocumentUrl =
            this.serviceProviderDescription.GITagDocumentsProofUrls;
          this.tradeMarkDocumentUrl =
            this.serviceProviderDescription.tradeMarkDocumentsProofUrls;
          this.UdyamDocumentsProofUrls =
            this.serviceProviderDescription.UdyamDocumentsProofUrls;
          this.nhgName = this.serviceProviderDescription.nhgName;
          this.upiID = this.serviceProviderDescription.bankDetails.upiID;
          this.accountHolderName =
            this.serviceProviderDescription.bankDetails.accountHolderName;
          this.email = this.serviceProviderDescription.email;
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForServiceProviderDescription = false;
          }
          // Handle error response
        }
      );
  }

  updateSellerDetaails() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams().set('profileID', this.profileID);

    const updatedData = {
      meUnitName: this.meUnitName,
      phoneNumber: this.phoneNumber1,
      whatsAppNumber: this.whatsAppNumber,
      localBodyType: this.localBodyType,
      localBodyName: this.localBodyName,
      wardName: this.wardName,
      wardNumber: this.wardNumber,
      districtName: this.districtName,
      category: this.category,
      bankIFSCcode: this.bankIFSCcode,
      bankAccountNumber: this.bankAccountNumber,
      bankBranch: this.bankBranch,
      bankName: this.bankName,
      meAppID: this.meAppID,
      fssaiID: this.fssaiID,
      GSTNumber: this.GSTNumber,
      CDSName: this.CDSName,
      CDSNumber: this.CDSNumber,
      buildingName: this.buildingName,
      streetName: this.streetName,
      landmark: this.landmark,
      pincode: this.pincode,
      approvalStatus: this.approvalStatus,
      accountHolderName: this.accountHolderName,
      email: this.email,
      upiID: this.upiID,
      nhgName: this.nhgName,
    };

    // Display SweetAlert confirmation dialog
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to update the seller details',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerButtonForUpdate = true;
        // If user clicks OK, execute the API request
        this.http
          .put(KS_ADMIN_URL + '/update/SellerDetails', updatedData, {
            headers,
            params,
          })
          .subscribe(
            (response) => {
              console.log('Update successful', response);
              // Handle success response if needed
              Swal.fire(
                'Success!',
                'Seller description updated successfully.',
                'success'
              );
              this.getServiceProviderDescription();
              this.edit = false;
              this.spinnerButtonForUpdate = false;
            },
            (error) => {
              console.error('Error', error);
              // Handle error response
              Swal.fire(
                'Error!',
                'Failed to update seller description.',
                'error'
              );
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.Authservice.logOut();
              } else {
                console.error('Error fetching MEUnitProfilePage:', error);
                this.spinnerButtonForUpdate = false;
              }
            }
          );
      }
    });
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }
}
