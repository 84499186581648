<div class="backgroundContainer">
  <div class="container card">
    <div class="row">
      <div class="col me-4 d-flex align-items-center">
       
        <p class="quickServeDescription">
          Kudumbashree offers a range of high-quality products crafted by
          women's collectives across Kerala. Our collection includes organic
          agricultural produce, handmade textiles and jewelry, delicious pickles
          and snacks, natural personal care items, and eco-friendly home
          essentials. Each product embodies quality, sustainability, and
          traditional Kerala craftsmanship. By choosing Kudumbashree, you
          support women's empowerment and local economic growth. Experience the
          essence of Kerala with every purchase.
        </p>
      </div>
      <div class="col">
        <div class="div logoOfPocketMart">
          <img
            class="logoImage"
            src="../../../assets/logo/pocketmart.png"
            alt="pocketMard logo"
          />
        </div>
        <p style="margin-bottom: 10px">Welcome to</p>
        <p class="quickServe"><b>Kudumbashree Products</b></p>
        <p class="dashboard">
          (Dashboard login for state admin, district admin)
        </p>
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">User name</label>
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              [(ngModel)]="username"
              maxlength="50"
              aria-describedby="emailHelp"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Enter your user name"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label"
              >Password</label
            >
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="password"
              maxlength="40"
              placeholder="Enter your password"
            />
          </div>

          <button
            *ngIf="!spinnerForLoginButton"
            (click)="onLogin()"
            class="btn btn-success loginButton"
          >
            Login
          </button>

          <!--spinner for login button-->
          <button
            style="float: right"
            *ngIf="spinnerForLoginButton"
            class="btn btn-success"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              aria-hidden="true"
            ></span>
            <span role="status">Loading...</span>
          </button>
          <!--spinner for login button-->
        </form>
      </div>
    </div>
    <div class="row"></div>
  </div>
</div>
