import { Component, OnInit } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Route, Router } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import Swal from "sweetalert2";
import { DatePipe } from "@angular/common";
import { QsIDsService } from "../../services/qs-ids.service";
import { ServiceRequestListingsService } from "../../services/service-request-listings.service";
import { DateAdapter } from '@angular/material/core';

import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-service-request-listings",
  templateUrl: "./service-request-listings.component.html",
  styleUrls: ["./service-request-listings.component.css"],
  providers: [DatePipe],
})
export class ServiceRequestListingsComponent implements OnInit {
  //variables
  startDate: any = null;
  endDate: any = null;
  productList: any;
  spinnerForSearch: boolean = false;

  pendingCheckBox: boolean = false;
  processingCheckBox: boolean = false;
  assignedCheckBox: boolean = false;
  acceptedCheckBox: boolean = false;
  completedCheckBox: boolean = false;
  cancelledCheckBox: boolean = false;

  maxDate: Date;

  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case "type_1":
        return "District Farmlivelihood";
      case "type_2":
        return "District NULM";
      case "type_3":
        return "District ME";
      case "type_4":
        return "District SVEP";
      case "type_5":
        return "District Tribal";
      case "type_6":
        return "District SD";
      case "type_7":
        return "District AH";
      default:
        return "Unknown Role";
    }
  }

  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>
  ) {
      this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();
    if (startDate) {
      console.log("service has start date");
      this.startDate = startDate;
      this.endDate = endDate;
      this.checkUser();
      // this.searchForServiceListings();
    }

    if (!startDate) {
      this.startDate = new Date();
      this.ServiceRequestListingsService.setDates(
        this.startDate,
        this.startDate
      );

      this.checkUser();
    }

    // this.filterServiceListings();
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
    this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
  }

  checkUser() {
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.searchForProductsListings();
    } else if (this.isDistrictAdmin()) {
      this.searchForProductsListingsForDistrictAdmin();
    } else if (this.isMarketingAdmin()) {
      this.searchForProductsListingsForMarketingDistrictAdmin();
    } else {
      console.log("please check login");
    }
  }

  searchForProductsListings() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire("Please select start date");
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForServiceListings(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.productList = data;
        this.spinnerForSearch = false;
        console.log("the request list", this.productList);
      },
      (error) => {
        console.error("Error", error);
        if (error.status === 401) {
          console.error("Unauthorized - logging out");
          this.Authservice.logOut();
        } else {
          console.error("Error fetching MEUnitProfilePage:", error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  //to get all the district admin  products
  searchForProductsListingsForDistrictAdmin() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire("Please select start date");
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForProductsListingsForDistrictAdmin(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.productList = data;
        this.spinnerForSearch = false;
        console.log("the request list", this.productList);
      },
      (error) => {
        console.error("Error", error);
        if (error.status === 401) {
          console.error("Unauthorized - logging out");
          this.Authservice.logOut();
        } else {
          console.error("Error fetching MEUnitProfilePage:", error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }


  // to get the details marketing admin 
  searchForProductsListingsForMarketingDistrictAdmin() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      Swal.fire("Please select start date");
      return;
    }

    if (!endDate) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    } else if (new Date(endDate) < new Date(startDate)) {
      this.ServiceRequestListingsService.setDates(startDate, startDate);
    }

    const token = this.Authservice.getToken();
    this.spinnerForSearch = true;

    this.ServiceRequestListingsService.searchForProductsListingsForMarketingDistrictAdmin(
      token
    ).subscribe(
      (data: any) => {
        console.log(data);
        this.productList = data;
        this.spinnerForSearch = false;
        console.log("the request list", this.productList);
      },
      (error) => {
        console.error("Error", error);
        if (error.status === 401) {
          console.error("Unauthorized - logging out");
          this.Authservice.logOut();
        } else {
          console.error("Error fetching MEUnitProfilePage:", error);
          this.spinnerForSearch = false;
        }
        // Handle error response
      }
    );
  }

  goToProductDescription(ID: string) {
    this.router.navigate(["/productDescription"], {
      queryParams: {
        productID: ID,
      },
    });
  }

  convertUtcToIst(dateStr: string): string {
    let date = new Date(dateStr);
    let istDate = new Date(date.getTime() + (5 * 60 + 30) * 60000);
    return this.datePipe.transform(istDate, "yyyy-MM-dd");
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }
}
