import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
  constructor(
    private authService: AuthServiceService,
    private router: Router
  ) {}

  //variables
  username: string = '';
  password: string = '';
  error: string = '';
  spinnerForLoginButton: boolean = false;
  //---------------------

  // to login ---------------------------
  onLogin(): void {
    if (this.username === '' || this.password === '') {
      return alert('All input fields are required');
    }

    const user = {
      username: this.username,
      password: this.password,
    };

    console.log('the login in frontend is working');
    this.spinnerForLoginButton = true;
    console.log(user);
    this.authService.login(user).subscribe(
      (res) => {
      
        console.log(res, 'res');
        if (res.message == 'Invalid credentials') {
          this.clearFields();
          this.spinnerForLoginButton = false;
          return alert('Invalid credentials');
        }
        if (res.message == 'User not found') {
          this.clearFields();
          this.spinnerForLoginButton = false;
          return alert('User not found');
        } else {
          const expiresIn = res.expiresIn;
          this.authService.saveToken(res.token, expiresIn);
          this.spinnerForLoginButton = false;
          localStorage.setItem('name', this.username);
          this.router.navigate(['/analyticsPage']);
        }
      },
      (error) => {
        this.spinnerForLoginButton = false;
        this.error = error.error.message;
        alert('Error in login please try again later');
      }
    );
  }
  //-------------------------------------------------

  clearFields() {
    this.username = '';
    this.password = '';
  }
}
