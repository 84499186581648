import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { environment } from 'src/environments/environment';
const KP_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-ulb-listings',
  templateUrl: './ulb-listings.component.html',
  styleUrls: ['./ulb-listings.component.css'],
})
export class UlbListingsComponent {
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient
  ) {}

  sellerListings: any;
  spinnerForListingPage: boolean = false;


  ngOnInit() {
    this.initializeBasedOnUserRole();
  }

  initializeBasedOnUserRole() {
    if (this.isDistrictAdmin()) {
      this.getULBListings();
    } else if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getSellerListingsForStateAdmin();
    }

    else if (this.isMarketingAdmin()) {
      this.getSellerListingsForMarketingAdmin();
    }
  }


  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case 'type_1':
        return 'District Farmlivelihood';
      case 'type_2':
        return 'District NULM';
      case 'type_3':
        return 'District ME';
      case 'type_4':
        return 'District SVEP';
      case 'type_5':
        return 'District Tribal';
      case 'type_6':
        return 'District SD';
      case 'type_7':
        return 'District AH';
      default:
        return 'Unknown Role';
    }
  }

  getULBListings() {

    this.spinnerForListingPage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(KP_ADMIN_URL + '/sellerListings', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.sellerListings = data;
          console.log('sellerListings', this.sellerListings);
          this.spinnerForListingPage = false;
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForListingPage = false;
          }
        }
      );
  }

  goToDetails(profileID: string) {
    this.router.navigate(['/sellerDescription'],{queryParams:{profileID:profileID}});
  }

  getSellerListingsForStateAdmin() {
    this.spinnerForListingPage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(KP_ADMIN_URL + '/sellerListStateAdmin', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.sellerListings = data;
          console.log('sellerListings', this.sellerListings);
          this.spinnerForListingPage = false;
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForListingPage = false;
          }
          // Handle error response
        }
      );
  }


  
  getSellerListingsForMarketingAdmin() {
    this.spinnerForListingPage = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http
      .get(KP_ADMIN_URL + '/sellerListmarketingDistrictAdmin', { headers }) // Changed from post to get
      .subscribe(
        (data) => {
          this.sellerListings = data;
          console.log('sellerListings', this.sellerListings);
          this.spinnerForListingPage = false;
          // Handle success response if needed
        },
        (error) => {
          console.error('Error', error);
          if (error.status === 401) {
            console.error('Unauthorized - logging out');
            this.Authservice.logOut();
          } else {
            console.error('Error fetching MEUnitProfilePage:', error);
            this.spinnerForListingPage = false;
          }
          // Handle error response
        }
      );
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ('0' + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

}
