import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { AuthServiceService } from 'src/app/auth-service.service';
import { PasswordService } from '../password.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { QsIDsService } from '../../services/qs-ids.service';
import { environment } from 'src/environments/environment';

const KP_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: 'app-add-service-provider',
  templateUrl: './add-service-provider.component.html',
  styleUrls: ['./add-service-provider.component.css'],
})
export class AddServiceProviderComponent implements OnInit {
  //variable for basic user name
  meUnitName: string = '';
  phoneNumber1: number = null;
  meAppID: number = null;
  whatsAppNumber: number = null;
  keyword: string = '';
  fssaiID: string = '';
  defaultImage: string = '../../../assets/profileImage/profileImage.png';
  selectedImage: any | null = null;
  cdsDocuments: File | null = null;
  gstDocuments: File | null = null;

  tradeMark: File | null = null;

  UdyamRegistration: File | null = null;
  GITag: File | null = null;
  fssai: File | null = null;

  selectedIdProofFile: File | null = null;
  selectedAddressProofFile: File | null = null;

  //variables for the bank details
  bankName: string = '';
  bankBranch: string = '';
  bankAccountNumber: number = null;
  bankIFSCcode: string = '';
  bankProofFile: File | null = null;

  //variables for the NHG
  GSTNumber: any = '';
  buildingName: string = '';
  streetName: string = '';
  pincode: string = '';
  landmark: string = '';

  //varibles for auxiliary group
  CDSName: string = '';
  CDSNumber: string = '';

  //variables for local body
  localBodyType: string = 'Panchayat'; // Default value
  localBodyName: string = '';
  wardName: string = '';
  wardNumber: string = '';
  districtName: string = '';

  sellerwhatsApp: any;
  sellerMessage: string;
  password: any;

  blockName: string = '';
  UdyamRegistrationNumber: string = ''; //
  profileID: string = '';

  //spinner variables
  spinner: boolean = false;
  showWhatsAppButton: boolean = false;

  edit: boolean = true;
  accountHolderName: string = '';
  upiID: string = '';
  nhgName: string = '';
  email: string = '';
  districtOFDistrictAdmin: any[] = [];

  //BLOCK, PANCHAYAT, CORPORATION selection starts----------------------------------------------------------------

  fetchDistricts(): void {
    this.http
      .get<any>('assets/cloudKitchenPlaces/districtBlocks/districts.json')
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.districtName) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(this.districtOFDistrictAdmin);
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }

  fetchDistrictsforMunicipalCorporation(): void {
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json'
      )
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
        this.districtOFDistrictAdmin = [];
        for (let i = 0; i < this.districts.length; i++) {
          if (this.districts[i].name === this.districtName) {
            this.districtOFDistrictAdmin[0] = this.districts[i];
            console.log(this.districtOFDistrictAdmin);
            break; // Stop the loop once you find the matching district
          }
        }
      });
  }

  fetchBlocksForDistrict(districtId: string): void {
    console.log('districtId: ', districtId);
    this.http
      .get<any>('assets/cloudKitchenPlaces/districtBlocks/blocks.json')
      .subscribe((data) => {
        this.blocks = data[districtId];
        console.log(this.blocks);
      });
  }
  tempBlocks: any;
  fetchGramPanchayatsForDistrict(districtId: string): void {
    console.log(districtId);
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/gramaPanchayats/' + districtId + '.json'
      )
      .subscribe((data) => {
        console.log(data);

        this.tempBlocks = data[`${this.selectedBlock}`];
        console.log(this.tempBlocks);
        this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
        this.gramPanchayats = this.tempBlocks;
        console.log(this.gramPanchayats);
      });
  }

  onDistrictChange(): void {
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.selectedMunicipalityId = '';
    this.selectedMunicipalCorporationId = '';
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      this.districtName = this.selectedDistrict.name;
      console.log('District Name: ', this.districtName);
      if (this.selectedLevel === 'municipalityLevel') {
        this.selectedMunicipality =
          this.municipalities[this.selectedDistrict.id];
        console.log('municipality', this.selectedMunicipality);

        return;
      } else if (this.selectedLevel == 'municipalCorporation') {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict.id];
        console.log('municipalCorporation', this.selectedMunicipalCorporation);

        return;
      } else {
        console.log('this.selectedDistrict', this.selectedDistrict.id);

        this.fetchBlocksForDistrict(this.selectedDistrict.id);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
      }
    }
  }

  selectedBlk: any = '';
  onBlockChange(): void {
    this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
    this.selectedBlock = this.selectedBlk.id;
    this.blockName = this.selectedBlk.name;
    console.log(this.selectedBlock);

    console.log('onBlockchnge', this.selectedDistrict.id);
    this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ''; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedBlock]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log(this.gramPanchayats);
    }
  }

  selectedGP: any = '';
  onPChange(): void {
    this.selectedGramPanchayat = this.selectedGP.name;
    this.localBodyName = this.selectedGramPanchayat;
    // this.blockName=this.selectedBlk.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('blockName: ', this.blockName);
    console.log('Local body name: ', this.localBodyName);
    // console.log(
    //   this.districtName,
    //   this.blockName,

    //   this.selectedGramPanchayat
    // );
  }

  // For Municipal Level

  municipalities: any;
  selectedMunicipality: any;
  selectedMunicipalityId: any;

  fetchMunicipalitiesForDistrict(): void {
    this.http
      .get<any>('assets/cloudKitchenPlaces/municipalities/municipalities.json')
      .subscribe((data) => {
        this.municipalities = data.municipalities;
        console.log(data.municipalities);
      });
  }

  selectedLevel: string = 'blockLevel'; // Property to store the selected level
  legalBody: string;
  districts: any[] = [];
  blocks: any;
  gramPanchayats: any = {};

  selectedDistrict: any = '';
  selectedBlock: string = '';
  selectedGramPanchayat: string = '';

  gramPanchayatsLoaded: boolean = false;

  // Method to handle radio button selection
  // For Municipal Level

  // Method to handle radio button selection
  onLevelSelection(selectedLevel: string): void {
    this.selectedDistrict = '';
    this.selectedBlock = ''; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = '';
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;
    this.selectedLevel = selectedLevel;
    this.selectedMunicipalCorp = '';

    console.log(this.selectedMunicipalityId);

    if (this.selectedLevel === 'blockLevel') {
      this.legalBody = 'PAN';
      this.localBodyType = 'Panchayat';
      this.fetchDistricts();
      // this.localBodyType = 'Panchayat';
    }
    if (this.selectedLevel === 'municipalityLevel') {
      this.legalBody = 'MUN';
      this.localBodyType = 'Municipality';
      this.fetchDistricts();
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === 'municipalCorporation') {
      this.legalBody = 'MNC';
      this.localBodyType = 'Corporation';

      this.fetchDistrictsforMunicipalCorporation();
      this.fetchMunicipalCorporationsForDistrict();
    }
    console.log('legalBody', this.legalBody);
  }
  selectedMunicipal: any = '';

  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log('Selected municipality:', this.selectedMunicipal.name);
    this.selectedMunicipalityId = this.selectedMunicipal.id;
    this.localBodyName = this.selectedMunicipal.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('Local body name: ', this.localBodyName);

    // You can perform additional actions here based on the selected municipality
  }

  selectedMunicipalCorp: any = '';
  onMunicipalCorporationChange(): void {
    this.selectedMunicipalCorporationId = this.selectedMunicipalCorp.id;
    this.localBodyName = this.selectedMunicipalCorp.name;
    console.log('Local Body Type: ', this.localBodyType);
    console.log('District: ', this.districtName);
    console.log('Local body name: ', this.localBodyName);
    // Implement actions to handle the change in selected municipality
    //  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }

  //For Municipal Corporation Level;
  municipalCorporations: any = {};
  selectedMunicipalCorporation: any;
  selectedMunicipalCorporationId: any;

  fetchMunicipalCorporationsForDistrict(): void {
    // Assuming you are fetching data from a JSON file named municipal_corporations.json
    this.http
      .get<any>(
        'assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json'
      )
      .subscribe((data) => {
        this.municipalCorporations = data.municipal_corporations || {};
        console.log(this.municipalCorporations);
      });
  }

  //BLOCK, PANCHAYAT, CORPORATION selection ends----------------------------------------------------------------

  // districts = [
  //   { value: 'Alappuzha', label: 'Alappuzha' },
  //   { value: 'Ernakulam', label: 'Ernakulam' },
  //   { value: 'Idukki', label: 'Idukki' },
  //   { value: 'Kannur', label: 'Kannur' },
  //   { value: 'Kasaragod ', label: 'Kasaragod' },
  //   { value: 'Kollam', label: 'Kollam' },
  //   { value: 'Kottayam', label: 'Kottayam' },
  //   { value: 'Kozhikode', label: 'Kozhikode' },
  //   { value: 'Malappuram', label: 'Malappuram' },
  //   { value: 'Palakkad', label: 'Palakkad' },
  //   { value: 'Pathanamthitta', label: 'Pathanamthitta' },
  //   { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
  //   { value: 'Thrissur', label: 'Thrissur' },
  //   { value: 'Wayanad', label: 'Wayanad' },
  // ];

  adminCategory = [
    { value: 'type_1', label: 'District Farmlivelihood' },
    { value: 'type_2', label: 'District NULM' },
    { value: 'type_3', label: 'District ME' },
    { value: 'type_4', label: 'District SVEP' },
    { value: 'type_5', label: 'District Tribal' },
    { value: 'type_6', label: 'District SD' },
    { value: 'type_7', label: 'District AH' },
  ];

  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService,
    private QsIDsService: QsIDsService,
    private router: Router
  ) {}

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  districtLabel: any = null;
  districtValue: any = null;
  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
    console.log('this.districtLabel', this.districtLabel);
    console.log('this.districtValue', this.districtValue);
    this.districtName = this.districtValue;
    console.log('this.districtName', this.districtName);
  }

  roleTypeLable: any = null;
  roleType: any = null;
  onAdminCategorySelected(event: any) {
    // Set the selected roleType Lable
    this.roleTypeLable = event.target.options[event.target.selectedIndex].text;
    console.log('the roleTypeLable is', this.roleTypeLable);
    // Set the selected roleType value
    this.roleType = event.target.value;
    console.log('the roleType is', this.roleType);
  }

  validateAge(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  checkAgeLength() {
    // Ensure age is within 0-99 range
    if (this.wardNumber.length > 6) {
      this.wardNumber = this.wardNumber.slice(0, 2);
    }
  }

  checkPincodeLength() {
    // Ensure age is within 0-99 range
    if (this.pincode.length > 7) {
      this.pincode = this.pincode.slice(0, 7);
    }
  }

  //---------------------------------------------------------------

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  allowOnlyAlphanumeric(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only alphanumeric characters (a-z, A-Z, 0-9)
    if (!/^[a-zA-Z0-9]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  allowOnlyAlphanumericAndSpace(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.charCode);

    // Allow only alphanumeric characters (a-z, A-Z, 0-9)
    if (!/^[a-zA-Z0-9 ]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngOnInit() {
    const timestamp = Date.now();
    const formattedTimestamp = new Date(timestamp)
      .toISOString()
      .replace(/[^\d]/g, '');
    const profileID = 'SP_ID' + formattedTimestamp.slice(4, 14);

    this.profileID = profileID;

    console.log('the profileID is ' + this.profileID);

    if (this.isDistrictAdmin()) {
      this.adminDistrict();
      this.adminRoleType();
    }

    if (this.isMarketingAdmin()) {
      this.adminDistrict();
    }

    this.fetchDistricts();

    // this.getMEUnitDescription();
  }

  adminDistrict() {
    const district = this.Authservice.getDecodedDistrict();
    if (district) {
      console.log('Decoded district value:', district);
      this.districtName = district;
    } else {
      console.log('District information not available.');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please login again.',
        confirmButtonText: 'OK',
      });
      // this.onLogout();
    }
  }

  adminRoleType() {
    const roleType = this.Authservice.getDecodedRoleType();
    if (roleType) {
      console.log('Decoded district value:', roleType);
      this.roleType = roleType;
    } else {
      console.log('District information not available.');
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong. Please login again.',
        confirmButtonText: 'OK',
      });
      // this.onLogout();
    }
  }

  onLogout() {
    this.Authservice.logOut();
  }

  checkphone1(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.phoneNumber1 = parseInt(input.value, 10);
    }
  }

  checkGSTNumber(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.GSTNumber = parseInt(input.value, 10);
    }
  }

  checkWhatsApp(input: HTMLInputElement) {
    if (input.value.length > 10) {
      input.value = input.value.slice(0, 10);
      this.whatsAppNumber = parseInt(input.value, 10);
    }
  }

  createPassword() {
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }

  onFileSelected(event: Event, proofType: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 2) {
        Swal.fire({
          icon: 'error',
          title: 'File Size Exceeded',
          text: 'The selected file exceeds the maximum allowed size of 2MB. Please reduce the file size.',
        });
        // Clear the file input
        input.value = '';
        if (proofType === 'addressProof') {
          this.selectedAddressProofFile = null;
        } else if (proofType === 'idProof') {
          this.selectedIdProofFile = null;
        } else if (proofType === 'bankPassBook') {
          this.bankProofFile = null;
        } else if (proofType === 'cdsDocuments') {
          this.cdsDocuments = null;
        } else if (proofType === 'gstDocuments') {
          this.gstDocuments = null;
        } else if (proofType === 'fssai') {
          this.fssai = null;
        } else if (proofType === 'GITag') {
          this.GITag = null;
        } else if (proofType === 'tradeMark') {
          this.tradeMark = null;
        } else if (proofType === 'UdyamRegistration') {
          this.UdyamRegistration = null;
        } else if (proofType === 'profileImage') {
          this.selectedImage = null;
        }
      } else {
        // Construct the new file name
        const originalName = file.name;
        const newFileName = `${this.profileID}_${proofType}`;

        // Create a new File object with the new name
        const renamedFile = new File([file], newFileName, { type: file.type });

        if (proofType === 'addressProof') {
          this.selectedAddressProofFile = renamedFile;
        } else if (proofType === 'idProof') {
          this.selectedIdProofFile = renamedFile;
        } else if (proofType === 'bankPassBook') {
          this.bankProofFile = renamedFile;
        } else if (proofType === 'cdsDocuments') {
          this.cdsDocuments = renamedFile;
        } else if (proofType === 'gstDocuments') {
          this.gstDocuments = renamedFile;
        } else if (proofType === 'fssai') {
          this.fssai = renamedFile;
        } else if (proofType === 'GITag') {
          this.GITag = renamedFile;
        } else if (proofType === 'UdyamRegistration') {
          this.UdyamRegistration = renamedFile;
        } else if (proofType === 'tradeMark') {
          this.tradeMark = renamedFile;
        } else if (proofType === 'profileImage') {
          this.selectedImage = renamedFile;
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            this.defaultImage = e.target?.result as string; // Set the data URL
          };
          reader.readAsDataURL(renamedFile);
        }

        console.log('Selected file:', proofType, renamedFile);
      }
    }
  }

  showAlert(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  }

  // when the add service provider button is clicked
  addServiceProvider() {
    if (!this.meUnitName) {
      this.showAlert('Please enter the unit name');
      return;
    }
    if (!this.phoneNumber1) {
      this.showAlert('Please enter your phone number');
      return;
    }
    if (this.phoneNumber1.toString().length !== 10) {
      this.showAlert('Phone number must be 10 characters long');
      return;
    }
    if (!this.meAppID) {
      this.showAlert('Please enter your ME App ID');
      return;
    }

    if (!this.whatsAppNumber) {
      this.showAlert('Please enter the WhatsApp number');
      return;
    }
    if (this.whatsAppNumber.toString().length !== 10) {
      this.showAlert('WhatsApp Number number must be 10 characters long');
      return;
    }
    if (!this.keyword) {
      this.showAlert('Please enter the keyword');
      return;
    }
    if (!this.CDSName) {
      this.showAlert('Please enter CDS Name');
      return;
    }
    if (!this.CDSNumber) {
      this.showAlert('Please enter CDS registration number');
      return;
    }

    if (!this.nhgName) {
      this.showAlert('Please enter CDS NHG name');
      return;
    }
    if (!this.bankName) {
      this.showAlert('Please enter the bank name');
      return;
    }
    if (!this.bankBranch) {
      this.showAlert('Please enter the bank branch');
      return;
    }
    if (!this.bankAccountNumber) {
      this.showAlert('Please enter the bank account number');
      return;
    }
    if (!this.bankIFSCcode) {
      this.showAlert('Please enter the bank IFSC code');
      return;
    }
    if (!this.bankProofFile) {
      this.showAlert('Please upload the bank passbook');
      return;
    }

    if (!this.localBodyType) {
      this.showAlert('Please enter the local body type');
      return;
    }
    if (!this.localBodyName) {
      this.showAlert('Please enter the local body name');
      return;
    }
    if (!this.wardName) {
      this.showAlert('Please enter the ward name');
      return;
    }

    if (!this.districtName) {
      this.showAlert('Please enter the district name');
      return;
    }

    if (!this.roleType) {
      this.showAlert('Please select the category');
      return;
    }

    if (!this.bankProofFile) {
      this.showAlert('Please upload bank passbook');
      return;
    }
    if (!this.cdsDocuments) {
      this.showAlert('Please upload cds documents');
      return;
    }
    if (!this.buildingName) {
      this.showAlert('Please enter building name');
      return;
    }
    if (!this.streetName) {
      this.showAlert('Please enter street name');
      return;
    }
    if (!this.landmark) {
      this.showAlert('Please enter landmark');
      return;
    }
    if (!this.accountHolderName) {
      this.showAlert('Please enter Bank Account Holder Name');
      return;
    }

    Swal.fire({
      icon: 'info',
      title: 'Add a seller account',
      text: 'Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner = true;
        this.edit = false;
        // const timestamp = Date.now();
        // const formattedTimestamp = new Date(timestamp)
        //   .toISOString()
        //   .replace(/[^\d]/g, '');
        // const profileID = 'USER_ID' + formattedTimestamp.slice(4, 14);
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log('The order function is called');
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${token}`
        );

        console.log('The headers are', headers);

        const formData = new FormData();
        formData.append('meUnitName', this.meUnitName);
        formData.append(
          'UdyamRegistrationNumber',
          this.UdyamRegistrationNumber
        );
        formData.append('roleType', this.roleType);
        formData.append('fssaiID', this.fssaiID);
        formData.append('phoneNumber', this.phoneNumber1.toString());
        formData.append('meAppID', this.meAppID.toString());
        formData.append('whatsAppNumber', this.whatsAppNumber.toString());
        formData.append('keyword', this.keyword);
        formData.append('bankName', this.bankName);
        formData.append('bankBranch', this.bankBranch);
        formData.append('bankAccountNumber', this.bankAccountNumber.toString());
        formData.append('bankIFSCcode', this.bankIFSCcode);
        formData.append('GSTNumber', this.GSTNumber);
        formData.append('streetName', this.streetName);
        formData.append('pincode', this.pincode);
        formData.append('buildingName', this.buildingName);
        formData.append('landmark', this.landmark);
        formData.append('CDSName', this.CDSName);
        formData.append('CDSNumber', this.CDSNumber);
        formData.append('localBodyType', this.localBodyType);
        formData.append('localBodyName', this.localBodyName);
        formData.append('wardName', this.wardName);
        formData.append('wardNumber', this.wardNumber.toString());
        formData.append('districtName', this.districtName);
        formData.append('blockName', this.blockName);
        formData.append('profileID', this.profileID);
        formData.append('accountHolderName', this.accountHolderName);
        formData.append('nhgName', this.nhgName);
        formData.append('upiID', this.upiID);
        formData.append('email', this.email);
        if (this.bankProofFile) {
          formData.append(
            'bankPassBook',
            this.bankProofFile,
            this.bankProofFile.name
          );
        }

        if (this.cdsDocuments) {
          formData.append(
            'cdsDocuments',
            this.cdsDocuments,
            this.cdsDocuments.name
          );
        }

        if (this.gstDocuments) {
          formData.append(
            'gstDocuments',
            this.gstDocuments,
            this.gstDocuments.name
          );
        }

        if (this.fssai) {
          formData.append('fssai', this.fssai, this.fssai.name);
        }

        if (this.GITag) {
          formData.append('GITag', this.GITag, this.GITag.name);
        }

        if (this.tradeMark) {
          formData.append('tradeMark', this.tradeMark, this.tradeMark.name);
        }

        if (this.UdyamRegistration) {
          formData.append(
            'UdyamRegistration',
            this.UdyamRegistration,
            this.UdyamRegistration.name
          );
        }

        this.http
          .post(KP_ADMIN_URL + '/addKPSeller', formData, { headers })
          .subscribe(
            (data) => {
              console.log('Account created');

              this.spinner = false;
              this.showWhatsAppButton = true;
              this.sellerwhatsApp = this.phoneNumber1;
              this.password = this.keyword;
              this.sellerMessage = `Hi, 
              You have successfully registered in PocketMart Kudumbashree Products.
              Your username :  ${this.sellerwhatsApp}
              Your temporary keyword is :  ${this.password}
               Please login to change your password.
               Thanks and regards.
               PocketMart Team.
               `;

              // this.router.navigate(['/kpSellerList']);

              this.clearTextBox();
              this.edit = true;
              if (this.isDistrictAdmin()) {
                this.adminDistrict();
                this.adminRoleType();
              }
          
              if (this.isMarketingAdmin()) {
                this.adminDistrict();
              }
          
              this.fetchDistricts();

              // Handle success response if needed
            },
            (error) => {
              console.error('Error', error);
              this.spinner = false;
              this.showWhatsAppButton = false;
              if (error.status === 401) {
                console.error('Unauthorized - logging out');
                this.edit = true;
                this.Authservice.logOut();
              } else {
                console.error('Error fetching :', error);
                alert('Error in Post');
                this.spinner = false;
                this.showWhatsAppButton = false;
                this.edit = true;
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log('User chose not to continue');
        this.showWhatsAppButton = false;
        this.spinner = false;
      }
    });
  }

  clearTextBox() {
    this.meUnitName = '';
    this.phoneNumber1 = null;
    this.meAppID = null;
    this.whatsAppNumber = null;
    this.keyword = '';
    this.localBodyType = 'Corporation';
    this.localBodyName = '';
    this.wardName = '';
    this.wardNumber = null;
    this.districtName = '';
    this.blockName = '';
    this.fssaiID = '';
    this.bankName = '';
    this.bankBranch = '';
    this.bankAccountNumber = null;
    this.bankIFSCcode = '';
    this.GSTNumber = '';
    this.buildingName = '';
    this.streetName = '';
    this.pincode = '';
    this.landmark = '';
    this.CDSName = '';
    this.CDSNumber = '';
    this.cdsDocuments = null;
    this.bankProofFile = null;
    this.districtLabel = null;
    this.districtValue = null;
    this.roleTypeLable = null;
    this.roleType = null;
    this.accountHolderName = null;
    this.nhgName = null;
    this.UdyamRegistrationNumber = null;
    this.upiID = null;
    this.email = null;

    this.selectedAddressProofFile = null;
    this.selectedIdProofFile = null;
    this.bankProofFile = null;
    this.cdsDocuments = null;
    this.gstDocuments = null;
    this.fssai = null;
    this.GITag = null;
    this.tradeMark = null;
    this.UdyamRegistration = null;
    this.selectedImage = null;

    const timestamp = Date.now();
    const formattedTimestamp = new Date(timestamp)
      .toISOString()
      .replace(/[^\d]/g, '');
    const profileID = 'SP_ID' + formattedTimestamp.slice(4, 14);

    this.profileID = profileID;
  }
}
