import { Component } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent {
  goToBookServiceManually: any;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService
  ) {}

  checkLogin(): void {
    const isLoggedIn = this.Authservice.isAuthenticated();
    if (isLoggedIn) {
      console.log('user is logged in');
    } else {
      this.router.navigate(['/login']);
    }
  }

  // to go to analytics page
  goToAnalyticsPage() {
    this.router.navigate(['/analyticsPage']);
  }

  // to go to notifications page
  goToNotifications() {
    this.router.navigate(['/kpNotification']);
  }

  //to go to seller list
  goToSellerList() {
    this.router.navigate(['/kpSellerList']);
  }

  // go to create district admin
  goToCreateDistrictAdmin() {
    this.router.navigate(['/createDistrictAdmin']);
  }


  //to create a marketing admin
  goToCreateMarketingDistrictAdmin() {
    this.router.navigate(['/createMarketingDistrictAdmin']);
  }


  // to go to products listing
  goToProductsListings() {
    this.router.navigate(['/productList']);
  }

  // to go to products listing
  goToAddSellerPage() {
    this.router.navigate(['/addSeller']);
  }

    // to go to products listing
    goToOrderDetailsPage() {
      this.router.navigate(['/orderDetails']);
    }
  

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isMarketingDistrictAdmin()
    );
  }
}
