import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const QS_ADMIN_URL = environment.qsAdminUrl;

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestListingsService {

  public startDate: any = null;
  public endDate: any = null;
  
  public pendingRequests: boolean = false;
  public processingRequests : boolean = false;
  public assignedRequests: boolean = false;
  public acceptedRequests : boolean = false;
  public completedRequests: boolean = false;
  public cancelledRequests: boolean = false;

  constructor(private http: HttpClient) {}

  setDates(startDate: any, endDate: any): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = false;
  }

  setPendingRequest(){
    this.pendingRequests = true;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = false;
    this.startDate = null;
    this.endDate = null;

    console.log('the set pending request is', this.pendingRequests)
  }

  getPendingRequest(){
    return this.pendingRequests;
  }

  setProcessingRequest(){
    this.pendingRequests = false;
    this.processingRequests = true;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = false;
    this.startDate = null;
    this.endDate = null;

    console.log('the set processing request is', this.processingRequests);
  }

  getProcessingRequest(){
    return this.processingRequests;
  }

  setAssignedRequest(){
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = true;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = false;
    this.startDate = null;
    this.endDate = null;

    console.log('the set processing request is', this.processingRequests);
  }

  getAssignedRequest(){
    return this.assignedRequests;
  }

  setAcceptedRequest(){
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = true;
    this.completedRequests = false;
    this.cancelledRequests = false;
    this.startDate = null;
    this.endDate = null;

    console.log('the set processing request is', this.processingRequests);
  }

  getAcceptedRequest(){
    return this.acceptedRequests;
  }

  setCompletedRequest(){
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = true;
    this.cancelledRequests = false;
    this.startDate = null;
    this.endDate = null;

    console.log('the set processing request is', this.processingRequests);
  }

  getCompletedRequest(){
    return this.completedRequests;
  }

  setCancelledRequest(){
    this.pendingRequests = false;
    this.processingRequests = false;
    this.assignedRequests = false;
    this.acceptedRequests = false;
    this.completedRequests = false;
    this.cancelledRequests = true;
    this.startDate = null;
    this.endDate = null;

    console.log('the set processing request is', this.processingRequests);
  }

  getCancelledRequest(){
    return this.cancelledRequests;
  }

  getDates(): { startDate: any, endDate: any } {
    return { startDate: this.startDate, endDate: this.endDate };
  }

  searchForServiceListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('startDate', this.startDate)
      .set('endDate', this.endDate);

    return this.http.get<any>(`${QS_ADMIN_URL}/getProductList`, { headers, params });
  }

  searchForProductsListingsForDistrictAdmin(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('startDate', this.startDate)
      .set('endDate', this.endDate);

    return this.http.get<any>(`${QS_ADMIN_URL}/getProductListForDistrictAdmin`, { headers, params });
  }

  searchForProductsListingsForMarketingDistrictAdmin(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const params = new HttpParams()
      .set('startDate', this.startDate)
      .set('endDate', this.endDate);

    return this.http.get<any>(`${QS_ADMIN_URL}/getProductListForMarketingDistrictAdmin`, { headers, params });
  }







  searchForPendingServiceListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getPendingServiceRequest`, { headers });
  }

  searchForProcessingListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getProcessingServiceRequest`, { headers });
  }

  searchForAssignedListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getAssignedServiceRequest`, { headers });
  }

  searchForAcceptedListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getAcceptedServiceRequest`, { headers });
  }

  searchForCompletedListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getCompletedServiceRequest`, { headers });
  }

  searchForCancelledListings(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  
    return this.http.get<any>(`${QS_ADMIN_URL}/getCancelledServiceRequest`, { headers });
  }
}
