import { Component, OnInit } from "@angular/core";
import { Route, Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import Swal from "sweetalert2";
import { QsIDsService } from "../../services/qs-ids.service";
import { ServiceRequestListingsService } from "../../services/service-request-listings.service";
import { DateAdapter } from '@angular/material/core';

import { environment } from "src/environments/environment";
const KP_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-analytics-page",
  templateUrl: "./analytics-page.component.html",
  styleUrls: ["./analytics-page.component.css"],
})
export class AnalyticsPageComponent implements OnInit {
  //variables
  startDate: any;
  endDate: any;
  approvedMEUnits: number = 0;
  notApprovedMEUnits: number = 0;
  totalServiceRequests: number = 0;
  // totalRevenue: number = 0;
  activeServiceProviders: number = 0;
  inActiveServiceProviders: number = 0;
  pendingServiceRequests: number = 0;
  progressServiceRequests: number = 0;
  assignedServiceRequests: number = 0;
  completedServiceRequests: number = 0;
  acceptedServiceRequests: number = 0;
  cancelledServiceRequests: number = 0;
  totalAmount: number = 0;

  spinnerForSearch: boolean = false;
  spinnerForExcelDownload: boolean = false;
  spinnerForPDFDownload: boolean = false;
  spinnerForServiceProvider: boolean = false;

  serviceProviderListing: any;

  totalMECount: any = 0;
  totalProducts: any = 0;
  pendingProductsCount: any = 0;
  pendingMEunitCount: any = 0;
  approvedProductsCount: any = 0;
  acceptedMEUnitCount: any = 0;
  totalOrders: any = 0;
  notVerifiedProductsCount: any = 0;
  verifiedProductsCount: any = 0;

  // districts = [
  //   { value: '', label: 'All' },
  //   { value: 'Alappuzha', label: 'Alappuzha' },
  //   { value: 'Ernakulam', label: 'Ernakulam' },
  //   { value: 'Idukki', label: 'Idukki' },
  //   { value: 'Kannur', label: 'Kannur' },
  //   { value: 'Kasaragod ', label: 'Kasaragod' },
  //   { value: 'Kollam', label: 'Kollam' },
  //   { value: 'Kottayam', label: 'Kottayam' },
  //   { value: 'Kozhikode', label: 'Kozhikode' },
  //   { value: 'Malappuram', label: 'Malappuram' },
  //   { value: 'Palakkad', label: 'Palakkad' },
  //   { value: 'Pathanamthitta', label: 'Pathanamthitta' },
  //   { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
  //   { value: 'Thrissur', label: 'Thrissur' },
  //   { value: 'Wayanad', label: 'Wayanad' },
  // ];

  districts: any[] = [];
  districtLabel: string = "";
  districtValue: string = "";
  maxDate: Date;

  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private router: Router,
    private QsIDsService: QsIDsService,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit() {
    this.fetchDistricts();

    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      this.startDate = new Date();
    }

    if (startDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    }

    if (this.startDate) {
      this.search();
    }
  }

  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
  }

  search() {
    if (this.isStateAdmin() || this.isSuperAdmin()) {
      this.searchForStateAdmin();

      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } else if (this.isDistrictAdmin()) {
      this.searchForDistrictAdmin();
      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } 
    else if (this.isMarketingAdmin()) {
      this.searchForMarketingDistrictAdmin();
      this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
    } 
    
    else {
      console.log("please check your login");
    }
  }

  searchForStateAdmin() {
    const localBodyType = this.localBodyType;
    const districtName = this.districtName;
    const blockName = this.blockName;
    const localBodyName = this.localBodyName;

    console.log(
      "the local body",
      localBodyType,
      districtName,
      blockName,
      localBodyName
    );

    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate)
        .set("districtValue", this.districtName)
        .set("localBodyType", this.localBodyType)
        .set("localBodyName", this.localBodyName)
        .set("blockName", this.blockName);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCounts", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log(data);

            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.approvedProductsCount = data.approvedProductsCount;
            this.totalProducts = data.totalProducts;
            this.totalOrders = data.totalOrders;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.pendingProductsCount = data.pendingProductsCount;
            this.verifiedProductsCount = data.verifiedProductsCount;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }  

  searchForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCountsForDistrictAdmin", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.approvedProductsCount = data.approvedProductsCount;
            this.totalProducts = data.totalProducts;
            this.totalOrders = data.totalOrders;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.pendingProductsCount = data.pendingProductsCount;
            this.verifiedProductsCount = data.VerifiedProductsCount;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }


  searchForMarketingDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      this.spinnerForSearch = true;
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);
      this.http
        .get<any>(KP_ADMIN_URL + "/analyticsCountsForMarketingDistrictAdmin", {
          headers,
          params,
        })
        .subscribe(
          (data: any) => {
            console.log("the result", data);
            this.spinnerForSearch = false;
            this.totalMECount = data.totalMECount;
            this.pendingMEunitCount = data.pendingMEunitCount;
            this.approvedProductsCount = data.approvedProductsCount;
            this.totalProducts = data.totalProducts;
            this.totalOrders = data.totalOrders;
            this.notVerifiedProductsCount = data.notVerifiedProductsCount;
            this.pendingProductsCount = data.pendingProductsCount;
            this.verifiedProductsCount = data.VerifiedProductsCount;
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
              this.spinnerForSearch = false;
            }
            // Handle error response
          }
        );
    }
  }

  convertUTCToIST(dateString: string): string {
    const date = new Date(dateString); // Create a Date object from the UTC date string
    const utcTime = date.getTime(); // Get the time in milliseconds since epoch in UTC

    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30, convert to milliseconds
    const istTime = new Date(utcTime + istOffset); // Create a new Date object for IST

    // Format the date to 'YYYY-MM-DD'
    const year = istTime.getUTCFullYear();
    const month = ("0" + (istTime.getUTCMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ("0" + istTime.getUTCDate()).slice(-2);

    return `${day}-${month}-${year}`;
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  localBodyName: string = "";
  districtName: string = "";
  blockName: string = "";
  localBodyType: string = ""; // Default value

  fetchDistricts(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/districts.json")
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
      });
  }

  fetchDistrictsforMunicipalCorporation(): void {
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/districtsCorp.json"
      )
      .subscribe((data) => {
        this.districts = data.districts;
        console.log(this.districts);
      });
  }

  fetchBlocksForDistrict(districtId: string): void {
    console.log("districtId: ", districtId);
    this.http
      .get<any>("assets/cloudKitchenPlaces/districtBlocks/blocks.json")
      .subscribe((data) => {
        this.blocks = data[districtId];
        console.log(this.blocks);
      });
  }
  tempBlocks: any;
  fetchGramPanchayatsForDistrict(districtId: string): void {
    console.log(districtId);
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/gramaPanchayats/" + districtId + ".json"
      )
      .subscribe((data) => {
        console.log(data);

        this.tempBlocks = data[`${this.selectedBlock}`];
        console.log(this.tempBlocks);
        this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
        this.gramPanchayats = this.tempBlocks;
        console.log(this.gramPanchayats);
      });
  }

  onDistrictChange(): void {
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.selectedMunicipalityId = "";
    this.selectedMunicipalCorporationId = "";
    this.selectedMunicipality = null;
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    if (this.selectedDistrict) {
      this.districtName = this.selectedDistrict.name;
      console.log("District Name: ", this.districtName);
      if (this.selectedLevel === "municipalityLevel") {
        this.selectedMunicipality =
          this.municipalities[this.selectedDistrict.id];
        console.log("municipality", this.selectedMunicipality);

        return;
      } else if (this.selectedLevel == "municipalCorporation") {
        this.selectedMunicipalCorporation =
          this.municipalCorporations[this.selectedDistrict.id];
        console.log("municipalCorporation", this.selectedMunicipalCorporation);

        return;
      } else {
        console.log("this.selectedDistrict", this.selectedDistrict.id);

        this.fetchBlocksForDistrict(this.selectedDistrict.id);
        // Preload gram panchayats data for the selected district
        this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
      }
    }
  }

  selectedBlk: any = "";
  onBlockChange(): void {
    this.fetchGramPanchayatsForDistrict(this.selectedDistrict.id);
    this.selectedBlock = this.selectedBlk.id;
    this.blockName = this.selectedBlk.name;
    console.log(this.selectedBlock);

    console.log("onBlockchnge", this.selectedDistrict.id);
    this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
    if (this.selectedBlock) {
      this.selectedGramPanchayat = ""; // Reset selected gram panchayat when block changes
      // this.gramPanchayatsLoaded = true; // Set flag to indicate gram panchayats are loaded and ready to display
    }
    // Set the gram panchayats data based on the selected block
    if (this.selectedBlock && this.blocks[this.selectedBlock]) {
      this.gramPanchayats =
        this.gramPanchayats[this.selectedDistrict][this.selectedBlock];
      console.log(this.gramPanchayats);
    }
  }

  selectedGP: any = "";
  onPChange(): void {
    this.selectedGramPanchayat = this.selectedGP.name;
    this.localBodyName = this.selectedGramPanchayat;
    // this.blockName=this.selectedBlk.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("blockName: ", this.blockName);
    console.log("Local body name: ", this.localBodyName);
    // console.log(
    //   this.districtName,
    //   this.blockName,

    //   this.selectedGramPanchayat
    // );
  }

  // For Municipal Level

  municipalities: any;
  selectedMunicipality: any;
  selectedMunicipalityId: any;

  fetchMunicipalitiesForDistrict(): void {
    this.http
      .get<any>("assets/cloudKitchenPlaces/municipalities/municipalities.json")
      .subscribe((data) => {
        this.municipalities = data.municipalities;
        console.log(data.municipalities);
      });
  }

  selectedLevel: string = ""; // Property to store the selected level
  legalBody: string;

  blocks: any;
  gramPanchayats: any = {};

  selectedDistrict: any = "";
  selectedBlock: string = "";
  selectedGramPanchayat: string = "";

  gramPanchayatsLoaded: boolean = false;

  // Method to handle radio button selection
  // For Municipal Level

  // Method to handle radio button selection
  onLevelSelection(selectedLevel: string): void {
 
    if(selectedLevel == ''){
      window.location.reload();

    }
    
    this.selectedDistrict = "";
    this.selectedBlock = ""; // Reset selected block and gram panchayat when district changes
    this.selectedGramPanchayat = "";
    this.gramPanchayatsLoaded = false; // Reset flag when district changes
    this.selectedMunicipality = null;
    this.selectedMunicipalCorporation = null;
    this.selectedLevel = selectedLevel;
    this.selectedMunicipalCorp = "";

    console.log(this.selectedMunicipalityId);

    if (this.selectedLevel === "blockLevel") {
      this.legalBody = "PAN";
      this.localBodyType = "Panchayat";
      this.fetchDistricts();
      this.localBodyType = "Panchayat";
    }
    if (this.selectedLevel === "municipalityLevel") {
      this.legalBody = "MUN";
      this.localBodyType = "Municipality";
      this.fetchDistricts();
      this.fetchMunicipalitiesForDistrict();
    }
    if (this.selectedLevel === "municipalCorporation") {
      this.legalBody = "MNC";
      this.localBodyType = "Corporation";

      this.fetchDistrictsforMunicipalCorporation();
      this.fetchMunicipalCorporationsForDistrict();
    }
    console.log("legalBody", this.legalBody);
  }
  selectedMunicipal: any = "";

  onMunicipalityChange(): void {
    // Implement actions to handle the change in selected municipality
    console.log("Selected municipality:", this.selectedMunicipal.name);
    this.selectedMunicipalityId = this.selectedMunicipal.id;
    this.localBodyName = this.selectedMunicipal.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);

    // You can perform additional actions here based on the selected municipality
  }

  selectedMunicipalCorp: any = "";
  onMunicipalCorporationChange(): void {
    this.selectedMunicipalCorporationId = this.selectedMunicipalCorp.id;
    this.localBodyName = this.selectedMunicipalCorp.name;
    console.log("Local Body Type: ", this.localBodyType);
    console.log("District: ", this.districtName);
    console.log("Local body name: ", this.localBodyName);
    // Implement actions to handle the change in selected municipality
    //  console.log('Selected municipality:', this.selectedMunicipalCorporationId);
    // You can perform additional actions here based on the selected municipality
  }

  //For Municipal Corporation Level;
  municipalCorporations: any = {};
  selectedMunicipalCorporation: any;
  selectedMunicipalCorporationId: any;

  fetchMunicipalCorporationsForDistrict(): void {
    // Assuming you are fetching data from a JSON file named municipal_corporations.json
    this.http
      .get<any>(
        "assets/cloudKitchenPlaces/municipalCorporations/municipal_corporation.json"
      )
      .subscribe((data) => {
        this.municipalCorporations = data.municipal_corporations || {};
        console.log(this.municipalCorporations);
      });
  }
}
