<app-side-bar></app-side-bar>
<app-header></app-header>
<div class="container p-4 mainContainer">
 <!-- header -->
   <div class="row">
        <div class="col">
            <p class="createULB"><u>Add services manually</u></p>        </div>
        <div class="col">
            <p class="pageReference">Quick serve > Add services manually</p>
        </div>
   </div>
   <br> <br>
   
   <div class="row">
      <div class="formContainer">
        <form class="form-horizontal"  #myForm="ngForm" (ngSubmit)="onSubmit(myForm)">
          <!-- <select name="district" id="district" class=" form-select" [(ngModel)]="selectedService" (ngModelChange)="onServiceChange()">
            <option value="" disabled selected>Choose a service</option>
            <option *ngFor="let service of serviceLabels" [value]="service.id">{{service.value}}</option>
          </select> -->

          <select name="district" id="district" class="form-select" [(ngModel)]="selectedService" (ngModelChange)="onServiceChange()">
            <option value="" disabled selected>Choose a service</option>
            <option *ngFor="let service of services" [value]="service.serviceName">{{ getServiceLabel(service.serviceName) }}</option>
          </select>
        
          <!-- code for single date -->
           <div class="dateContainer"  *ngIf="selectedService === 'qs_ID_02' || selectedService === 'qs_ID_07'" >
            <mat-form-field appearance="fill" class="datePicker">
              <mat-label>Select the date</mat-label>
              <input matInput [matDatepicker]="startPicker" [(ngModel)]="selectedStartDate" (dateChange)="onStartDateChange()" [matDatepickerFilter]="startDateFilter" [min]="minDate" [max]="maxDate" [ngModelOptions]="{standalone: true}" required>
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
           </div>
          <!-- code for date range -->
           <div class="dateContainer" *ngIf="selectedService=== 'qs_ID_01'||selectedService=== 'qs_ID_03'||selectedService=== 'qs_ID_04'||
           selectedService=== 'qs_ID_05'||selectedService=== 'qs_ID_06'">
          <mat-form-field appearance="fill" class="datePicker">
            <mat-label>Select a start date</mat-label>
            <input matInput [matDatepicker]="startPicker" [(ngModel)]="selectedStartDate" (dateChange)="onStartDateChange()" [matDatepickerFilter]="startDateFilter" [min]="minDate" [max]="maxDate" [ngModelOptions]="{standalone: true}" required>
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="datePicker" *ngIf="selectedStartDate">
            <mat-label>Select an end date</mat-label>
            <input matInput [matDatepicker]="endPicker" [(ngModel)]="selectedEndDate"  [min]="endMinDate" [max]="endMaxDate" [ngModelOptions]="{standalone: true}" required>
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
          </mat-form-field>
         </div>
  
          <!-- end of code for date range -->
              <div class="formContent">    
                <div class="subRow">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" name="fullName" maxlength="25" placeholder="" [(ngModel)]="fullName" required>
                    <label for="floatingInput" style="font-size: 16px;">Enter full name</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="number" name="mobileNumber" oninput="this.value=this.value.slice(0, 10);" min="1000000000" [(ngModel)]="mobileNumber"
                    max="9999999999" class="form-control"  placeholder="Mobile number" required>
                    <label for="floatingInput"  style="font-size: 16px;">Mobile number</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" name="workDescription" maxlength="50" placeholder="" [(ngModel)]="workDescription" required>
                    <label for="floatingInput" style="font-size: 16px;">Work description</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="number" name="ratePerHour" oninput="this.value=this.value.slice(0, 4);" min="0" 
                    max="9999" class="form-control" maxlength="4" placeholder="Rate per hour" [(ngModel)]="ratePerHour"  required>
                    <label for="floatingInput" style="font-size: 16px;">Rate per hour (₹)</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input
                    type="text"
                    #inputField
                    name="googleAddress"
                    placeholder="Search your address"
                    class="form-control"
                    maxlength="50"/> 
                    <label for="floatingInput" style="font-size: 16px;">Search your address</label>               
                  </div>
              </div>
              <div class="subRow">
                <div class="form-floating mb-3">
                  <input type="text" 
                  class="form-control" 
                  maxlength="50"
                  name="buildingName"  
                  placeholder="Buiding name" 
                  [(ngModel)]="buidingName" required>
                  <label for="floatingInput" style="font-size: 16px;">Buiding name</label>
                </div>
                  <div class="form-floating mb-3">
                    <input type="text" 
                    class="form-control" 
                    maxlength="50" 
                    name="streetName" 
                    placeholder="Street name" 
                    [(ngModel)]="streetName" 
                    required>
                    <label for="floatingInput" style="font-size: 16px;">Street name</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" maxlength="50" name="landMark" placeholder="Landmark" [(ngModel)]="landmark" required>
                    <label for="floatingInput" style="font-size: 16px;">Landmark</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="number" name="pincode" oninput="this.value=this.value.slice(0, 6);" min="000000" 
                    max="999999" class="form-control" maxlength="10" placeholder="Pincode" [(ngModel)]="pincode"  required>
                    <label for="floatingInput" style="font-size: 16px;">Pincode</label>
                  </div>
                  <div class="form-floating mb-3" >
                    <select name="selectedDistrict" id="district" class="form-select" [(ngModel)]="selectedDistrict" (ngModelChange)="onDistrictChange()" required>
                      <option value="" disabled selected>Select district</option>
                      <option [value]="district.value" *ngFor="let district of districts">{{district.value}}</option>
                    </select>
                  </div>
              </div>
              </div>
            
              <div class="buttonContainer">
                <button type="reset" class="btn btn-secondary buttons" >Clear
                </button>
                <button  type="submit" class="btn btn-primary buttons">Submit
                </button>
              </div>  

              </form>
        </div>
   </div>
</div>