<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Seller Description</u></p>
    </div>

    <div class="col">
      <p (click)="goBack()" class="pageReference">
        Seller listings > Seller description
      </p>
    </div>
  </div>

  <button
    *ngIf="spinnerForServiceProviderDescription"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div class="row" *ngIf="!spinnerForServiceProviderDescription">
    <div class="col-2 m-2 p-4 card">
      <span style="text-align: center; color: gray; margin-bottom: 10px">{{
        profileID
      }}</span>
      <img
        class="profileImage"
        style="margin-bottom: 10px"
        src="../../../assets/profileImage/profileImage.png"
        alt=""
      />

      <div class="row mt-1 mb-3" *ngIf="isStateAdmin() || isSuperAdmin()">
        <div class="col pe-0">
          <p style="margin-bottom: 0">Approval Status</p>
        </div>
        <div class="col-4">
          <div class="form-check form-switch">
            <input
              [disabled]="!edit"
              class="form-check-input instockToggle"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              [(ngModel)]="approvalStatus"
            />
          </div>
        </div>
      </div>

      <button
        [disabled]="accountDelete"
        type="button"
        (click)="passwordReset()"
        class="btn btn-outline-primary p-1"
      >
        Reset Password
      </button>

      <button
        *ngIf="isStateAdmin() || isSuperAdmin()"
        [disabled]="accountDelete"
        style="margin-top: 10px"
        (click)="deleteAccount()"
        type="button"
        class="btn btn-outline-danger"
      >
        Delete Account
      </button>

      <br />
    </div>
    <div class="col m-2 p-4 card">
      <div class="row mb-1">
        <div class="col">
          <p><b>Seller Details</b></p>
        </div>
        <div class="col">
          <button
            [disabled]="accountDelete"
            style="float: right"
            (click)="enableEdit()"
            type="button"
            class="btn btn-secondary btn-sm"
          >
            <i class="fa-solid fa-pen-to-square"></i> Edit
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Details</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >ME Unit Name</label
                    >
                    <input
                      type="text"
                      [disabled]="!edit"
                      [(ngModel)]="meUnitName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="50"
                      placeholder="Enter ME Unit Name"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Phone Number</label
                    >
                    <input
                      type="text"
                      [disabled]="!edit"
                      class="form-control"
                      [(ngModel)]="phoneNumber1"
                      [ngModelOptions]="{ standalone: true }"
                      id="meUnitName"
                      placeholder="Enter Phone Number"
                      #phone1
                      (input)="checkphone1(phone1)"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">WhatsApp</label>
                    <input
                      type="text"
                      [disabled]="!edit"
                      [(ngModel)]="whatsAppNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="10"
                      placeholder="Enter WhatsApp Number"
                      #whatsApp
                      (input)="checkWhatsApp(whatsApp)"
                      (keypress)="validateAge($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="meUnitName" class="form-label">Email ID </label>
                <input
                  type="email"
                  [(ngModel)]="email"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="meUnitName"
                  maxlength="50"
                  [disabled]="!edit"
                />
              </div>

              <div class="mb-3">
                <label for="meUnitName" class="form-label">ME APP ID</label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="meAppID"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="meUnitName"
                  maxlength="25"
                  placeholder="Enter ME App ID"
                  #phone2
                />
              </div>

              <div class="mb-3">
                <label for="meUnitName" class="form-label">FSSAI ID</label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="fssaiID"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="meUnitName"
                  maxlength="50"
                  placeholder="Enter FSSAI ID"
                />
              </div>

              <div class="mb-3">
                <label for="meUnitName" class="form-label">GST Number</label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="GSTNumber"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="meUnitName"
                  maxlength="50"
                  placeholder="Enter GST Number"
                />
              </div>

              <!------CDS group details------>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">CDS Name</label>
                    <input
                      type="text"
                      [disabled]="!edit"
                      [(ngModel)]="CDSName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="50"
                      placeholder="Enter CDS Name"
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >CDS Registration Number</label
                    >
                    <input
                      type="text"
                      [disabled]="!edit"
                      [(ngModel)]="CDSNumber"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="50"
                      placeholder="Enter CDS Number"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label">NHG Name</label>
                    <input
                      type="text"
                      [disabled]="!edit"
                      [(ngModel)]="nhgName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      maxlength="50"
                    />
                  </div>
                </div>
              </div>

              <!-- <div class="mb-3 mt-1" *ngIf="isStateAdmin() || isSuperAdmin()">
                <label for="districtSelect" class="form-label"
                  >Select category</label
                >
                <select
                  class="form-select"
                  id="districtSelect"
                  (change)="onAdminCategorySelected($event)"
                  aria-label="Default select example"
                  [disabled]="!edit"
                >
                  <option value="" disabled selected>
                    Open this select menu
                  </option>

                  <option
                    *ngFor="let adminCategory of adminCategory"
                    [value]="adminCategory.value"
                  >
                    {{ adminCategory.label }}
                  </option>
                </select>
              </div> -->

              <div class="mb-3">
                <label for="category" class="form-label">Admin Type</label>
                <input
                  type="text"
                  disabled
                  [value]="categoryLabel"
                  class="form-control"
                  id="category"
                  placeholder="Enter Street Name"
                  maxlength="50"
                />
              </div>
              <!----------------------------->
            </mat-expansion-panel>
          </mat-accordion>
          <br />

          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Address</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >Building Name</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="buildingName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Building Name"
                    maxlength="50"
                  />
                </div>
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Street Name</label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="streetName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Street Name"
                    maxlength="50"
                  />
                </div>
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Landmark</label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="landmark"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Landmark"
                    maxlength="50"
                  />
                </div>
                <!--radio button starts-->
                <label for="formFile3" class="form-label"
                  >Select the local bodies</label
                >
                <div class="row ps-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      disabled
                      name="localBodyType"
                      id="flexRadioDefault1"
                      [(ngModel)]="localBodyType"
                      value="Corporation"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Corporation
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      disabled
                      name="localBodyType"
                      id="flexRadioDefault2"
                      [(ngModel)]="localBodyType"
                      value="Municipality"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Municipality
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      disabled
                      name="localBodyType"
                      id="flexRadioDefault3"
                      [(ngModel)]="localBodyType"
                      value="Panchayat"
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Panchayat
                    </label>
                  </div>
                </div>

                <!--radio button ends-->
                <div class="mb-3" *ngIf="localBodyType === 'Corporation'">
                  <label for="meUnitName" class="form-label"
                    >Corporation Name</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="localBodyName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Corporation Name"
                    maxlength="50"
                  />
                </div>

                <div class="mb-3" *ngIf="localBodyType === 'Municipality'">
                  <label for="meUnitName" class="form-label"
                    >Municipality Name</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="localBodyName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Municipality Name"
                    maxlength="50"
                  />
                </div>

                <div class="mb-3" *ngIf="localBodyType === 'Panchayat'">
                  <label for="meUnitName" class="form-label"
                    >Panchayat Name</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="localBodyName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Panchayat Name"
                    maxlength="50"
                  />
                </div>

                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Ward Name</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="wardName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        placeholder="Enter Ward Name"
                        maxlength="50"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Ward Number</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="wardNumber"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        placeholder="Enter Ward Number"
                        #ward
                        maxlength="2"
                        (input)="checkAgeLength()"
                        (keypress)="validateAge($event)"
                      />
                    </div>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Pincode</label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="pincode"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Pincode"
                    #ward
                    maxlength="6"
                    (input)="checkPincodeLength()"
                    (keypress)="validateAge($event)"
                  />
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label">District</label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="districtName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter District"
                    disabled
                    style="
                      background-color: rgb(206, 203, 203);
                      border-color: rgb(206, 203, 203);
                    "
                  />
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="col">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Bank Details</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!------bank details starts ------------>
              <div class="container p-0">
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Account Holders Name
                        <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        [(ngModel)]="accountHolderName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        [disabled]="!edit"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Bank Name</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="bankName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter Bank Name"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Bank Branch</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="bankBranch"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="30"
                        placeholder="Enter Branch Name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Account Number</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="bankAccountNumber"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter Account Number"
                        (keypress)="validateAge($event)"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >IFSC Code</label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="bankIFSCcode"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter IFSC Code"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label">UPI ID</label>
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="upiID"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter UPI ID"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!------------------------------------>
            </mat-expansion-panel>
          </mat-accordion>
          <br />
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <p style="margin-bottom: 0">
                    <b>Documents</b>
                  </p>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="formFileIdProof" class="form-label"
                      >CDS Registration Documents</label
                    >

                    <div *ngFor="let url of cdsDocumentUrl">
                      <a [href]="url" target="_blank"
                        >Download CDS Registration Documents</a
                      >
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'cdsDocuments')"
                      accept="application/pdf"
                      id="formFileIdProof"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >Bank Pass Book</label
                    >
                    <div *ngFor="let url of bankPassBookUrl">
                      <a [href]="url" target="_blank"
                        >Download Bank Pass Book</a
                      >
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >GST Documents</label
                    >
                    <div *ngFor="let url of gstDocumentUrl">
                      <a [href]="url" target="_blank">Download GST Documents</a>
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >GI Tag Documents</label
                    >
                    <div *ngFor="let url of GITagDocumentUrl">
                      <a [href]="url" target="_blank"
                        >Download GI Tag Documents</a
                      >
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >Trade Mark</label
                    >
                    <div *ngFor="let url of tradeMarkDocumentUrl">
                      <a [href]="url" target="_blank">Download Trade Mark</a>
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="bankPassBook" class="form-label"
                      >Udyam Documents</label
                    >
                    <div *ngFor="let url of UdyamDocumentsProofUrls">
                      <a [href]="url" target="_blank">Udyam Documents</a>
                    </div>
                    <!-- <input
                      class="form-control"
                      type="file"
                      (change)="onFileSelected($event, 'bankPassBook')"
                      accept="application/pdf"
                      id="bankPassBook"
                    /> -->
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <br />

      <div class="row mt-4" *ngIf="edit">
        <div class="col">
          <div
            *ngIf="spinnerButtonForUpdate"
            style="float: right"
            class="spinner-border text-success"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            *ngIf="!spinnerButtonForUpdate"
            (click)="updateSellerDetaails()"
            type="button"
            style="float: right"
            class="btn btn-success"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
