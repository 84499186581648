<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Seller Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Kudumbashree Products > Seller Listings</p>
    </div>
  </div>

  <br />

  <button
    *ngIf="spinnerForListingPage"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span
      class="spinner-grow spinner-grow-sm"
      role="status"
      aria-hidden="true"
    ></span>
    Loading...
  </button>

  <div class="row" *ngIf="!spinnerForListingPage">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">ProfileID</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Role</th>
            <th scope="col">District</th>
            <th scope="col">Reg Date</th>
            <th scope="col">Account Status</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let sellerListings of sellerListings; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ sellerListings.meUnitName }}</td>
            <td>{{ sellerListings.profileID }}</td>
            <td>{{ sellerListings.phoneNumber }}</td>
            <td>{{ roleTypeToLabel(sellerListings.category) }}</td>
            <td>{{ sellerListings.address.district }}</td>
            <td>
              <i class="fa-solid fa-calendar-days"></i>
              {{ convertUTCToIST(sellerListings.registeredDate) }}
            </td>
            <td>
              <span
                [class.active]="!sellerListings.accountSatus"
                [class.pending]="sellerListings.accountSatus"
              >
                {{ !sellerListings.accountSatus ? "Active" : "Deleted" }}
              </span>
            </td>
            <td
              style="text-align: center"
              (click)="goToDetails(sellerListings.profileID)"
            >
              <i
                class="fa-solid fa-square-caret-right fa-2xl"
                style="color: #0fbd5a"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
