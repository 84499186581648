import { Component } from '@angular/core';
import { QsIDsService } from '../../services/qs-ids.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/auth-service.service';

import {
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';

const QS_ADMIN_URL = environment.qsAdminUrl;
export interface PlaceSearchResult {
  address: string;
  // location?: google.maps.LatLng;
  location?: { lat: number; lng: number };
  imageUrl?: string;
  iconUrl?: string;
  name?: string;
}

@Component({
  selector: 'app-book-service-manually',
  templateUrl: './book-service-manually.component.html',
  styleUrls: ['./book-service-manually.component.css']
})
export class BookServiceManuallyComponent implements OnInit {

  // google autocomplete place start
  @ViewChild('inputField') inputField: ElementRef;

  @Input() placeholder = 'Enter Address';

  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  // google autocomplete place end

  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  minDate: Date;
  maxDate: Date;
  endMinDate: Date | null = null;
  endMaxDate: Date | null = null;
  googleAddress: any;
  selectedService: string = '';
  fullName: string = '';
  mobileNumber: number = null;
  workDescription: string = '';
  buidingName: string = '';
  streetName: string = '';
  landmark: string = '';
  pincode: number = null;
  selectedDistrict: string = '';
  ratePerHour: number = null;
  
  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
    private Authservice: AuthServiceService,
    private QsIDsService: QsIDsService,
  ) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    this.minDate = new Date(today);
    this.minDate.setDate(this.minDate.getDate() + 1);

    this.maxDate = new Date(this.minDate);
    this.maxDate.setDate(this.maxDate.getDate() + 90);
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }


  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputField.nativeElement);

    // Set component restrictions to India
    this.autocomplete.setComponentRestrictions({
      country: ['IN']
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete?.getPlace();

      // Log the place object for debugging
      console.log('Google Place:', place);

      const result: PlaceSearchResult = {
        address: this.inputField.nativeElement.value,
        name: place?.name,
        imageUrl: this.getPhotoUrl(place),
        location: place?.geometry?.location ? {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        } : undefined,
        iconUrl: place?.icon
      };

      // Log the result for debugging
      console.log('Resultddd:', result.address);
      this.googleAddress = result.address

      this.ngZone.run(() => {
        this.placeChanged.emit(result);
      });
    });
  }

  MEUnit:any = null;
  approvalStatus:any = null;
  services:any = null;
  registeredULB:any = null;

  ngOnInit() {
    this.getMEUnitDescription();
  }

  getMEUnitDescription() {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    // const params = new HttpParams().set('profileID', this.profileID);
    this.http.get(QS_ADMIN_URL + '/ApprovalStatus', { headers }).subscribe(
      (data) => {
        this.MEUnit = data;
        console.log(this.MEUnit);
        this.approvalStatus = this.MEUnit.isApproved;
        this.services = this.MEUnit.services;
        this.registeredULB = this.MEUnit.registeredULB;

        console.log('the approval status is: ' + this.approvalStatus);
        console.log('the services is: ' + this.services);

        // Handle success response if needed
      },
      (error) => {
        console.error('Error', error);
        if (error.status === 401) {
          console.error('Unauthorized - logging out');
          this.Authservice.logOut();
        } else {
          console.error('Error fetching MEUnitProfilePage:', error);
        }
        // Handle error response
      }
    );
  }
  
  getPhotoUrl(place: google.maps.places.PlaceResult | undefined): string | undefined {
    return place?.photos && place.photos.length > 0 ? place.photos[0].getUrl({ maxWidth: 300 }) : undefined;
  }


  onStartDateChange() {
    if (this.selectedStartDate) {
      this.endMinDate = new Date(this.selectedStartDate);
      this.endMinDate.setDate(this.endMinDate.getDate());

      this.endMaxDate = new Date(this.selectedStartDate);
      this.endMaxDate.setDate(this.endMaxDate.getDate() + 90);
    }
  }

  startDateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    return date >= this.minDate && date <= this.maxDate;
  };

  endDateFilter = (date: Date | null): boolean => {
    if (!date || !this.endMinDate || !this.endMaxDate) {
      return false;
    }
    return date >= this.endMinDate && date <= this.endMaxDate;
  };

  serviceLabels = [
    { id: 'qs_ID_01', value: 'House maid' },
    { id: 'qs_ID_02', value: 'Maintenance (Res/Comm)' },
    { id: 'qs_ID_03', value: 'Child care' },
    { id: 'qs_ID_04', value: 'Post natal (support)' },
    { id: 'qs_ID_05', value: 'Bystander (elderly care)' },
    { id: 'qs_ID_06', value: 'Bystander (bedridden)' },
    { id: 'qs_ID_07', value: 'Vehicle cleaning' },
  ];

  onServiceChange() {
    console.log('Selected Service ID:', this.selectedService);
    return this.selectedService;
  }

  onDistrictChange() {
    console.log('Selected District:', this.selectedDistrict);
    return this.selectedDistrict;
  }

  // formSubmit() {
  //   console.log( this.fullName,this.selectedService,this.mobileNumber,this.workDescription,this.buidingName,this.streetName,this.landmark,this.pincode,this.selectedDistrict)
  //   if ((!this.fullName)||(!this.selectedService)||(!this.mobileNumber)||(!this.workDescription)||(!this.buidingName)||(!this.streetName)||(this.landmark)||(this.pincode)||(this.selectedDistrict)){
  //     Swal.fire('Please enter all fields');
  //   }
  //   else{
  //   console.log('Selected start date:', this.selectedStartDate);
  //   console.log('Selected end date:', this.selectedEndDate);
  //   console.log('Selected selected Service:', this.selectedService);
  //   console.log('Selected full Name:', this.fullName);
  //   console.log('Selected mobile Number:', this.mobileNumber);
  //   console.log('Selected work Description:', this.workDescription);
  //   console.log('Selected buiding Name:', this.buidingName);
  //   console.log('Selected street Name:', this.streetName);
  //   console.log('Selected landmark:', this.landmark);
  //   console.log('Selected pincode:', this.pincode);
  //   console.log('Selected selected District:', this.selectedDistrict);
  // }
  // }

  districts = [
    { value: 'Alappuzha', label: 'Alappuzha' },
    { value: 'Ernakulam', label: 'Ernakulam' },
    { value: 'Idukki', label: 'Idukki' },
    { value: 'Kannur', label: 'Kannur' },
    { value: 'Kasaragod ', label: 'Kasaragod' },
    { value: 'Kollam', label: 'Kollam' },
    { value: 'Kottayam', label: 'Kottayam' },
    { value: 'Kozhikode', label: 'Kozhikode' },
    { value: 'Malappuram', label: 'Malappuram' },
    { value: 'Palakkad', label: 'Palakkad' },
    { value: 'Pathanamthitta', label: 'Pathanamthitta' },
    { value: 'Thiruvananthapuram', label: 'Thiruvananthapuram' },
    { value: 'Thrissur', label: 'Thrissur' },
    { value: 'Wayanad', label: 'Wayanad' },
  ];

  onSubmit(form: any) {
    // if((this.fullName=="")||(this.selectedService=="")||(this.mobileNumber==null)||(this.workDescription=="")||(this.buidingName=="")||(this.streetName=="")||(this.landmark=="")||(this.pincode==null)||(this.selectedDistrict=="")){
    //   console.log("enter again")
    // }
    if (form.valid) {
      console.log(
        'Selected start date:',
        this.selectedStartDate,
        'Selected end date:',
        this.selectedEndDate,
        'Selected Service:',
        this.selectedService,
        'full Name:',
        this.fullName,
        'mobile Number:',
        this.mobileNumber,
        ' work Description:',
        this.workDescription,
        ' buiding Name:',
        this.buidingName,
        ' street Name:',
        this.streetName,
        ' landmark:',
        this.landmark,
        ' pincode:',
        this.pincode,
        ' selected District:',
        this.selectedDistrict,
        'rate per hour',
        this.ratePerHour
      );

      Swal.fire('Success');
    } 
    else {
      console.log('Form is invalid');
      Swal.fire(
        'Some fields are missing or incorrect. \n Please enter all the fields'
      );

      return false;
      
    }

    const enteredData = {
      address: this.googleAddress,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate,
      serviceType: this.selectedService,
      name: this.fullName,
      phoneNumber: this.mobileNumber,
      workDescription: this.workDescription,
      buidingName: this.buidingName,
      streetName: this.streetName,
      landMark: this.landmark,
      pinCode: this.pincode,
      district: this.selectedDistrict,
      ratePerHour: this.ratePerHour,
    };

    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http
      .post<any>(`${QS_ADMIN_URL}/bookService`, enteredData, { headers })
      .subscribe((response) => {
        console.log(response, 'received data');
      
      });

    console.log(enteredData, 'IOJHIUHJa');
  }

}
