import { Component, OnInit } from "@angular/core";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import { PasswordService } from "../password.service";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
const KS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-create-district-admin",
  templateUrl: "./create-district-admin.component.html",
  styleUrls: ["./create-district-admin.component.css"],
})
export class CreateDistrictAdminComponent implements OnInit {
  constructor(
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private passwordService: PasswordService
  ) {}

  ngOnInit() {
    this.getDistrictAdmin();
  }

  //variables
  district: string = "";
  eMailID: string = "";
  keyword: string = "";
  spinner: boolean = false;
  districtAdmin: any;
  districtLabel: string = "";
  districtValue: string = "";
  roleTypeLable: string = "";
  roleType: string = "";

  spinnerForCreateDistrictAdminButton: boolean = false;

  districts = [
    { value: "Alappuzha", label: "Alappuzha" },
    { value: "Ernakulam", label: "Ernakulam" },
    { value: "Idukki", label: "Idukki" },
    { value: "Kannur", label: "Kannur" },
    { value: "Kasaragod ", label: "Kasaragod" },
    { value: "Kollam", label: "Kollam" },
    { value: "Kottayam", label: "Kottayam" },
    { value: "Kozhikode", label: "Kozhikode" },
    { value: "Malappuram", label: "Malappuram" },
    { value: "Palakkad", label: "Palakkad" },
    { value: "Pathanamthitta", label: "Pathanamthitta" },
    { value: "Thiruvananthapuram", label: "Thiruvananthapuram" },
    { value: "Thrissur", label: "Thrissur" },
    { value: "Wayanad", label: "Wayanad" },
  ];

  adminCategory = [
    { value: "type_1", label: "District Farmlivelihood" },
    { value: "type_2", label: "District NULM" },
    { value: "type_3", label: "District ME" },
    { value: "type_4", label: "District SVEP" },
    { value: "type_5", label: "District SISD" },
    // { value: "type_6", label: "District SD" },
    { value: "type_7", label: "District AH" },
  ];

  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case "type_1":
        return "District Farmlivelihood";
      case "type_2":
        return "District NULM";
      case "type_3":
        return "District ME";
      case "type_4":
        return "District SVEP";
      case "type_5":
        return "District SISD";
      // case "type_6":
      //   return "District SD";
      case "type_7":
        return "District AH";
      default:
        return "Unknown Role";
    }
  }

  onDistrictSelected(event: any) {
    // Set the selected district label
    this.districtLabel = event.target.options[event.target.selectedIndex].text;
    // Set the selected district value
    this.districtValue = event.target.value;
  }

  onAdminCategorySelected(event: any) {
    // Set the selected roleType Lable
    this.roleTypeLable = event.target.options[event.target.selectedIndex].text;
    console.log("the roleTypeLable is", this.roleTypeLable);
    // Set the selected roleType value
    this.roleType = event.target.value;
    console.log("the roleType is", this.roleType);
  }
  userAccountName: any;
  userPassword: any;
  toShowTheText: boolean = false;

  closeCard() {
    this.toShowTheText = false;
    window.location.reload();
  }

  //create the district admin
  createDistrictAdmin() {
    this.district = this.districtLabel;
    if (!this.eMailID.includes("@")) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address",
      });
      return;
    }

    if (!this.district || !this.eMailID || !this.keyword || !this.roleType) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter all the fields",
      });
      return;
    }
    Swal.fire({
      icon: "info",
      title: "Create district admin",
      text: "Do you want to continue?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinnerForCreateDistrictAdminButton = true;
        const timestamp = Date.now();
        const formattedTimestamp = new Date(timestamp)
          .toISOString()
          .replace(/[^\d]/g, ""); // Remove non-digit characters
        const profileID = "KPSID" + formattedTimestamp;
        this.spinner = true;
        const token = this.Authservice.getToken();
        console.log("the order function is called");
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );

        console.log("the headers are", headers);

        const params = new HttpParams()
          .set("district", this.district)
          .set("eMailID", this.eMailID)
          .set("keyword", this.keyword)
          .set("profileID", profileID)
          .set("roleType", this.roleType);

        this.http
          .post(
            KS_ADMIN_URL + "/createKPDistrictAdmin",
            {},
            { headers, params }
          )
          .subscribe(
            (data) => {
              console.log("account created");
              this.toShowTheText = true;

              // this.getDistrictAdmin();
              this.spinner = false;
              this.userAccountName = this.eMailID;
              this.userPassword = this.keyword;
              this.openEmail();
              this.clearTextBox();
              this.spinnerForCreateDistrictAdminButton = false;
              this.getDistrictAdmin();
              // window.location.reload();
              // Handle success response if needed
            },
            (error) => {
              console.error("Error", error);
              this.spinner = false;
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else if (error.status === 400) {
                alert("Error in creating district admin");
                this.spinnerForCreateDistrictAdminButton = false;
                window.location.reload();
              } else if (error.status === 404) {
                alert("Account already exists");
                this.spinnerForCreateDistrictAdminButton = false;
                window.location.reload();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
                this.spinnerForCreateDistrictAdminButton = false;
              }
              // Handle error response
            }
          );
      } else {
        // Handle if the user clicks 'No'
        console.log("User chose not to continue");
      }
    });
  }

  openEmail() {
    const recipient = this.eMailID;

    const subject = "Password";
    const body = `
    Hi,
    
    You have successfully registered in PocketMart Kudumbashree Products.

    Your username : ${recipient}
    Your temporary keyword is : ${this.keyword}.

    Please login to change your password.

    Thanks and regards.
    PocketMart Team.
    
    `;

    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${encodeURIComponent(
      body
    )}`;
    window.location.href = mailtoLink;
  }

  //to get the details
  // getDistrictAdmin() {
  //   const token = this.Authservice.getToken();
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.http
  //     .get(KS_ADMIN_URL + '/kpDistrictAdminsDetails', { headers }) // Changed from post to get
  //     .subscribe(
  //       (data) => {
  //         this.districtAdmin = data;
  //         console.log('District Admin', this.districtAdmin);
  //         // Handle success response if needed
  //       },
  //       (error) => {
  //         console.error('Error', error);
  //         if (error.status === 401) {
  //           console.error('Unauthorized - logging out');
  //           this.Authservice.logOut();
  //         } else {
  //           console.error('Error fetching MEUnitProfilePage:', error);
  //         }
  //         // Handle error response
  //       }
  //     );
  // }

  currentPage: number = 1;
  totalPages: number = 1;
  itemsPerPage: number = 25; 

  getDistrictAdmin(page: number = 1) {
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);

    this.http
    .get(`${KS_ADMIN_URL}/kpDistrictAdminsDetails?page=${page}&limit=${this.itemsPerPage}`, {
        headers,
      })
      .subscribe(
        (data: any) => {
          this.districtAdmin = data.districtAdmins;
          this.currentPage = data.currentPage;
          this.totalPages = data.totalPages;
          console.log("District Admins:", this.districtAdmin);
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
          }
        }
      );
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.getDistrictAdmin(page);
    }
  }

  passwordReset(userName: string) {
    const newPassword = this.passwordService.generateRandomPassword();
    const passwordReset = newPassword;
    const username = userName;

    Swal.fire({
      title: "Are you sure you want to reset the password?",
      text: "The new password is: " + passwordReset,
      icon: "question",
      showCancelButton: true, // Display the cancel button
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel", // Text for the cancel button
      confirmButtonColor: "rgb(38 117 79)",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("newKeyword", passwordReset)
          .set("username", username);

        const options = { headers, params };
        this.http
          .put(KS_ADMIN_URL + "/kpPasswordResetForDistrictAdmin", {}, options)
          .subscribe(
            (data: any) => {
              Swal.fire(data.message);
            },
            (error) => {
              console.log(error);
              if (error.status === 401) {
                console.error("Unauthorized - logging out");
                this.Authservice.logOut();
              } else {
                console.error("Error fetching MEUnitProfilePage:", error);
              }
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Password reset cancelled", "", "info");
      }
    });
  }

  accountDelete(userName: string) {
    const accountDelete = true;
    const username = userName;

    Swal.fire({
      title: "Are you sure you want to delete the account?",
      icon: "question",
      showCancelButton: true, // Display the cancel button
      confirmButtonText: "Continue",
      cancelButtonText: "Cancel", // Text for the cancel button
      confirmButtonColor: "rgb(38 117 79)",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );
        const params = new HttpParams()
          .set("accountDelete", accountDelete)
          .set("username", username);

        const options = { headers, params };
        this.http.put(KS_ADMIN_URL + "/kpDeleteAccount", {}, options).subscribe(
          (data: any) => {
            Swal.fire(data.message);
            this.getDistrictAdmin();
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("delete account cancelled", "", "info");
      }
    });
  }

  clearTextBox() {
    this.district = "";
    this.eMailID = "";
    this.keyword = "";
    this.districtLabel = "";
    this.districtValue = "";
    this.roleType = "";
    this.roleTypeLable = "";
  }

  createPassword() {
    // const newPassword = this.generateRandomPassword();
    const newPassword = this.passwordService.generateRandomPassword();
    this.keyword = newPassword;
  }
}
