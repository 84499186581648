<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Analytics</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Kudumbashree Products > Analytics</p>
    </div>
  </div>

  <!--calendar-->

  <div class="container card headerCard p-4">
    
    <!-- 
    <div class="row" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col">
        <select
          class="form-select"
          id="districtSelect"
          (change)="onDistrictSelected($event)"
          aria-label="Default select example"
        >
          <option value="" disabled selected>Select district</option>

          <option *ngFor="let district of districts" [value]="district.value">
            {{ district.label }}
          </option>
        </select>
      </div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div> -->

    <div class="row" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col">
        <label class="form-label">
          <p class="labelText">Select local body</p>
        </label>
        <select
          class="form-select"
          [(ngModel)]="selectedLevel"
          (change)="onLevelSelection(selectedLevel)"
        >
        <option value="">All</option>
          <option value="blockLevel">Panchayat</option>
          <option value="municipalityLevel">Municipality</option>
          <option value="municipalCorporation">Municipal Corporation</option>
        </select>
      </div>
      <div class="col">
        <label for="district" class="form-label required">
          <p class="labelText">District</p>
        </label>
        <!-- Dropdown for District -->
        <select
          class="form-select inputBoxes"
          id="district"
          [(ngModel)]="selectedDistrict"
          name="district"
          (change)="onDistrictChange()"
        >
          <option value="" disabled selected>Select District</option>
          <option *ngFor="let district of districts" [ngValue]="district">
            {{ district.name }}
          </option>
        </select>
      </div>

      <div class="col">
        <div class="div" *ngIf="selectedMunicipality">
          <label for="municipality" class="form-label required">
            <p class="labelText">Municipality</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="municipality"
            [(ngModel)]="selectedMunicipal"
            name="municipality"
            (click)="onMunicipalityChange()"
          >
            <option value="" disabled selected>Select Municipality</option>
            <option
              *ngFor="let municipality of selectedMunicipality"
              [ngValue]="municipality"
            >
              {{ municipality.name }}
            </option>
          </select>
        </div>
        <div class="div" *ngIf="selectedMunicipalCorporation">
          <label for="districtMunicipalCorporation" class="form-label required">
            <p class="labelText">District Municipal Corporation</p>
          </label>
          <select
            class="form-select inputBoxes"
            id="districtMunicipalCorporation"
            [(ngModel)]="selectedMunicipalCorp"
            name="districtMunicipalCorporation"
            (click)="onMunicipalCorporationChange()"
          >
            <option value="" disabled selected>
              Select District Municipal Corporation
            </option>
            <option
              *ngFor="let mnc of selectedMunicipalCorporation"
              [ngValue]="mnc"
            >
              {{ mnc.name }}
            </option>
          </select>
        </div>
        <div class="div" *ngIf="selectedDistrict && gramPanchayatsLoaded &&localBodyType !==''">
         
          <label for="block" class="form-label required">
            <p class="labelText">Block</p>
          </label>
          <!-- Dropdown for Block -->
          <select
            class="form-select inputBoxes"
            id="block"
            [(ngModel)]="selectedBlk"
            name="block"
            (change)="onBlockChange()"
          >
            <option value="" disabled selected>Select Block</option>
            <option *ngFor="let block of blocks" [ngValue]="block">
              {{ block.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="selectedBlock">
        <label for="gramPanchayat" class="form-label required">
          <p class="labelText">Grama Panchayat</p>
        </label>
        <!-- Dropdown for Gram Panchayat -->
        <select
          class="form-select inputBoxes"
          id="gramPanchayat"
          [(ngModel)]="selectedGP"
          name="gramPanchayat"
          (change)="onPChange()"
        >
          <option value="" disabled selected>Select Grama Panchayat</option>
          <option *ngFor="let gp of gramPanchayats" [ngValue]="gp">
            {{ gp.name }}
          </option>
        </select>
      </div>
    </div>


    <div class="row mt-4">
      <div class="col">
        <mat-form-field appearance="fill" style="margin-right: 10px">
          <mat-label>Enter your start date</mat-label>
          <input
            matInput
            [matDatepicker]="firstPicker"
            [(ngModel)]="startDate"
            [max]="maxDate"
            (dateChange)="onDateChange($event, 'First Date')"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="firstPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #firstPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Enter your end date</mat-label>
          <input
            matInput
            [matDatepicker]="secondPicker"
            [(ngModel)]="endDate"
            [max]="maxDate"
            (dateChange)="onDateChange($event, 'Second Date')"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="secondPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #secondPicker></mat-datepicker>
        </mat-form-field>

        <button
          *ngIf="!spinnerForSearch"
          style="margin-left: 15px"
          type="button"
          (click)="search()"
          class="btn btn-success"
        >
          Search
        </button>
        <div
          *ngIf="spinnerForSearch"
          class="spinner-border text-success"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <!--cards-->
  <div class="row mt-3">
    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total ME Units Registered</p>
      <p class="cardHeading">{{ totalMECount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Not Approved Products</p>
      <p class="cardHeading">{{ pendingProductsCount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Approved Products</p>
      <p class="cardHeading">{{ approvedProductsCount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Warning example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-warning" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total Products</p>
      <p class="cardHeading">{{ totalProducts }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Verified Products</p>
      <p class="cardHeading">{{ verifiedProductsCount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Success example"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-success" style="width: 100%"></div>
      </div>
    </div>

    <!-- <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Approval Pending ME Units</p>
      <p class="cardHeading">{{ pendingMEunitCount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Danger example"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-danger" style="width: 100%"></div>
      </div>
    </div> -->

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Not Verified Products</p>
      <p class="cardHeading">{{ notVerifiedProductsCount }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Warning example"
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-warning" style="width: 100%"></div>
      </div>
    </div>

    <div class="col card p-3 m-2">
      <p style="margin-bottom: 5">Total orders</p>
      <p class="cardHeading">{{ totalOrders }}</p>
      <div
        class="progress"
        role="progressbar"
        aria-label="Info example"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div class="progress-bar bg-info" style="width: 100%"></div>
      </div>
    </div>

    <div class="col p-3 m-2"></div>
  </div>

  <hr />
</div>
