import { Component, OnInit } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Route, Router } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { ServiceRequestListingsService } from "../services/service-request-listings.service";
import { DateAdapter } from '@angular/material/core';

import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.css"],
  providers: [DatePipe],
})
export class OrderDetailsComponent {
  endDate: any = null;
  startDate: any = null;
  spinnerForSearch: boolean = false;
  maxDate: Date;

  orderList: any;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private ServiceRequestListingsService: ServiceRequestListingsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.maxDate = new Date();
    // Optional: set locale if needed
    this.dateAdapter.setLocale('en-GB');
  }

  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case "type_1":
        return "District Farmlivelihood";
      case "type_2":
        return "District NULM";
      case "type_3":
        return "District ME";
      case "type_4":
        return "District SVEP";
      case "type_5":
        return "District SISD";
      // case "type_6":
      //   return "District SD";
      case "type_7":
        return "District AH";
      default:
        return "Unknown Role";
    }
  }

  ngOnInit() {
    const { startDate, endDate } =
      this.ServiceRequestListingsService.getDates();

    if (!startDate) {
      this.startDate = new Date();
    }

    if (startDate) {
      this.startDate = startDate;
      this.endDate = endDate;
    }

    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }

    if (this.startDate) {
      this.checkUser();
    }
    // this.checkUser();
    // const { startDate, endDate } =
    //   this.ServiceRequestListingsService.getDates();
    // if (startDate) {
    //   console.log('service has start date');
    //   this.startDate = startDate;
    //   this.endDate = endDate;
    //   this.checkUser();
    //   // this.searchForServiceListings();
    // }
    // this.filterServiceListings();
  }

  checkUser() {
    if (this.isSuperAdmin() || this.isStateAdmin()) {
      this.getOrderDetails();
    } else if (this.isDistrictAdmin()) {
      this.getOrderDetailsForDistrictAdmin();
    } else if (this.isMarketingAdmin()) {
      this.getOrderDetailsForMarketingDistrictAdmin();
    } else {
      console.log("please check login");
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>, label: string) {
    console.log(`${label}:`, event.value);
    // this.ServiceRequestListingsService.setDates(this.startDate, this.endDate);
  }

  convertUtcToIst(dateStr: string): string {
    let date = new Date(dateStr);
    let istDate = new Date(date.getTime() + (5 * 60 + 30) * 60000);
    return this.datePipe.transform(istDate, "yyyy-MM-dd");
  }

  getOrderDetails() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);

      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForStateAdmin", { headers, params }) // Changed from post to get
        .subscribe(
          (data) => {
            console.log("the data is: " + data);
            this.orderList = data;
            console.log("the orderList is: " + this.orderList);

            // Handle success response if needed
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            // Handle error response
          }
        );
    }
  }

  getOrderDetailsForDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);

      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForDistrictAdmin", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          (data) => {
            console.log("the data is: " + data);
            this.orderList = data;
            console.log("the orderList is: " + this.orderList);

            // Handle success response if needed
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            // Handle error response
          }
        );
    }
  }



  getOrderDetailsForMarketingDistrictAdmin() {
    if (!this.startDate) {
      Swal.fire("Please select start date");
      return;
    }
    if (!this.endDate) {
      this.endDate = this.startDate;
    }
    if (new Date(this.endDate) < new Date(this.startDate)) {
      this.endDate = this.startDate;
    }
    if (this.startDate && this.endDate) {
      const token = this.Authservice.getToken();
      const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
      const params = new HttpParams()
        .set("startDate", this.startDate)
        .set("endDate", this.endDate);

      this.http
        .get(QS_ADMIN_URL + "/orderDetailsForMarketingDistrictAdmin", {
          headers,
          params,
        }) // Changed from post to get
        .subscribe(
          (data) => {
            console.log("the data is: " + data);
            this.orderList = data;
            console.log("the orderList is: " + this.orderList);

            // Handle success response if needed
          },
          (error) => {
            console.error("Error", error);
            if (error.status === 401) {
              console.error("Unauthorized - logging out");
              this.Authservice.logOut();
            } else {
              console.error("Error fetching MEUnitProfilePage:", error);
            }
            // Handle error response
          }
        );
    }
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }
}
