import { Component, OnInit } from "@angular/core";
import { Route, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import { AuthServiceService } from "src/app/auth-service.service";
import { PasswordService } from "../password.service";
import Swal from "sweetalert2";
import { QsIDsService } from "../../services/qs-ids.service";
import { environment } from "src/environments/environment";
const QS_ADMIN_URL = environment.qsAdminUrl;

declare var bootstrap: any;

@Component({
  selector: "app-me-unit-description",
  templateUrl: "./me-unit-description.component.html",
  styleUrls: ["./me-unit-description.component.css"],
})
export class MeUnitDescriptionComponent implements OnInit {
  //variable
  productID: string;
  productName: string = "";
  productPrice: number | undefined;
  productDescription: string = "";
  sellingPrice: number | undefined;
  deliveryCharge: number | undefined;
  availableQuantity: number | undefined;
  shippingTime: number | undefined;
  returnPeriod: number | undefined;
  productWeight: number | undefined;
  productHeight: number | undefined;
  productBreadth: number | undefined;
  productLength: number | undefined;
  cgst: number | undefined;
  sgst: number | undefined;
  minimumOrderQuantity: number | undefined;
  maximumOrderQuantity: number | undefined;

  selectCatagory: boolean = false;
  packageWeight: number | undefined;
  packageLength: number | undefined;
  packageBreadth: number | undefined;
  packageHeight: number | undefined;

  instock: boolean = true;
  returnable: boolean = false;
  productCat: string = "Select Product Catagories";
  productDimensionUnit: string = "cm";
  productWeightDimensionUnit: string = "kg";
  orderCancellation: string = "No";
  HSNcode: number | undefined;
  productThumbnailImage: string = "";

  selectedCategoryID: String = "";
  editProductDBID: any = undefined;

  spinnerButton: boolean = false;

  editProductID: string = "";
  editProducts: any;
  productUnitType: string = "Nos";
  ingredients: string = "";

  districtAdminVerification: boolean = false;
  stateAdminApproval: boolean = false;

  //for images
  checkProductImage: string = "";
  selectedImageUrls: (string | ArrayBuffer)[] = [];
  productImage1: string | undefined;
  productImage2: string | undefined;
  productImage3: string | undefined;
  defaultImages = "assets/defaultImage/defaultimage.jpg";
  productImageData: File[] = [];
  productThumbNailImage: File | undefined;

  uploadButton1Enabled: boolean = true;
  uploadButton2Enabled: boolean = false;
  uploadButton3Enabled: boolean = false;

  profileID: string = "";
  MEUnitDescription: any;
  keyword: string = "";
  userAccount: string = "";
  serviceList: any;
  spinnerForMEUnitDescription: boolean = false;

  spinnerForGivePermissions: boolean = false;
  options: string[] = ["Size", "Weight", "Color"];
  selectedVariant1: any;
  selectedVariant2: string = "";
  variant1: string = "";
  variant2: string = "";

  edit: boolean = false;
  constructor(
    private router: Router,
    private Authservice: AuthServiceService,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private passwordService: PasswordService,
    private QsIDsService: QsIDsService
  ) {}

  ngOnInit() {
    console.log("eheeklhlehlfdasd");
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.productID = queryParams["productID"];
    });

    if (this.productID) {
      this.getMEUnitDescription();
    }
  }

  editProduct(){
    this.edit = true;
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIDsService.getServiceLabel(serviceName);
  }

  //to get the details
  getMEUnitDescription() {
    this.spinnerForMEUnitDescription = true;
    const token = this.Authservice.getToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    const params = new HttpParams().set("productId", this.productID);
    this.http
      .get(QS_ADMIN_URL + "/productsDescriptionDetails", { headers, params })
      .subscribe(
        (data) => {
          this.spinnerForMEUnitDescription = false;
          this.editProducts = data;

          console.log("the productForEdit is :::", this.editProducts);
          this.districtAdminVerification = this.editProducts.verifiedStatus;
          this.stateAdminApproval = this.editProducts.approvalStatus;
          this.productID = this.editProducts.productID;
          this.productName = this.editProducts.productName;
          this.HSNcode = this.editProducts.HSNcode;
          this.productPrice = this.editProducts.mrpPrice;
          this.sellingPrice = this.editProducts.sellingPrice;
          this.productDescription = this.editProducts.productDescription;
          this.productName = this.editProducts.productName;
          this.deliveryCharge = this.editProducts.deliveryCharge;
          this.availableQuantity = this.editProducts.availableQuantity;
          this.returnPeriod = this.editProducts.returnPeriod;
          this.returnable = this.editProducts.returnable;
          this.productWeight = this.editProducts.product.productWeightValue;
          this.productHeight = this.editProducts.product.productHeight;
          this.productBreadth = this.editProducts.product.productBreadth;
          this.productLength = this.editProducts.product.productLength;
          this.cgst = this.editProducts.cgst;
          this.profileID = this.editProducts.meUnitProfileID;
          console.log("Profile ID:", this.profileID);
          this.sgst = this.editProducts.sgst;
          this.productUnitType = this.editProducts.productUnitType;
          this.ingredients = this.editProducts.ingredients;

          this.HSNcode = this.editProducts.HSNcode;
          this.packageLength = this.editProducts.package.packageLength;
          this.packageBreadth = this.editProducts.package.packageBreadth;
          this.packageHeight = this.editProducts.package.packageHeight;
          this.packageWeight = this.editProducts.package.packageWeightValue;
          this.minimumOrderQuantity = this.editProducts.minimumOrderQuantity;
          this.maximumOrderQuantity = this.editProducts.maximumOrderQuantity;
          this.selectedCategoryID = this.editProducts.categories;
          this.instock = this.editProducts.instock;
          //this.productImageURL = this.productForEdit.productImageURL[0];
          // this.checkProductImage = this.productForEdit.productThumbNailImage;
          this.productThumbnailImage = this.editProducts.productThumbNailImage;
          this.productImage1 = this.editProducts.productImageURL[0];
          this.productImage2 = this.editProducts.productImageURL[1];
          console.log("the product image 2 ", this.productImage2);
          this.productImage3 = this.editProducts.productImageURL[2];
          this.selectCategoryForEdit();
          this.productCat = this.productCategoryName;
          this.selectedVariant1 = this.editProducts.selectedVariant1;
          this.selectedVariant2 = this.editProducts.selectedVariant2;
          this.variant1 = this.editProducts.variant1;
          this.variant2 = this.editProducts.variant2;
        },
        (error) => {
          console.error("Error", error);
          if (error.status === 401) {
            console.error("Unauthorized - logging out");
            this.Authservice.logOut();
          } else {
            console.error("Error fetching MEUnitProfilePage:", error);
            this.spinnerForMEUnitDescription = false;
          }
          // Handle error response
        }
      );
  }
  get firstDropDown(): string[] {
    return this.options.filter((option) => option !== this.selectedVariant2);
  }
  get secondDropDown(): string[] {
    return this.options.filter((option) => option !== this.selectedVariant1);
  }
  catagoryOnClick() {
    this.selectCatagory = true;
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  selectButton(ID: string, CAT: string) {
    console.log("the selected button", ID, CAT);
    this.selectCatagory = false;
    this.productCat = CAT;
    this.selectedCategoryID = ID;
  }

  //image upload code
  onImageSelected(event: any, index: number): void {
    const file: File = event.target.files[0];
    this.compressImage(file, index)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result !== null) {
            this.selectedImageUrls[index] = reader.result;
          }
        };
        reader.readAsDataURL(compressedFile as Blob);

        const compressedFileName = this.getCompressedImageName(index);
        const compressedImageFile = new File(
          [compressedFile as Blob],
          compressedFileName
        );
        this.productImageData[index] = compressedImageFile;

        if (index === 0) {
          this.compressFurtherImage(compressedImageFile)
            .then((furtherCompressedFile) => {
              this.productThumbNailImage = furtherCompressedFile;
            })
            .catch((error) => {
              console.error("Thumbnail compression error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Image compression error:", error);
      });

    if (index === 0) {
      this.uploadButton1Enabled = true;
      this.uploadButton2Enabled = !this.editProductDBID;
    } else if (index === 1) {
      this.uploadButton2Enabled = true;
      this.uploadButton3Enabled = !this.editProductDBID;
    } else if (index === 2) {
      this.uploadButton3Enabled = true;
      this.uploadButton2Enabled = true;
    }
  }

  getCompressedImageName(index: number): string {
    const productID = this.productID; // Replace with actual product ID
    return `${productID}_image${index + 1}`;
  }

  compressImage(file: File, index: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (!ctx) {
              reject("Failed to get canvas context");
              return;
            }

            const size = Math.min(image.width, image.height);
            const xOffset = (image.width - size) / 2;
            const yOffset = (image.height - size) / 2;
            const maxWidth = 800;
            const maxHeight = 800;
            let width = size;
            let height = size;

            if (width > height && width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            } else if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              width,
              height
            );

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const compressedFile = new File(
                    [blob],
                    "compressed_image.jpg"
                  );
                  if (compressedFile.size > 300 * 1024) {
                    const quality = (300 * 1024) / compressedFile.size;
                    canvas.toBlob(
                      (furtherBlob) => {
                        if (furtherBlob) {
                          const furtherCompressedFile = new File(
                            [furtherBlob],
                            "further_compressed_image.jpg"
                          );
                          resolve(furtherCompressedFile);
                        } else {
                          reject("Further compressed blob is null");
                        }
                      },
                      "image/jpeg",
                      quality
                    );
                  } else {
                    resolve(compressedFile);
                  }
                } else {
                  reject("Blob is null");
                }
              },
              "image/jpeg",
              0.9
            );
          };
          image.src = event.target.result as string;
        } else {
          reject("FileReader event target or result is null");
        }
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  compressFurtherImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const image = new Image();
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject("Failed to get canvas context");
            return;
          }

          let width = image.width;
          let height = image.height;

          if (file.size > 300 * 1024) {
            const maxSize = 150;
            if (width > height) {
              height *= maxSize / width;
              width = maxSize;
            } else {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  const furtherCompressedFile = new File(
                    [blob],
                    `${this.productID}_thumbnailImage.jpg`
                  );
                  resolve(furtherCompressedFile);
                } else {
                  reject("Thumbnail blob is null");
                }
              },
              "image/jpeg",
              1
            );
          } else {
            const size = Math.min(width, height);
            const xOffset = (width - size) / 2;
            const yOffset = (height - size) / 2;
            const squareSize = 150;

            canvas.width = squareSize;
            canvas.height = squareSize;
            ctx.drawImage(
              image,
              xOffset,
              yOffset,
              size,
              size,
              0,
              0,
              squareSize,
              squareSize
            );

            canvas.toBlob(
              (blob: Blob | null) => {
                if (blob) {
                  const croppedFile = new File(
                    [blob],
                    `${this.productID}_thumbnailImage.jpg`
                  );
                  resolve(croppedFile);
                } else {
                  reject("Cropped blob is null");
                }
              },
              "image/jpeg",
              1
            );
          }
        };
        image.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error("Listing image file reading error:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }

  validateNumberInput(event: KeyboardEvent) {
    const charCode = event.charCode;
    // Allow only numbers, one dot, and no other characters
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
    }
  }

  updateProducts() {
    if (this.isDistrictAdmin()) {
      this.updateProductsDistrictAdmin();
    } else if (this.isStateAdmin() || this.isSuperAdmin()) {
      this.updateProductsStateAdmin();
    } else if (this.isMarketingAdmin()) {
      this.updateProductsDistrictAdmin();
    }
  }

  updateProductsDistrictAdmin() {
    this.sellingPrice = Number(this.sellingPrice);
    this.productPrice = Number(this.productPrice);

    let missingFields: string[] = [];

    if (!this.productName) missingFields.push("Product Name");
    if (!this.productPrice) missingFields.push("MRP Price");
    if (!this.sellingPrice) missingFields.push("Selling Price");
    if (!this.productDescription) missingFields.push("Product Description");
    if (!this.selectedCategoryID) missingFields.push("Category");
    

    if (missingFields.length > 0) {
      Swal.fire({
        title: "Missing Fields",
        html: `Please fill out the following fields:<br><ul>${missingFields
          .map((field) => `<li>${field}</li>`)
          .join("")}</ul>`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      return; // Stop execution if fields are missing
    }

    if (this.sellingPrice > this.productPrice) {
      console.log("the this.sellingPrice is insude: " + this.sellingPrice);
      console.log("the this.productPrice is inside: " + this.productPrice);
      Swal.fire({
        title: "Selling price should be less than the MRP price",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the product updation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();

        formData.append(
          "districtAdminVerification",
          this.districtAdminVerification?.toString() || ""
        );

        formData.append("productName", this.productName);
        formData.append("ingredients", this.ingredients);
        formData.append("productUnitType", this.productUnitType);
        formData.append("profileID", this.profileID);
        formData.append("HSNcode", this.HSNcode?.toString() || "");
        formData.append("orderCancellation", this.orderCancellation);
        formData.append("productUnit", this.productDimensionUnit);
        formData.append("productWeightUnit", this.productWeightDimensionUnit);
        formData.append("mrpPrice", this.productPrice?.toString() || "");
        formData.append("productDescription", this.productDescription);
        formData.append("sellingPrice", this.sellingPrice?.toString() || "");
        formData.append(
          "deliveryCharge",
          this.deliveryCharge?.toString() || ""
        );
        formData.append(
          "availableQuantity",
          this.availableQuantity?.toString() || ""
        );
        formData.append("shippingTime", this.shippingTime?.toString() || "");
        formData.append("returnPeriod", this.returnPeriod?.toString() || "");
        formData.append(
          "productWeightValue",
          this.productWeight?.toString() || ""
        );
        formData.append("productHeight", this.productHeight?.toString() || "");
        formData.append(
          "productBreadth",
          this.productBreadth?.toString() || ""
        );
        formData.append("productLength", this.productLength?.toString() || "");
        formData.append("cgst", this.cgst?.toString() || "");
        formData.append("sgst", this.sgst?.toString() || "");
        formData.append(
          "minimumOrderQuantity",
          this.minimumOrderQuantity?.toString() || ""
        );
        formData.append(
          "maximumOrderQuantity",
          this.maximumOrderQuantity?.toString() || ""
        );

        formData.append(
          "packageWeightValue",
          this.packageWeight?.toString() || ""
        );
        formData.append("packageLength", this.packageLength?.toString() || "");
        formData.append(
          "packageBreadth",
          this.packageBreadth?.toString() || ""
        );
        formData.append("packageHeight", this.packageHeight?.toString() || "");
        formData.append("productID", this.productID);
        formData.append("instock", this.instock?.toString() || "");
        formData.append("returnable", this.returnable?.toString() || "");
        formData.append(
          "categories",
          this.selectedCategoryID?.toString() || ""
        );
        formData.append("selectedVariant1", this.selectedVariant1);
        formData.append("selectedVariant2", this.selectedVariant2);
        formData.append("variant1", this.variant1);
        formData.append("variant2", this.variant2);

        // Append each selected file to the FormData object
        for (let i = 0; i < this.productImageData.length; i++) {
          formData.append("images", this.productImageData[i]);
        }

        // Append thumbnail image to formData
        if (this.productThumbNailImage) {
          formData.append("thumbnailImage", this.productThumbNailImage);
        }

        // Log formData to check values before sending
        console.log("upload image file: ", this.productImageData);
        console.log("The form data is:", formData);

        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );

        this.spinnerButton = true;

        // HTTP POST request
        this.http
          .put(QS_ADMIN_URL + "/updateKPProduct", formData, {
            headers: headers,
            responseType: "text",
          })
          .subscribe(
            (response) => {
              console.log("Response from server:", response);
              this.spinnerButton = false;
              this.getMEUnitDescription();
              Swal.fire({
                title: "Updated Successfully!",
                text: "The product has been updated.",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Only navigate when the OK button is clicked
                  this.router.navigate(["/productList"]);
                }
              });
            },
            (error) => {
              console.error("Error occurred:", error);
              this.spinnerButton = false;
            }
          );
      } else {
        // If the user cancels, do nothing
        Swal.fire("Cancelled", "Product registration was cancelled.", "info");
      }
    });
  }

  updateProductsStateAdmin() {
    this.sellingPrice = Number(this.sellingPrice);
    this.productPrice = Number(this.productPrice);

    let missingFields: string[] = [];

    if (!this.productName) missingFields.push("Product Name");
    if (!this.productPrice) missingFields.push("MRP Price");
    if (!this.sellingPrice) missingFields.push("Selling Price");
    if (!this.productDescription) missingFields.push("Product Description");
    if (!this.selectedCategoryID) missingFields.push("Category");


    if (missingFields.length > 0) {
      Swal.fire({
        title: "Missing Fields",
        html: `Please fill out the following fields:<br><ul>${missingFields
          .map((field) => `<li>${field}</li>`)
          .join("")}</ul>`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      return; // Stop execution if fields are missing
    }

    if (this.sellingPrice > this.productPrice) {
      console.log("the this.sellingPrice is insude: " + this.sellingPrice);
      console.log("the this.productPrice is inside: " + this.productPrice);
      Swal.fire({
        title: "Selling price should be less than the MRP price",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to proceed with the product updation?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData();

        formData.append(
          "stateAdminApproval",
          this.stateAdminApproval?.toString() || ""
        );

        formData.append("productName", this.productName);
        formData.append("ingredients", this.ingredients);
        formData.append("productUnitType", this.productUnitType);
        formData.append("profileID", this.profileID);
        formData.append("HSNcode", this.HSNcode?.toString() || "");
        formData.append("orderCancellation", this.orderCancellation);
        formData.append("productUnit", this.productDimensionUnit);
        formData.append("productWeightUnit", this.productWeightDimensionUnit);
        formData.append("mrpPrice", this.productPrice?.toString() || "");
        formData.append("productDescription", this.productDescription);
        formData.append("sellingPrice", this.sellingPrice?.toString() || "");
        formData.append(
          "deliveryCharge",
          this.deliveryCharge?.toString() || ""
        );
        formData.append(
          "availableQuantity",
          this.availableQuantity?.toString() || ""
        );
        formData.append("shippingTime", this.shippingTime?.toString() || "");
        formData.append("returnPeriod", this.returnPeriod?.toString() || "");
        formData.append(
          "productWeightValue",
          this.productWeight?.toString() || ""
        );
        formData.append("productHeight", this.productHeight?.toString() || "");
        formData.append(
          "productBreadth",
          this.productBreadth?.toString() || ""
        );
        formData.append("productLength", this.productLength?.toString() || "");
        formData.append("cgst", this.cgst?.toString() || "");
        formData.append("sgst", this.sgst?.toString() || "");
        formData.append(
          "minimumOrderQuantity",
          this.minimumOrderQuantity?.toString() || ""
        );
        formData.append(
          "maximumOrderQuantity",
          this.maximumOrderQuantity?.toString() || ""
        );

        formData.append(
          "packageWeightValue",
          this.packageWeight?.toString() || ""
        );
        formData.append("packageLength", this.packageLength?.toString() || "");
        formData.append(
          "packageBreadth",
          this.packageBreadth?.toString() || ""
        );
        formData.append("packageHeight", this.packageHeight?.toString() || "");
        formData.append("productID", this.productID);
        formData.append("instock", this.instock?.toString() || "");
        formData.append("returnable", this.returnable?.toString() || "");
        formData.append(
          "categories",
          this.selectedCategoryID?.toString() || ""
        );
        formData.append(
          "selectedVariant1",
          this.selectedVariant1?.toString() || ""
        );
        formData.append(
          "selectedVariant2",
          this.selectedVariant2?.toString() || ""
        );
        formData.append("variant1", this.variant1?.toString() || "");
        formData.append("variant2", this.variant2?.toString() || "");
        // Append each selected file to the FormData object
        for (let i = 0; i < this.productImageData.length; i++) {
          formData.append("images", this.productImageData[i]);
        }

        // Append thumbnail image to formData
        if (this.productThumbNailImage) {
          formData.append("thumbnailImage", this.productThumbNailImage);
        }

        // Log formData to check values before sending
        console.log("upload image file: ", this.productImageData);
        console.log("The form data is:", formData);

        const token = this.Authservice.getToken();
        const headers = new HttpHeaders().set(
          "Authorization",
          `Bearer ${token}`
        );

        this.spinnerButton = true;

        // HTTP POST request
        this.http
          .put(QS_ADMIN_URL + "/updateKPProductStateAdmin", formData, {
            headers: headers,
            responseType: "text",
          })
          .subscribe(
            (response) => {
              console.log("Response from server:", response);
              this.spinnerButton = false;
              this.getMEUnitDescription();
              Swal.fire({
                title: "Updated Successfully!",
                text: "The product has been updated.",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Only navigate when the OK button is clicked
                  this.router.navigate(["/productList"]);
                }
              });
            },
            (error) => {
              console.error("Error occurred:", error);
              this.spinnerButton = false;
            }
          );
      } else {
        // If the user cancels, do nothing
        Swal.fire("Cancelled", "Product registration was cancelled.", "info");
      }
    });
  }

  //To check the user role
  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }

  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() &&
      this.Authservice.isMarketingDistrictAdmin()
    );
  }

  productCategoryName: any;
  selectCategoryForEdit() {
    switch (this.selectedCategoryID) {
      case "ID_CHI":
        this.productCategoryName = "Food Products/Chips";
        break;
      case "ID_CRA":
        this.productCategoryName = "Food Products/Crakers";
        break;
      case "ID_CAB":
        this.productCategoryName = "Food Products/Cake&Breads";
        break;
      case "ID_BIS":
        this.productCategoryName = "Food Products/Biscuits&Cookies";
        break;
      case "ID_SWT":
        this.productCategoryName = "Food Products/Sweets ";
        break;

      case "ID_DAT":
        this.productCategoryName = "Food Products/Dates";
        break;
      case "ID_CHS":
        this.productCategoryName = "Food Products/Seeds";
        break;
      case "ID_CAS":
        this.productCategoryName = "Food Products/Nuts";
        break;

      case "ID_MCH":
        this.productCategoryName = "Food Products/Chocolate";
        break;
      case "ID_CUR":
        this.productCategoryName = "Food Products/Curd";
        break;
      case "ID_MLK":
        this.productCategoryName = "Food Products/Milk";
        break;
      case "ID_GHE":
        this.productCategoryName = "Food Products/Ghee";
        break;
      case "ID_SAB":
        this.productCategoryName = "Food Products/Butter";
        break;
      case "ID_PUM":
        this.productCategoryName = "Food Products/Paneer";
        break;

      case "ID_DIM":
        this.productCategoryName = "Food Products/Tiffin Mix";
        break;
      case "ID_SAM":
        this.productCategoryName = "Food Products/Masala Mix";
        break;
      case "ID_ADA":
        this.productCategoryName = "Food Products/Payasam Mix";
        break;
      case "ID_HEA":
        this.productCategoryName = "Food Products/Health Mix";
        break;
      case "ID_CAM":
        this.productCategoryName = "Food Products/Cake Mix";
        break;
      case "ID_APP":
        this.productCategoryName = "Food Products/Dessert Mix";
        break;
      case "ID_NUT":
        this.productCategoryName = "Food Products/Dessert Mix";
        break;
      case "ID_BAB":
        this.productCategoryName = "Food Products/Baby Food Products";
        break;

      case "ID_CHA":
        this.productCategoryName = "Food Products/Powders";
        break;
      case "ID_VEG":
        this.productCategoryName = "Food Products/Veg";
        break;
      case "ID_NON":
        this.productCategoryName = "Food Products/Non-Veg";
        break;
      case "ID_MAN":
        this.productCategoryName = "Food Products/Mixed";
        break;

      case "ID_CHP":
        this.productCategoryName = "Food Products/Chilli Powder";
        break;
      case "ID_TUR":
        this.productCategoryName = "Food Products/Turmeric Powder";
        break;
      case "ID_COR":
        this.productCategoryName = "Food Products/Corriander Powder";
        break;
      case "ID_MEM":
        this.productCategoryName = "Food Products/Masala Powder";
        break;
      case "ID_PEP":
        this.productCategoryName = "Food Products/Spices";
        break;

      case "ID_SUG":
        this.productCategoryName = "Food Products/Sugar";
        break;
      case "ID_BRS":
        this.productCategoryName = "Food Products/Brown Sugar";
        break;
      case "ID_JAG":
        this.productCategoryName = "Food Products/Jaggery Powder";
        break;
      case "ID_RBJ":
        this.productCategoryName = "Food Products/Round Block Jaggery";
        break;
      case "ID_CRY":
        this.productCategoryName = "Food Products/Crystal Salt";
        break;

      case "ID_COC":
        this.productCategoryName = "Food Products/Coconut Oil";
        break;
      case "ID_SUN":
        this.productCategoryName = "Food Products/Sunflower Oil";
        break;
      case "ID_MUS":
        this.productCategoryName = "Food Products/Mustard Oil";
        break;
      case "ID_RIB":
        this.productCategoryName = "Food Products/RiceBran Oil";
        break;
      case "ID_OLI":
        this.productCategoryName = "Food Products/Olive Oil";
        break;
      case "ID_GRN":
        this.productCategoryName = "Food Products/Groundnut Oil";
        break;
      case "ID_SEO":
        this.productCategoryName = "Food Products/Sesame Oil";
        break;

      case "ID_CFL":
        this.productCategoryName = "Food Products/Flour";
        break;
      case "ID_GRP":
        this.productCategoryName = "Food Products/Pulses";
        break;
      case "ID_MAT":
        this.productCategoryName = "Food Products/Rice";
        break;

      case "ID_COF":
        this.productCategoryName = "Food Products/Coffee";
        break;
      case "ID_TEA":
        this.productCategoryName = "Food Products/Tea";
        break;
      case "ID_SQU":
        this.productCategoryName = "Food Products/Soft Drinks";
        break;
      case "ID_SQA":
        this.productCategoryName = "Food Products/Squash";
        break;

      case "ID_JAM":
        this.productCategoryName = "Food Products/Jams";
        break;
      case "ID_HON":
        this.productCategoryName = "Food Products/Honey";
        break;
      case "ID_PEA":
        this.productCategoryName = "Food Products/Spreads";
        break;

      case "ID_MLT":
        this.productCategoryName = "Food Products/Millets";
        break;

      case "ID_FVG":
        this.productCategoryName = "Food Products/Fresh Food Products";
        break;
      case "ID_FRU":
        this.productCategoryName = "Food Products/Fruits";
        break;
      case "ID_MUR":
        this.productCategoryName = "Food Products/Muringa Products";
        break;

      case "ID_FAW":
        this.productCategoryName = "Personal Care/FaceWash & Scrubs";
        break;
      case "ID_CRM":
        this.productCategoryName = "Personal Care/Cream & Moisturizer";
        break;
      case "ID_BLO":
        this.productCategoryName = "Personal Care/Body Lotion & Oils";
        break;
      case "ID_SSR":
        this.productCategoryName = "Personal Care/Sunscreens";
        break;

      case "ID_SHA":
        this.productCategoryName = "Personal Care/ Shampoos & Conditioners";
        break;
      case "ID_HAO":
        this.productCategoryName = "Personal Care/HairOils Serums & Gels";
        break;
      case "ID_COL":
        this.productCategoryName = "Personal Care/Colors & Hennas";
        break;

      case "ID_FON":
        this.productCategoryName = "Personal Care/Foundation & Concealers";
        break;
      case "ID_COP":
        this.productCategoryName = "Personal Care/Compact & Primers";
        break;
      case "ID_KAJ":
        this.productCategoryName =
          "Personal Care/Kajals Eyeliners & Eyeshadows";
        break;
      case "ID_LST":
        this.productCategoryName = "Personal Care/Lipsticks LipLiners LipBalms";
        break;
      case "ID_NAP":
        this.productCategoryName = "Personal Care/Nail Polish";
        break;

      case "ID_PER":
        this.productCategoryName = "Personal Care/Perfumes";
        break;
      case "ID_ROL":
        this.productCategoryName = "Personal Care/Roll-ons";
        break;
      case "ID_BOS":
        this.productCategoryName = "Personal Care/Body Spray";
        break;

      case "ID_BOW":
        this.productCategoryName = "Personal Care/Body Wash";
        break;
      case "ID_SOA":
        this.productCategoryName = "Personal Care/Soaps";
        break;
      case "ID_BOL":
        this.productCategoryName = "Personal Care/Body Oils";
        break;
      case "ID_BAC":
        this.productCategoryName = "Personal Care/Bathing Accessories";
        break;
      case "ID_HAW":
        this.productCategoryName = "Personal Care/Hand Wash";
        break;
      case "ID_SAP":
        this.productCategoryName = "Personal Care/Sanitary pads";
        break;
      case "ID_MEN":
        this.productCategoryName = "Fashion/Men's Wear";
        break;
      case "ID_WOM":
        this.productCategoryName = "Fashion/Ladies Wear";
        break;
      case "ID_KID":
        this.productCategoryName = "Fashion/Kids Wear";
        break;
      case "ID_HLM":
        this.productCategoryName = "Fashion/Handlooms";
        break;
      case "ID_FTW":
        this.productCategoryName = "Fashion/FootWear";
        break;
      case "ID_BAW":
        this.productCategoryName = "Fashion/Bags & Wallets";
        break;

      case "ID_FUR":
        this.productCategoryName = "Home & Kitchen/Furnitures";
        break;
      case "ID_IAS":
        this.productCategoryName = "Home & Kitchen/Iron & Steel";
        break;
      case "ID_CRO":
        this.productCategoryName = "Home & Kitchen/Crockery Items";
        break;

      case "ID_WUD":
        this.productCategoryName = "Home & Kitchen/Wooden Handicrafts";
        break;
      case "ID_STL":
        this.productCategoryName = "Home & Kitchen/Steel Handicraft";
        break;
      case "ID_CLY":
        this.productCategoryName = "Home & Kitchen/Clay products";
        break;
      case "ID_NTP":
        this.productCategoryName = "Home & Kitchen/Nettipattom";
        break;
      case "ID_ARN":
        this.productCategoryName = "Home & Kitchen/Aranmula Kannadi";
        break;
      case "ID_PAP":
        this.productCategoryName = "Home & Kitchen/Paper Craft";
        break;
      case "ID_LOT":
        this.productCategoryName = "Home & Kitchen/Lotions";
        break;
      case "ID_SOD":
        this.productCategoryName = "Home & Kitchen/Soaps & Detergents";
        break;
      case "ID_TOW":
        this.productCategoryName = "Home & Kitchen/Towels";
        break;
      case "ID_BED":
        this.productCategoryName =
          "Home & Kitchen/Bedsheet Bed Sheets & Pillow Covers";
        break;
      case "ID_IND":
        this.productCategoryName = "Home & Kitchen/Indoor Plants";
        break;
      case "ID_OUT":
        this.productCategoryName = "Home & Kitchen/Outdoor Plants";
        break;
      case "ID_SED":
        this.productCategoryName = "Home & Kitchen/Seeds";
        break;
      case "ID_FER":
        this.productCategoryName = "Home & Kitchen/Fertilizers ";
        break;

      case "ID_BAM":
        this.productCategoryName = "Home & Kitchen/Bamboo products ";
        break;
      case "ID_TRI":
        this.productCategoryName = "Home & Kitchen/Tribal products ";
        break;
      case "ID_BAN":
        this.productCategoryName = "Home & Kitchen/Bangles ";
        break;

      case "ID_EAR":
        this.productCategoryName = "Home & Kitchen/Earings ";
        break;

      case "ID_NOP":
        this.productCategoryName = "Home & Kitchen/Nosepins ";
        break;
      case "ID_ANK":
        this.productCategoryName = "Home & Kitchen/Anklets ";
        break;
      case "ID_RIN":
        this.productCategoryName = "Home & Kitchen/Rings ";
        break;
      case "ID_CHN":
        this.productCategoryName = "Home & Kitchen/Chain ";
        break;
      case "ID_UIB":
        this.productCategoryName = "Home & Kitchen/Umbrella ";
        break;
      case "ID_TOY":
        this.productCategoryName = "Home & Kitchen/Toys ";
        break;
      case "ID_BUD":
        this.productCategoryName = "Home & Kitchen/Buds Products ";
        break;
      case "ID_ELE":
        this.productCategoryName = "Home & Kitchen/Electronics ";
        break;
      default:
        this.productCategoryName = "Select product category";
        break;
    }
  }
}
