<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="row">
    <div class="col">
      <p class="myAccount"><u>My Account</u></p>
    </div>

    <div class="col" (click)="goBack()">
      <p class="pageReference">Quick serve > My Account</p>
    </div>
  </div>

  <button
    *ngIf="spinnerForProfilePage"
    class="btn btn-primary"
    type="button"
    disabled
  >
    <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
    <span role="status">Loading...</span>
  </button>

  <div class="row" *ngIf="!spinnerForProfilePage">
    <div class="col-3 card m-2 p-4">
      <img
        class="profileImage"
        src="../../../assets/profileImage/profileImage.png"
        alt=""
      />
      <P style="text-align: center; margin-top: 15px; color: gray"
        >ProfileID : {{ profileID }}</P
      >
      <p>
        Approval Status :
        <span class="pending" *ngIf="!approvalStatus">PENDING</span>
        <span *ngIf="approvalStatus" class="approved">APPROVED</span>
      </p>

      <div class="div" *ngIf="QRCode && QRCode.length <= 0">
        <div class="row mt-2">
          <div class="col">
            <div class="mb-3">
              <label for="imageUpload4" class="form-label"
                >Upload QR Code</label
              >
              <input
                type="file"
                class="form-control"
                id="imageUpload4"
                (change)="onQRCodeUpload($event, 1)"
              />
            </div>
          </div>
        </div>

        <div
          class="row mt-3"
          *ngIf="QRCodeImageUrls && QRCodeImageUrls.length >= 1"
        >
          <div class="col">
            <img
              [src]="QRCodeImageUrls[0]"
              style="border-radius: 5px"
              class="img-fluid"
              alt="Uploaded Image 1"
            />
          </div>
        </div>
      </div>
      <div class="row m-0 mt-2" *ngIf="QRCode && QRCode.length == 0">
        <button (click)="uploadQRCode()" type="button" class="btn btn-success">
          Upload QRCode
        </button>
      </div>

      <div class="div" *ngIf="QRCode && QRCode.length >= 1">
        <p style="margin-bottom: 0"><u>QRCode Image</u></p>
        <img
          class="profileImage"
          [src]="QRCode.length >= 1 ? QRCode[0].QRCodeImageUrl[0] : ''"
          alt="QRCode image"
        />
      </div>

      <div class="row mt-4">
        <div class="col">
          <button
            (click)="openAddServiceCard()"
            type="button"
            style="width: 100%"
            class="btn btn-outline-primary"
          >
            Add Service
          </button>
        </div>
      </div>

      <!-------------------------------------------------------------------------->
      <div
        class="popup-overlay"
        *ngIf="addService"
        (click)="selectedServiceCancel()"
      ></div>
      <div class="popup" *ngIf="addService">
        <div class="container mt-3 pb-3">
          <div class="row mt-3">
            <div class="col">
              <div class="mb-3">
                <label for="serviceSelect" class="form-label"
                  >Select Service</label
                >
                <select
                  id="serviceSelect"
                  class="form-select"
                  (change)="onServiceChange($event)"
                  aria-label="Default select example"
                >
                  <option value="" disabled selected>
                    Open this select menu
                  </option>
                  <option *ngFor="let service of services" [value]="service">
                    {{ service }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label"
                  >Service Description</label
                >
                <textarea
                  class="form-control"
                  [(ngModel)]="serviceDescription"
                  maxlength="300"
                  placeholder="Enter service description"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="meUnitName" class="form-label">Rate/hrs</label>
                <input
                  type="number"
                  [(ngModel)]="ratePerHour"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="meUnitName"
                  placeholder="Enter price"
                  #price
                  (input)="checkExperience(price)"
                />
              </div>
            </div>
          </div>

          <!-- Display Uploaded Image Section 1 -->
          <div class="row mt-3" >
            <div class="col">
              <img  *ngIf="imageUrls[0]"
                [src]="imageUrls[0]"
                style="border-radius: 5px"
                class="img-fluid"
                alt="Uploaded Image 1"
              />
            </div>
            <div class="col" >
              <img *ngIf="imageUrls[1]"
                [src]="imageUrls[1]"
                style="border-radius: 5px"
                class="img-fluid"
                alt="Uploaded Image 2"
              />
            </div>
            <div class="col" >
              <img *ngIf="imageUrls[2]"
                [src]="imageUrls[2]"
                style="border-radius: 5px"
                class="img-fluid"
                alt="Uploaded Image 3"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="imageUpload1" class="form-label"
                  >Upload Image 1</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="imageUpload1"
                  (change)="onFileChange($event, 1)"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for="imageUpload2" class="form-label"
                  >Upload Image 2</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="imageUpload2"
                  (change)="onFileChange($event, 2)"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for="imageUpload3" class="form-label"
                  >Upload Image 3</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="imageUpload3"
                  (change)="onFileChange($event, 3)"
                />
              </div>
            </div>
          </div>

          <!-- Display Uploaded Image Section 2 -->
          <!-- <div class="row mt-3" >
            <div class="col" *ngIf="imageUrls[1]">
              <img
                [src]="imageUrls[1]"
                style="border-radius: 5px"
                class="img-fluid"
                alt="Uploaded Image 2"
              />
            </div>
          </div> -->

          <!-- Image Upload Section 2 -->
          <!-- <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="imageUpload2" class="form-label"
                  >Upload Image 2</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="imageUpload2"
                  (change)="onFileChange($event, 2)"
                />
              </div>
            </div>
          </div> -->

          <!-- Display Uploaded Image Section 3 -->
          <!-- <div class="row mt-3" >
            <div class="col" *ngIf="imageUrls[2]">
              <img
                [src]="imageUrls[2]"
                style="border-radius: 5px"
                class="img-fluid"
                alt="Uploaded Image 3"
              />
            </div>
          </div> -->

          <!-- Image Upload Section 3 -->
          <!-- <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="imageUpload3" class="form-label"
                  >Upload Image 3</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="imageUpload3"
                  (change)="onFileChange($event, 3)"
                />
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col">
              <button
                (click)="selectedServiceCancel()"
                type="button"
                class="btn btn-outline-secondary"
              >
                Cancel
              </button>
            </div>
            <div class="col">
              <button
                (click)="addServiceType()"
                type="button"
                style="float: right"
                class="btn btn-success"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-------------------------------------------------------------------------->
    </div>
    <div class="col card m-2 p-4">
      <div class="row">
        <div class="col-10">
          <p style="color: gray">User Information</p>
        </div>
        <div class="col">
          <!-- <button
            type="button"
            (click)="enableEdit()"
            style="float: right"
            class="btn btn-danger"
          >
            <i class="fa-solid fa-pen-to-square"></i> edit
          </button> -->
        </div>
      </div>
      <!-------->
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">ME Unit Name</label>
            <input
              type="text"
              [(ngModel)]="MEUnitName"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              maxlength="50"
              placeholder="enter ME Unit Name"
            />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">Phone Number</label>
            <input
              type="number"
              [(ngModel)]="phoneNumber"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter Phone Number"
              #phoneInput
              (input)="checkLength(phoneInput)"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">ME APP ID</label>
            <input
              type="text"
              [(ngModel)]="meAppID"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter ME APP ID"
              maxlength="50"
            />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label"
              >CDS Registration ID</label
            >
            <input
              type="text"
              [(ngModel)]="cdsRegID"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter CDS Registration ID"
              maxlength="50"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">Name</label>
            <input
              type="text"
              [(ngModel)]="userName"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter Name"
              maxlength="50"
            />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">E-mail ID</label>
            <input
              type="text"
              [(ngModel)]="mailID"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter e-mail ID"
              maxlength="50"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">Address</label>
            <div class="div" *ngIf="edit">
              <app-place-autocomplete
                (placeChanged)="fromValue = $event"
              ></app-place-autocomplete>
            </div>
            <input
              *ngIf="!edit"
              type="text"
              [(ngModel)]="address"
              [ngModelOptions]="{ standalone: true }"
              disabled
              class="form-control"
              id="meUnitName"
              placeholder="Enter address"
            />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">Pin code</label>
            <input
              type="number"
              [(ngModel)]="pinCode"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="!edit"
              class="form-control"
              id="meUnitName"
              placeholder="Enter Pin Code"
              #pincode
              (input)="checkPincodeLength(pincode)"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="meUnitName" class="form-label">District</label>
            <input
              type="text"
              [(ngModel)]="district"
              [ngModelOptions]="{ standalone: true }"
              disabled
              class="form-control"
              id="meUnitName"
              placeholder="Enter District"
              maxlength="50"
            />
          </div>
        </div>
        <div class="col"></div>
      </div>

      <div class="row" *ngIf="edit">
        <div class="col"></div>
        <div class="col">
          <button
            type="button"
            (click)="updateServiceProviderDescription()"
            style="float: right"
            class="btn btn-success w-50"
          >
            Update
          </button>
        </div>
      </div>

      <!-------->
    </div>
  </div>

  <div class="row mt-3" *ngIf="!spinnerForProfilePage">
    <p style="font-size: large; font-weight: bold"><u>Offered Services</u></p>
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Service</th>
            <th scope="col">Image</th>
            <th scope="col">Rate</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody *ngFor="let serviceList of serviceList; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>{{ getServiceLabel(serviceList.serviceName) }}</td>
            <td>
              <div *ngFor="let imageUrl of serviceList.imageUrl">
                <img
                  [src]="imageUrl"
                  alt="{{ serviceList.serviceName }}"
                  style="
                    max-width: 100px;
                    max-height: 100px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                  "
                />
              </div>
            </td>
            <td>{{ serviceList.ratePerHour }}</td>
            <td>{{ serviceList.serviceDescription }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
