<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="createULB"><u>Products Description</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Products Listings > Products Description</p>
    </div>
  </div>

  

  <div class="row">
    <div class="row" *ngIf="isDistrictAdmin() || isMarketingAdmin()">
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input instockToggle"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckChecked"
            [(ngModel)]="districtAdminVerification"
          />

          <label
            class="form-check-label instockLabel"
            for="flexSwitchCheckChecked"
            >District Admin Verification</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-3" *ngIf="isStateAdmin() || isSuperAdmin()">
      <div class="col">
        <div class="form-check form-switch">
          <input
            class="form-check-input instockToggle"
            type="checkbox"
            [disabled]="!districtAdminVerification"
            role="switch"
            id="flexSwitchCheckChecked"
            [(ngModel)]="stateAdminApproval"
          />

          <label
            class="form-check-label instockLabel"
            for="flexSwitchCheckChecked"
            >State Admin Approval</label
          >
        </div>
      </div>
    </div>
    <!--first page starts-->
    <div class="div mt-4" *ngIf="!selectCatagory">
      <div class="row">
        <div class="col card p-4 m-3">
          
          <div class="row mb-3">
            <div class="col">
              <button type="button" (click)="editProduct()" class="btn btn-success">Edit product</button>
            </div>
            <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input instockToggle"
                  type="checkbox"
                  [disabled]="!edit"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  [(ngModel)]="instock"
                />
                <label
                  class="form-check-label instockLabel"
                  for="flexSwitchCheckChecked"
                  >Instock</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" ProductName" class="form-label"
                  >Product Name <span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="productName"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="productName"
                  maxlength="30"
                  placeholder="Enter Product Name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" HSNcode" class="form-label"
                  >HSN code <span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="HSNcode"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="HSNcode"
                  placeholder="Enter HSNcode"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 8) this.value = this.value.slice(0, 8);"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="productPrice" class="form-label"
                  >MRP Price<span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="productPrice"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="productPrice"
                  maxlength="50"
                  placeholder="Enter MRP Price"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for=" sellingPrice" class="form-label"
                  >Selling Price <span style="color: red">*</span></label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="sellingPrice"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="sellingPrice"
                  placeholder="Enter Selling Price"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                />
              </div>
            </div>
            <div class="col">
              <label for="inputState" class="form-label"> Select Unit </label>
              <select
                id="inputState productUnit"
                class="form-select inputBoxes"
                [(ngModel)]="productUnitType"
                [disabled]="!edit"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="Nos" selected>Nos</option>
                <option value="Kg">Kg</option>
                <option value="Packet">Packet</option>
                <option value="Dozen">Dozen</option>
                <option value="litre">Litre</option>
                <option value="Bottle">Bottle</option>
                <option value="Bottle">Box</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="productDescription" class="form-label"
                  >Product Description <span style="color: red">*</span></label
                >
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="!edit"
                  placeholder="Enter Product Description "
                  id="floatingTextarea"
                  [(ngModel)]="productDescription"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="80"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="Product Ingredients" class="form-label"
                  >Product Ingredients
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="!edit"
                  placeholder="Enter Product Ingredients "
                  id="floatingTextarea"
                  [(ngModel)]="ingredients"
                  [ngModelOptions]="{ standalone: true }"
                  maxlength="200"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-5">
              <button
                type="button"
                [disabled]="!edit"
                class="btn btn-primary"
                style="width: 100%"
                (click)="catagoryOnClick()"
              >
                Categories
              </button>
            </div>
            <div class="col pe-0">
              <label for="productCatagories" class="form-label"
                >{{ productCat }}<span style="color: red">*</span></label
              >
            </div>

            <div class="row pt-3 pe-0">
              <!-- First Dropdown -->
              <div class="col pt-0 align-items-center">
                <label for="inputState" class="form-label">
                  <p class="labelText">Select Variant</p>
                </label>
                <select
                [disabled]="!edit"
                  id="inputState"
                  class="form-select inputBoxes"
                  [(ngModel)]="selectedVariant1"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let option of firstDropDown" [value]="option">
                    {{ option }}
                  </option>
                </select>
                <br />

                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="variant1"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control variantclass"
                  placeholder="Enter {{ selectedVariant1 }}"
                  maxlength="30"
                />
              </div>

              <!-- Second Dropdown -->
              <div class="col">
                <label for="inputStateDisplay" class="form-label">
                  <p class="labelText">Select Variant</p>
                </label>
                <select
                [disabled]="!edit"
                  id="inputStateDisplay"
                  class="form-select inputBoxes"
                  [(ngModel)]="selectedVariant2"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option
                    *ngFor="let option of secondDropDown"
                    [value]="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <br />
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="variant2"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control variantclass"
                  placeholder="Enter {{ selectedVariant2 }}"
                  maxlength="30"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for=" availableQuantity" class="form-label"
                  >Available Quantity</label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="availableQuantity"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="availableQuantity"
                  placeholder="Enter Available Quantity"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 4) this.value = this.value.slice(0, 4);"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col mb-3 d-flex align-items-center">
              <div class="form-check">
                <label class="form-check-label" for="defaultCheck1">
                  Is returnable?
                </label>
                <input
                  class="form-check-input returncheck"
                  [disabled]="!edit"
                  type="checkbox"
                  [(ngModel)]="returnable"
                  id="defaultCheck1"
                />
              </div>
            </div>

            <div class="col" *ngIf="returnable">
              <div class="mb-1">
                <label for="  returnPeriod" class="form-label"
                  >Return Period</label
                >
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="returnPeriod"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="returnPeriod"
                  placeholder="Enter Return Period"
                  oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 2) this.value = this.value.slice(0, 2);"
                />
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6"><label for="">Order Cancellation:</label></div>

            <div class="col">
              <div class="form-check">
                <input
                [disabled]="!edit"
                  class="form-check-input"
                  [(ngModel)]="orderCancellation"
                  [ngModelOptions]="{ standalone: true }"
                  type="radio"
                  name="flexRadioDefaul3"
                  id="flexRadioDefault3"
                  value="Yes"
                />
                <label class="form-check-label" for="flexRadioDefault3"
                  >Yes
                </label>
              </div>
            </div>
            <div class="col">
              <div class="form-check">
                <input
                [disabled]="!edit"
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault3"
                  id="flexRadioDefault3"
                  [(ngModel)]="orderCancellation"
                  [ngModelOptions]="{ standalone: true }"
                  value="No"
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  No
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="cgst" class="form-label">CGST (%) </label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="cgst"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="cgst"
                  maxlength="4"
                  placeholder="Enter CGST"
                  pattern="^\d+(\.\d{1,2})?$"
                  (keypress)="validateNumberInput($event)"
                />
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <label for=" sgst" class="form-label">SGST (%)</label>
                <input
                  type="text"
                  [disabled]="!edit"
                  [(ngModel)]="sgst"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  id="sgst"
                  maxlength="4"
                  placeholder="Enter SGST"
                  pattern="^\d+(\.\d{1,2})?$"
                  (keypress)="validateNumberInput($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col card p-4 m-3">
          <div class="card p-2 mb-2">
            <h6>Product Specification </h6>

            <div class="row ps-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  productLength" class="form-label">
                    Length (cm)
                  </label>

                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productLength"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productLength"
                    maxlength="5"
                    placeholder="Enter length"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="productBreadth" class="form-label"
                    >Breadth(cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productBreadth"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productBreadth"
                    maxlength="5"
                    placeholder="Enter Breadth"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for="  ProductHeight" class="form-label"
                    >Height (cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productHeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productHeight"
                    maxlength="5"
                    placeholder="Enter Height"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row p-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  ProductWeight" class="form-label"
                    >Product Weight (kg)
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="productWeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="productWeight"
                    maxlength="5"
                    placeholder="Enter Product Weight"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-2">
            <h6>Package Specification</h6>
            <div class="row ps-2">
              <div class="col">
                <div class="mb-3">
                  <label for="packageLength" class="form-label">
                    Length (cm)</label
                  >

                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageLength"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageLength"
                    maxlength="5"
                    placeholder="Enter length"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="packageBreadth" class="form-label"
                    >Breadth(cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageBreadth"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageBreadth"
                    maxlength="5"
                    placeholder="Enter Breadth"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <label for=" packageHeight" class="form-label"
                    >Height (cm)</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageHeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageHeight"
                    maxlength="5"
                    placeholder="Enter Height"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>

            <div class="row p-2">
              <div class="col">
                <div class="mb-3">
                  <label for="  packageWeight" class="form-label"
                    >Package Weight (kg)
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="packageWeight"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="packageWeight"
                    maxlength="5"
                    placeholder="Enter Package Weight"
                    pattern="^\d+(\.\d{1,2})?$"
                    (keypress)="validateNumberInput($event)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-3">
            <h6>Order Quantity</h6>
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="  minQuantity" class="form-label">Minimum </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="minimumOrderQuantity"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="minimumOrderQuantity"
                    maxlength="50"
                    placeholder="Enter Minimum Order Quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                  />
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <label for="  maxOrderQuantity" class="form-label"
                    >Maximum
                  </label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="maximumOrderQuantity"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="maximumOrderQuantity"
                    maxlength="50"
                    placeholder="Enter Maximum Order Quantity"
                    oninput="this.value = this.value.replace(/[^0-9]/g, ''); if (this.value.length > 3) this.value = this.value.slice(0, 3);"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="card p-2 mb-3">
            <div class="row">
              <div class="col">
                <!--Image upload section starts-->
                <div
                  *ngIf="
                    (checkProductImage &&
                      checkProductImage.endsWith('_thumbnailImage.jpg')) ||
                    checkProductImage == ''
                  "
                >
                  <div class="row">
                    <label>
                      <h6>Upload Images:<span style="color: red">*</span></h6>
                    </label>
                  </div>
                  <div class="container p-3">
                    <div class="row">
                      <div class="col">
                        <div class="imagePreview">
                          <img
                            class="uploadedImage"
                            [src]="
                              selectedImageUrls[0] ||
                              productImage1 ||
                              defaultImages
                            "
                            alt="selected images"
                            (click)="fileInput1.click()"
                          />
                          <button
                          [disabled]="!edit"
                            type="button"
                            (click)="fileInput1.click()"
                            class="btn btn-light imageUploadButtons btn-sm transparent-button"
                          >
                            Front side
                            <span style="color: red">*</span>
                          </button>
                          <input
                            type="file"
                            accept="image/*"
                            #fileInput1
                            style="display: none"
                            (change)="onImageSelected($event, 0)"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <ng-container>
                          <!-- Show "+" sign when the first image is not selected -->
                          <div
                            class="add-image-placeholder"
                            *ngIf="!selectedImageUrls[0] && !productImage1"
                          >
                            <i class="fas fa-plus"></i>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="selectedImageUrls[0] || productImage1"
                        >
                          <div class="imagePreview">
                            <img
                              class="uploadedImage"
                              [src]="
                                selectedImageUrls[1] ||
                                productImage2 ||
                                defaultImages
                              "
                              alt="selected images"
                              (click)="fileInput2.click()"
                            />
                            <button
                            [disabled]="!edit"
                              type="button"
                              (click)="fileInput2.click()"
                              class="btn btn-light imageUploadButtons btn-sm transparent-button"
                            >
                              Back side
                              <span style="color: red">*</span>
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              #fileInput2
                              style="display: none"
                              (change)="onImageSelected($event, 1)"
                            />
                          </div>
                        </ng-container>
                      </div>
                      <div class="col">
                        <ng-container>
                          <!-- Show "+" sign when the first image is not selected -->
                          <div
                            class="add-image-placeholder"
                            *ngIf="!selectedImageUrls[1] && !productImage2"
                          >
                            <i class="fas fa-plus"></i>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="selectedImageUrls[1] || productImage2"
                        >
                          <div class="imagePreview">
                            <img
                              class="uploadedImage"
                              [src]="
                                selectedImageUrls[2] ||
                                productImage3 ||
                                defaultImages
                              "
                              alt="selected images"
                              (click)="fileInput3.click()"
                            />
                            <button
                            [disabled]="!edit"
                              type="button"
                              (click)="fileInput3.click()"
                              class="btn btn-light imageUploadButtons btn-sm transparent-button"
                            >
                              Select Image
                              <span style="color: red">*</span>
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              #fileInput3
                              style="display: none"
                              (change)="onImageSelected($event, 2)"
                            />
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Image upload section ends-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col"></div>
            <div class="col">
              <div
                *ngIf="spinnerButton"
                class="spinner-border text-success"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                *ngIf="!spinnerButton"
                (click)="updateProducts()"
                type="button"
                class="btn btn-success"
               
                style="width: 100%; margin-bottom: 105px"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--first page ends-->
    <br />

    <!--second page starts-->
    <div class="row" *ngIf="selectCatagory">
      <div class="col p-0">
        <div class="d-flex">
          <div class="nav scrollable-container">
            <div
              class="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                class="nav-link selectCategoryTabs active"
                id="v-pills-foodProducts-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-foodProducts"
                type="button"
                role="tab"
                aria-controls="v-pills-foodProducts"
                aria-selected="true"
                (click)="scrollToTop()"
              >
                Food Products
              </button>

              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-personalCare-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-personalCare"
                type="button"
                role="tab"
                aria-controls="v-pills-personalCare"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Personal Care
              </button>

              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-fashion-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-fashion"
                type="button"
                role="tab"
                aria-controls="v-pills-fashion"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Fashion
              </button>

              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-kitchen-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-kitchen"
                type="button"
                role="tab"
                aria-controls="v-pills-kitchen"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Home & Kitchen
              </button>

              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-jewel-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-jewel"
                type="button"
                role="tab"
                aria-controls="v-pills-jewel"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Jewellery & Accessories
              </button>
              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-stationary-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-stationary"
                type="button"
                role="tab"
                aria-controls="v-pills-stationary"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Stationary & Toys
              </button>

              <button
                class="nav-link selectCategoryTabs"
                id="v-pills-electronics-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-electronics"
                type="button"
                role="tab"
                aria-controls="v-pills-electronics"
                aria-selected="false"
                (click)="scrollToTop()"
              >
                Electronics
              </button>
            </div>
          </div>
          <div class="tab-content" id="v-pills-tabContent">
            <!--foodProducts start-->
            <div
              class="tab-pane fade show active"
              id="v-pills-foodProducts"
              role="tabpanel"
              aria-labelledby="v-pills-foodProducts-tab"
            >
              <div class="container-fluid">
                <br />

                <div class="row producttextalign">
                  <h6>Snacks & Bakery Items</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/snackFoodBakery/chips.jpeg"
                              alt="Chips"
                              id="ID_CHI"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHI'
                              }"
                              (click)="
                                selectButton('ID_CHI', 'Food Products/Chips')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Chips</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/snackFoodBakery/crakers.jpeg"
                              alt="Crakers"
                              id="ID_CRA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRA'
                              }"
                              (click)="
                                selectButton('ID_CRA', 'Food Products/Crakers')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Crackers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/snackFoodBakery/cakeandbread.jpeg"
                              alt="Cake & Breads"
                              id="ID_CAB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAB'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CAB',
                                  'Food Products/Cake&Breads'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Cakes & Breads</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/snackFoodBakery/biscuit.jpeg"
                              alt="Biscuits & Cookies"
                              id="ID_BIS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BIS'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BIS',
                                  'Food Products/Biscuits&Cookies'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Biscuits & Cookies</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/snackFoodBakery/cakeandbread.jpeg"
                              alt="sweets"
                              id="ID_SWT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SWT'
                              }"
                              (click)="
                                selectButton('ID_SWT', 'Food Products/Sweets')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sweets</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Dryfruits Nuts & Seeds</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/dryFruitsNutsSeeds/dates.jpeg"
                              alt="Dates"
                              id="ID_DAT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_DAT'
                              }"
                              (click)="
                                selectButton('ID_DAT', 'Food Products/Dates')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Dates</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/dryFruitsNutsSeeds/pumpkinseeds.jpeg"
                              alt="Chips & Snacks"
                              id="ID_CHS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHS'
                              }"
                              (click)="
                                selectButton('ID_CHS', 'Food Products/Seeds')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Seeds</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/dryFruitsNutsSeeds/cashews.jpeg"
                              alt="Meals"
                              id="ID_CAS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAS'
                              }"
                              (click)="
                                selectButton('ID_CAS', 'Food Products/Nuts')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Nuts</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Dairy Products</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/Chocolate.jpeg"
                              alt="Chocolate"
                              id="ID_MCH"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MCH'
                              }"
                              (click)="
                                selectButton(
                                  'ID_MCH',
                                  'Food Products/Chocolate'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Chocolates</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/Curd.jpeg"
                              alt="Curd"
                              id="ID_CUR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CUR'
                              }"
                              (click)="
                                selectButton('ID_CUR', 'Food Products/Curd')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Curd</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/Milk.jpeg"
                              alt="Milk"
                              id="ID_MLK"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MLK'
                              }"
                              (click)="
                                selectButton('ID_MLK', 'Food Products/Milk')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Milk</p>
                  </div>
                </div>

                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/kudumbashreeghee.jpeg"
                              alt="Ghee"
                              id="ID_GHE"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_GHE'
                              }"
                              (click)="
                                selectButton('ID_GHE', 'Food Products/Ghee')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Ghee</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/butter.jpeg"
                              alt="Butter"
                              id="ID_SAB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SAB'
                              }"
                              (click)="
                                selectButton('ID_SAB', 'Food Products/Butter')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Butter</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/DairyProducts/Paneer.jpeg"
                              alt="Paneer"
                              id="ID_PUM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PUM'
                              }"
                              (click)="
                                selectButton('ID_PUM', 'Food Products/Paneer')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Paneer</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Instant Mix & Health Mix</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/tiffinmix.jpeg"
                              alt="TiffinMix"
                              id="ID_DIM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_DIM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_DIM',
                                  'Food Products/Tiffin Mix'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Tiffin Mix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/masalamix.jpeg"
                              alt="Masala Mix"
                              id="ID_SAM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SAM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SAM',
                                  'Food Products/Masala Mix'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Masala Mix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/payasammix.jpeg"
                              alt="Payasam Mix"
                              id="ID_ADA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_ADA'
                              }"
                              (click)="
                                selectButton(
                                  'ID_ADA',
                                  'Food Products/Payasam Mix'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Payasam Mix</p>
                  </div>
                </div>

                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/healthmix.jpeg"
                              alt="Health Mix"
                              id="ID_HEA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HEA'
                              }"
                              (click)="
                                selectButton(
                                  'ID_HEA',
                                  'Food Products/Health Mix'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Health Mix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/cakemix.jpeg"
                              alt="Cake Mix"
                              id="ID_CAM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CAM'
                              }"
                              (click)="
                                selectButton('ID_CAM', 'Food Products/Cake Mix')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Cake Mix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/dessertmix.jpeg"
                              alt="Dessert Mix"
                              id="ID_APP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_APP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_APP',
                                  'Food Products/Dessert Mix'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Dessert Mix</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/cakemix.jpeg"
                              alt="Nutrimix"
                              id="ID_NUT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NUT'
                              }"
                              (click)="
                                selectButton('ID_NUT', 'Food Products/Nutrimix')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Nutrimix</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/instantmixes/dessertmix.jpeg"
                              alt="BabyFoods"
                              id="ID_BAB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAB'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BAB',
                                  'Food Products/Baby Foods'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Baby Foods</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Pickles & Powders</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/picklesandpowders/Chammanthipodi.jpeg"
                              alt="Powders"
                              id="ID_CHA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHA'
                              }"
                              (click)="
                                selectButton('ID_CHA', 'Food Products/Powders')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Powders</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/picklesandpowders/mangopickle.jpeg"
                              alt="Veg"
                              id="ID_VEG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_VEG'
                              }"
                              (click)="
                                selectButton('ID_VEG', 'Food Products/Veg')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Veg</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/picklesandpowders/nonvegpickle.jpeg"
                              alt="Non-Veg"
                              id="ID_NON"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NON'
                              }"
                              (click)="
                                selectButton('ID_NON', 'Food Products/Non-Veg')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Non-Veg</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/picklesandpowders/nonvegpickle.jpeg"
                              alt="Mixed"
                              id="ID_MAN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MAN'
                              }"
                              (click)="
                                selectButton('ID_MAN', 'Food Products/Mixed')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Mixed</p>
                  </div>
                </div>

                <div class="row producttextalign">
                  <h6>Ready Masalas & Spices</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Readymasalasandspices/chilli powder.jpeg"
                              alt="Chilli Powder"
                              id="ID_CHP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CHP',
                                  'Food Products/Chilli Powder'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Chilli Powder</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Readymasalasandspices/turmeric powder.jpeg"
                              alt="Turmeric Powder"
                              id="ID_TUR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_TUR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_TUR',
                                  'Food Products/Turmeric Powder'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Turmeric Powder</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Readymasalasandspices/coriander powder.jpeg"
                              alt="Corriander Powder"
                              id="ID_COR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COR',
                                  'Food Products/Corriander Powder'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Corriander Powder</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Readymasalasandspices/masala powder.jpeg"
                              alt="Masala"
                              id="ID_MEM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MEM'
                              }"
                              (click)="
                                selectButton('ID_MEM', 'Food Products/Masala')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Masala</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Readymasalasandspices/spices.jpeg"
                              alt="Spices"
                              id="ID_PEP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PEP'
                              }"
                              (click)="
                                selectButton('ID_PEP', 'Food Products/Spices')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Spices</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Sugar Jaggery & Salt</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sugarjaggerysalt/sugar.jpeg"
                              alt="Sugar"
                              id="ID_SUG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SUG'
                              }"
                              (click)="
                                selectButton('ID_SUG', 'Food Products/Sugar')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Sugar</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sugarjaggerysalt/brownsugar.jpeg"
                              alt="Brown Sugar"
                              id="ID_BRS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BRS'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BRS',
                                  'Food Products/Brown Sugar'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Brown Sugar</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sugarjaggerysalt/jaggerypowder.jpeg"
                              alt="Jaggery Powder"
                              id="ID_JAG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_JAG'
                              }"
                              (click)="
                                selectButton(
                                  'ID_JAG',
                                  'Food Products/Jaggery Powder'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Jaggery Powder</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sugarjaggerysalt/jaggeryblock.jpeg"
                              alt="Round Block Jaggery"
                              id="ID_RBJ"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_RBJ'
                              }"
                              (click)="
                                selectButton(
                                  'ID_RBJ',
                                  'Food Products/Round Block Jaggery'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Round Block Jaggery</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/sugarjaggerysalt/crystalsalt.jpeg"
                              alt="Crystal Salt"
                              id="ID_CRY"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRY'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CRY',
                                  'Food Products/Crystal Salt'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Crystal Salt</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Edible Oils</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/coconutoil.jpeg"
                              alt="Coconut Oil"
                              id="ID_COC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COC'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COC',
                                  'Food Products/Coconut Oil'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Coconut Oil</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/sunfloweroil.jpeg"
                              alt="Sunflower Oil"
                              id="ID_SUN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SUN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SUN',
                                  'Food Products/Sunflower Oil'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>SunFlower Oil</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/mustardoil.jpeg"
                              alt="Mustard Oil"
                              id="ID_MUS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MUS'
                              }"
                              (click)="
                                selectButton(
                                  'ID_MUS',
                                  'Food Products/Mustard Oil'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Mustard Oil</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/ricebranoil.jpeg"
                              alt="RiceBran Oil"
                              id="ID_RIB"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_RIB'
                              }"
                              (click)="
                                selectButton(
                                  'ID_RIB',
                                  'Food Products/RiceBran Oil'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>RiceBran Oil</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/oliveoil.jpeg"
                              alt="Olive Oil"
                              id="ID_OLI"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_OLI'
                              }"
                              (click)="
                                selectButton(
                                  'ID_OLI',
                                  'Food Products/Olive Oil'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Olive Oil</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/groundnutoil.jpeg"
                              alt="Groundnut Oil"
                              id="ID_GRN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_GRN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_GRN',
                                  'Food Products/Groundnut Oil'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Groundnut Oil</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/edibleoil/sesameoil.jpeg"
                              alt="Sesame Oil"
                              id="ID_SEO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SEO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SEO',
                                  'Food Products/Sesame Oil'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sesame Oil</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Rice, Flour & Pulses</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Flour/atta.jpeg"
                              alt="Flour"
                              id="ID_CFL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CFL'
                              }"
                              (click)="
                                selectButton('ID_CFL', 'Food Products/Flour')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Flours</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/Pulses/lobia.jpeg"
                              alt="Pulses"
                              id="ID_GRP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_GRP'
                              }"
                              (click)="
                                selectButton('ID_GRP', 'Food Products/Pulses')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Pulses</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/rice/rice.jpeg"
                              alt="Rice"
                              id="ID_MAT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MAT'
                              }"
                              (click)="
                                selectButton('ID_MAT', 'Food Products/Rice')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rice</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Tea & Coffee Beverages</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/teacoffeebeverages/coffee.jpeg"
                              alt="Coffee"
                              id="ID_COF"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COF'
                              }"
                              (click)="
                                selectButton('ID_COF', 'Food Products/Coffee')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Coffee</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/teacoffeebeverages/tea.jpeg"
                              alt="Tea"
                              id="ID_TEA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_TEA'
                              }"
                              (click)="
                                selectButton('ID_TEA', ' Food Products/Tea')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Tea</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/teacoffeebeverages/beverages.jpeg"
                              alt="SoftDrinks"
                              id="ID_SQU"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SQU'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SQU',
                                  'Food Products/Soft Drinks'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Soft Drinks</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/teacoffeebeverages/coffee.jpeg"
                              alt="Squash"
                              id="ID_SQA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SQA'
                              }"
                              (click)="
                                selectButton('ID_SQA', 'Food Products/Squash')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Squash</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Jams, Honey & Spreads</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/jamshoneyspreads/jams.jpeg"
                              alt="Jams"
                              id="ID_JAM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_JAM'
                              }"
                              (click)="
                                selectButton('ID_JAM', 'Food Products/Jams')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Jams</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/jamshoneyspreads/honey.jpeg"
                              alt="Honey"
                              id="ID_HON"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HON'
                              }"
                              (click)="
                                selectButton('ID_HON', 'Food Products/Honey')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Honey</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/jamshoneyspreads/spreads.jpeg"
                              alt="Spreads"
                              id="ID_PEA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PEA'
                              }"
                              (click)="
                                selectButton('ID_PEA', 'Food Products/Spreads')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Spreads</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Millets</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/millets/millet.jpeg"
                              alt="Millets"
                              id="ID_MLT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MLT'
                              }"
                              (click)="
                                selectButton('ID_MLT', 'Food Products/Millets')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Millets</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Fresh Vegetables & Fruits</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/vegetables/vegetables.jpeg"
                              alt="Fresh veg"
                              id="ID_FVG"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FVG'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FVG',
                                  'Food Products/Fresh Vegetables'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Fresh Vegetables</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/vegetables/vegetables.jpeg"
                              alt="Fruits"
                              id="ID_FRU"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FRU'
                              }"
                              (click)="
                                selectButton('ID_FRU', 'Food Products/Fruits')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Fruits</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/vegetables/vegetables.jpeg"
                              alt="Muringa Products"
                              id="ID_FRU"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FRU'
                              }"
                              (click)="
                                selectButton('ID_FRU', 'Food Products/Fruits')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Muringa Products</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- personal care -->
            <div
              class="tab-pane fade"
              id="v-pills-personalCare"
              role="tabpanel"
              aria-labelledby="v-pills-personalCare-tab"
            >
              <div class="container-fluid">
                <br />
                <div class="row producttextalign">
                  <h6>Skin Care</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/skincare/facewashandscrub.jpeg"
                              alt="facewash&scrubs"
                              id="ID_FAW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FAW'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FAW',
                                  'Personal Care/FaceWash & Scrubs'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>FaceWash & Scrubs</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/skincare/creamsandmoisturizer.jpeg"
                              alt="Cream & Moisturizer"
                              id="ID_CRM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRM'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CRM',
                                  'Personal Care/Cream & Moisturizer'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Creams & Moisturizers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/skincare/bodylotionsandbodyoils.jpeg"
                              alt="bodylotions and oils"
                              id="ID_BLO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BLO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BLO',
                                  'Personal Care/Body Lotion & Oils'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Body Lotion & Oils</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/skincare/sunscreenlotions.jpeg"
                              alt="sunscreens"
                              id="ID_SSR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SSR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SSR',
                                  'Personal Care/Sunscreens'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sunscreens</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Hair Care</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/haircare/shampooandconditioner.jpeg"
                              alt="Shampoos&Conditioner"
                              id="ID_SHA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SHA'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SHA',
                                  'Personal Care/Shampoos & Conditioners'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Shampoos & Conditioners</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/haircare/hairoilserumandgel.jpeg"
                              alt="HairOils Serums & Gels "
                              id="ID_HAO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HAO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_HAO',
                                  'Personal Care/HairOils Serums & Gels'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>HairOils Serums & Gels</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/haircare/hennapowder.jpeg"
                              alt="Colors & Hennas"
                              id="ID_COL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COL'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COL',
                                  'Personal Care/Colors & Hennas'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Colors & Hennas</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Makeup</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/makeup/foundationandconcealers.jpeg"
                              alt="Foundation & Concealers"
                              id="ID_FON"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FON'
                              }"
                              (click)="
                                selectButton(
                                  'ID_FON',
                                  'Personal Care/Foundation & Concealers'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Foundation & Concealers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/makeup/compactandprimers.jpeg"
                              alt="Compact & Primers"
                              id="ID_COP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_COP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_COP',
                                  'Personal Care/Compact & Primers'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Compact & Primers</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/makeup/eyeshadoweyeliner.jpeg"
                              alt="Kajals & Eyeliners"
                              id="ID_KAJ"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_KAJ'
                              }"
                              (click)="
                                selectButton(
                                  'ID_KAJ',
                                  'Personal Care/Kajals & Eyeliners'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Kajals Eyeliners Eyeshadows</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/makeup/lipsticklipliner.jpeg"
                              alt="Lipstick LipLiner LipBalm"
                              id="ID_LST"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_LST'
                              }"
                              (click)="
                                selectButton('ID_LST', 'Personal Care/Lipstick')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Lipsticks LipLiners LipBalms</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/makeup/nailpolish.jpeg"
                              alt="NailPolish"
                              id="ID_NAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_NAP',
                                  'Personal Care/Nail Polish'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Nail Polish</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Fragrance</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fragrance/perfumes.jpeg"
                              alt="Perfumes"
                              id="ID_PER"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PER'
                              }"
                              (click)="
                                selectButton('ID_PER', 'Personal Care/Perfumes')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Perfumes</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fragrance/bodyrollons.jpeg"
                              alt="Rollons"
                              id="ID_ROL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_ROL'
                              }"
                              (click)="
                                selectButton('ID_ROL', 'Personal Care/Rollons')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rollons</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fragrance/bodyspray.jpeg"
                              alt="Body Spray"
                              id="ID_BOS"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOS'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BOS',
                                  'Personal Care/Body Spray'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Body Spray</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Bath & Body</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/bodywash.jpeg"
                              alt="Body Wash"
                              id="ID_BOW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOW'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BOW',
                                  'Personal Care/Body Wash'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Body Wash</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/soaps.jpeg"
                              alt="Soaps"
                              id="ID_SOA"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SOA'
                              }"
                              (click)="
                                selectButton('ID_SOA', 'Personal Care/Soaps')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Soaps</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/bodyoils.jpeg"
                              alt="Body Oils"
                              id="ID_BOL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BOL'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BOL',
                                  'Personal Care/Body Oils'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Body Oils</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/bathaccessories.jpeg"
                              alt="Bathing Accessories"
                              id="ID_BAC"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAC'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BAC',
                                  'Personal Care/Bathing Accessories'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bathing Accessories</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/bathaccessories.jpeg"
                              alt="Handwash"
                              id="ID_HAW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HAW'
                              }"
                              (click)="
                                selectButton('ID_HAW', 'Personal Care/Handwash')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Handwash</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Sanitary Pads</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/bath&body/bathaccessories.jpeg"
                              alt="Sanitary Pads"
                              id="ID_SAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_SAP',
                                  'Personal Care/Sanitary Pads'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Sanitary Pads</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- fashion -->
            <div
              class="tab-pane fade"
              id="v-pills-fashion"
              role="tabpanel"
              aria-labelledby="v-pills-fashion-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Men's wear"
                              id="ID_MEN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_MEN',
                              }"
                              (click)="
                                selectButton('ID_MEN', 'Fashion/Men\'s wear')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Men's wear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Ladies Wear"
                              id="ID_WOM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_WOM'
                              }"
                              (click)="
                                selectButton('ID_WOM', 'Fashion/Ladies Wear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Ladies Wear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/kids.jpeg"
                              alt="Kids Wear"
                              id="ID_KID"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_KID'
                              }"
                              (click)="
                                selectButton('ID_KID', 'Fashion/Kids Wear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Kids Wear</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Men's wear"
                              id="ID_HLM"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_HLM',
                              }"
                              (click)="
                                selectButton('ID_HLM', 'Fashion/HandLoom')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>HandLoom</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="FootWear"
                              id="ID_FTW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_FTW'
                              }"
                              (click)="
                                selectButton('ID_FTW', 'Fashion/FootWear')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>FootWear</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/kids.jpeg"
                              alt="Bags & Wallets"
                              id="ID_BAW"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BAW'
                              }"
                              (click)="
                                selectButton('ID_BAW', 'Fashion/Bags & Wallets')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bags & Wallets</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- kitchen -->
            <div
              class="tab-pane fade"
              id="v-pills-kitchen"
              role="tabpanel"
              aria-labelledby="v-pills-kitchen-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Home Decor</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Furnitures"
                              id="ID_FUR"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_FUR',
                            }"
                              (click)="
                                selectButton(
                                  'ID_MEN',
                                  'Home & Kitchen/Furnitures'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Furnitures</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Kitchen Accessories</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Iron & Steel"
                              id="ID_IAS"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_IAS',
                            }"
                              (click)="
                                selectButton(
                                  'ID_IAS',
                                  'Home & Kitchen/Iron & Steel'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Iron & Steel</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Crockery Items"
                              id="ID_CRO"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CRO'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CRO',
                                  'Home & Kitchen/Crockery Items'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Crockery Items</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Arts & Crafts</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="wooden"
                              id="ID_WUD"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_WUD',
                            }"
                              (click)="
                                selectButton(
                                  'ID_WUD',
                                  'Home & Kitchen/Iron & Steel'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Wooden Handicraft</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="steel Handicraft"
                              id="ID_STL"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_STL'
                              }"
                              (click)="
                                selectButton(
                                  'ID_STL',
                                  'Home & Kitchen/Steel Handicraft'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Steel Handicraft</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Clay products"
                              id="ID_CLY"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CLY'
                              }"
                              (click)="
                                selectButton(
                                  'ID_CLY',
                                  'Home & Kitchen/Clay products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Clay products</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="wooden"
                              id="ID_NTP"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_NTP',
                            }"
                              (click)="
                                selectButton(
                                  'ID_NTP',
                                  'Home & Kitchen/Nettipattom'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Nettipattom</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="steel Handicraft"
                              id="ID_ARN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_ARN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_ARN',
                                  'Home & Kitchen/Steel Handicraft'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Aranmula Kannadi</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Paper Crafts"
                              id="ID_PAP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_PAP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_PAP',
                                  'Home & Kitchen/Paper Crafts'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Paper Crafts</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Cleaning Supplies</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="cleaning"
                              id="ID_SOD"
                              class="categoryLinks"
                              [ngClass]="{
                              selected: selectedCategoryID === 'ID_SOD',
                            }"
                              (click)="
                                selectButton(
                                  'ID_SOD',
                                  'Home & Kitchen/Soaps & Detergents'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Soaps & Detergents</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="steel Handicraft"
                              id="ID_LOT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_LOT'
                              }"
                              (click)="
                                selectButton('ID_LOT', 'Home & Kitchen/Lotions')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Lotions</p>
                  </div>
                </div>

                <div class="row producttextalign">
                  <h6>Home Textiles</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Towels"
                              id="ID_TOW"
                              class="categoryLinks"
                              [ngClass]="{
                               selected: selectedCategoryID === 'ID_TOW',
                             }"
                              (click)="
                                selectButton('ID_TOW', 'Home & Kitchen/Towels')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Towels</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="bedSheets"
                              id="ID_BED"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BED'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BED',
                                  'Home & Kitchen /Bed Sheets & Pillow Covers'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Bed Sheets & Pillow Covers</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <h6>Gardening</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="indoor"
                              id="ID_IND"
                              class="categoryLinks"
                              [ngClass]="{
                               selected: selectedCategoryID === 'ID_IND',
                             }"
                              (click)="
                                selectButton('ID_IND', 'Home & Kitchen/Indoor')
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Indoor Plants</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Outdoor"
                              id="ID_OUT"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_OUT'
                              }"
                              (click)="
                                selectButton(
                                  'ID_OUT',
                                  'Home & Kitchen /Outdoor Plants'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Outdoor Plants</p>
                  </div>

                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="seeds"
                              id="ID_SED"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_SED'
                              }"
                              (click)="
                                selectButton('ID_SED', 'Home & Kitchen /Seeds')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Seeds</p>
                  </div>
                  <div class="row producttextalign">
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                                alt="Fertilizers"
                                id="ID_FER"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_FER'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_FER',
                                    'Home & Kitchen /Fertilizers'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Fertilizers</p>
                    </div>
                  </div>

                  <div class="row producttextalign">
                    <h6>Eco friendly products</h6>
                    <hr />
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                                alt="Bamboo products"
                                id="ID_BAM"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_BAM'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_BAM',
                                    'Home & Kitchen /Bamboo products'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Bamboo products</p>
                    </div>
                    <div class="col-4">
                      <div class="productButtons">
                        <div class="container-grid">
                          <div class="grid-column">
                            <a
                              ><img
                                src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                                alt="Tribal Products"
                                id="ID_TRI"
                                class="categoryLinks"
                                [ngClass]="{
                                  selected: selectedCategoryID === 'ID_TRI'
                                }"
                                (click)="
                                  selectButton(
                                    'ID_TRI',
                                    'Home & Kitchen /Tribal Products'
                                  )
                                "
                            /></a>
                          </div>
                        </div>
                      </div>
                      <p>Tribal Products</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="v-pills-jewel"
              role="tabpanel"
              aria-labelledby="v-pills-jewel-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Jewellery & Accessories</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Bangles"
                              id="ID_BAN"
                              class="categoryLinks"
                              [ngClass]="{
                            selected: selectedCategoryID === 'ID_BAN',
                          }"
                              (click)="
                                selectButton(
                                  'ID_BAN',
                                  'Jewellery & Accessories/Bangles'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Bangles</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Earings"
                              id="ID_EAR"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_EAR'
                              }"
                              (click)="
                                selectButton(
                                  'ID_EAR',
                                  'Jewellery & Accessories/Earings'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Earings</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/kids.jpeg"
                              alt="Nosepins"
                              id="ID_NOP"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_NOP'
                              }"
                              (click)="
                                selectButton(
                                  'ID_NOP',
                                  'Jewellery & Accessories/Nosepins'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Nosepins</p>
                  </div>
                </div>
                <div class="row producttextalign">
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Anklets"
                              id="ID_ANK"
                              class="categoryLinks"
                              [ngClass]="{
                            selected: selectedCategoryID === 'ID_ANK',
                          }"
                              (click)="
                                selectButton(
                                  'ID_ANK',
                                  'Jewellery & Accessories/Anklets'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Anklets</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Rings"
                              id="ID_RIN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_RIN'
                              }"
                              (click)="
                                selectButton(
                                  'ID_RIN',
                                  'Jewellery & Accessories/Rings'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Rings</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/kids.jpeg"
                              alt="Chains"
                              id="ID_CHN"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_CHN'
                              }"
                              (click)="
                                selectButton('ID_CHN', 'Fashion/Chains')
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Chains</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="v-pills-stationary"
              role="tabpanel"
              aria-labelledby="v-pills-stationary-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Stationaries & Toys</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Umbrella"
                              id="ID_UIB"
                              class="categoryLinks"
                              [ngClass]="{
                          selected: selectedCategoryID === 'ID_UIB',
                        }"
                              (click)="
                                selectButton(
                                  'ID_UIB',
                                  'Stationaries & Toys/Umbrella'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Umbrella</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Ladies.jpeg"
                              alt="Toys"
                              id="ID_TOY"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_TOY'
                              }"
                              (click)="
                                selectButton(
                                  'ID_TOY',
                                  'Stationaries & Toys/Toys'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Toys</p>
                  </div>
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/kids.jpeg"
                              alt="Buds product"
                              id="ID_BUD"
                              class="categoryLinks"
                              [ngClass]="{
                                selected: selectedCategoryID === 'ID_BUD'
                              }"
                              (click)="
                                selectButton(
                                  'ID_BUD',
                                  'Stationaries & Toys/Buds Products'
                                )
                              "
                          /></a>
                        </div>
                      </div>
                    </div>
                    <p>Buds Products</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="v-pills-electronics"
              role="tabpanel"
              aria-labelledby="v-pills-electronics-tab"
            >
              <div class="container-fluid">
                <div class="row producttextalign">
                  <h6>Electronics</h6>
                  <hr />
                  <div class="col-4">
                    <div class="productButtons">
                      <div class="container-grid">
                        <div class="grid-column">
                          <a
                            ><img
                              src="../../assets/subcategoryImages/fashion/Mens.jpeg"
                              alt="Electronics"
                              id="ID_ELE"
                              class="categoryLinks"
                              [ngClass]="{
                        selected: selectedCategoryID === 'ID_ELE',
                      }"
                              (click)="
                                selectButton(
                                  'ID_ELE',
                                  'Stationaries & Toys/Electronics'
                                )
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>Electronics</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--second page ends-->
  </div>
</div>
