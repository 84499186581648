<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <div class="container1 mt-5">
    <div class="d-flex justify-content-center">
      <div class="card shadow-lg p-4" style="width: 28rem">
        <h2 class="text-center mb-4">REGISTRATION FORM</h2>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >ME Unit Name <span style="color: red">*</span></label
                  >
                  <input
                    [disabled]="!edit"
                    type="text"
                    [(ngModel)]="meUnitName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="50"
                    placeholder="Enter ME Unit Name"
                    (keypress)="allowOnlyAlphanumericAndSpace($event)"
                  />
                </div>
              </div>
              <div class="col"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Details</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Phone Number <span style="color: red">*</span></label
                      >
                      <input
                        [disabled]="!edit"
                        type="text"
                        class="form-control"
                        [(ngModel)]="phoneNumber1"
                        [ngModelOptions]="{ standalone: true }"
                        id="meUnitName"
                        placeholder="Enter Phone Number"
                        #phone1
                        (input)="checkphone1(phone1)"
                        (keypress)="validateAge($event)"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >WhatsApp <span style="color: red">*</span></label
                      >
                      <input
                        [disabled]="!edit"
                        type="text"
                        [(ngModel)]="whatsAppNumber"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="10"
                        placeholder="Enter WhatsApp Number"
                        #whatsApp
                        (input)="checkWhatsApp(whatsApp)"
                        (keypress)="validateAge($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="meUnitName" class="form-label">Email ID </label>
                  <input
                    type="email"
                    [disabled]="!edit"
                    [(ngModel)]="email"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="50"
                    placeholder="Enter Email ID"
                  />
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >ME APP ID <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="meAppID"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="25"
                    placeholder="Enter ME App ID"
                    #phone2
                  />
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >FSSAI Registration Number</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="fssaiID"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="14"
                    placeholder="Enter FSSAI Registration Number"
                    (keypress)="validateAge($event)"
                  />
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label">
                    Udyam Registration Number</label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="UdyamRegistrationNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="50"
                    placeholder="Enter Udyam Registration Number"
                  />
                </div>

                <div class="mb-3">
                  <label for="meUnitName" class="form-label">GST Number</label>
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="GSTNumber"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="15"
                    placeholder="Enter GST Number"
                    (keypress)="allowOnlyAlphanumeric($event)"
                  />
                </div>

                <!------CDS group details------>
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >CDS Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="CDSName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter CDS Name"
                        (keypress)="allowOnlyAlphanumericAndSpace($event)"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >CDS Registration Number
                        <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="CDSNumber"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="50"
                        placeholder="Enter CDS Reg.no"
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="meUnitName" class="form-label"
                    >NHG Name <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="nhgName"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    maxlength="25"
                    placeholder="Enter NHG Name"
                    (keypress)="allowOnlyAlphanumericAndSpace($event)"
                  />
                </div>

                <div class="mb-3 mt-1" *ngIf="isStateAdmin() || isSuperAdmin() || isMarketingAdmin()">
                  <label for="districtSelect" class="form-label"
                    >District Admin Role
                    <span style="color: red">*</span></label
                  >
                  <select
                    class="form-select"
                    [disabled]="!edit"
                    id="districtSelect"
                    (change)="onAdminCategorySelected($event)"
                    aria-label="Default select example"
                  >
                    <option value="" disabled selected>
                      Select District Admin Role
                    </option>
                    <!-- Use *ngFor to loop through the districts array and create an option for each item -->
                    <option
                      *ngFor="let adminCategory of adminCategory"
                      [value]="adminCategory.value"
                    >
                      {{ adminCategory.label }}
                    </option>
                  </select>
                </div>
                <!----------------------------->
              </mat-expansion-panel>
            </mat-accordion>

            <br />
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Address</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Building Name <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="buildingName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Building Name"
                      maxlength="50"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Street Name <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="streetName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Street Name"
                      maxlength="50"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Landmark <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="landmark"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Landmark"
                      maxlength="50"
                    />
                  </div>

                  <!-- <div class="container">
                    <div class="row">
                      <div class="col">Old code starts</div>
                    </div>
                  </div>
                  <label for="formFile3" class="form-label"
                    >Select the local bodies
                    <span style="color: red">*</span></label
                  >
                  <div class="row ps-4">
                    <div class="form-check">
                      <input
                        [disabled]="!edit"
                        class="form-check-input"
                        type="radio"
                        name="localBodyType"
                        id="flexRadioDefault1"
                        [(ngModel)]="localBodyType"
                        value="Corporation"
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Corporation
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="!edit"
                        class="form-check-input"
                        type="radio"
                        name="localBodyType"
                        id="flexRadioDefault2"
                        [(ngModel)]="localBodyType"
                        value="Municipality"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Municipality
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        [disabled]="!edit"
                        class="form-check-input"
                        type="radio"
                        name="localBodyType"
                        id="flexRadioDefault3"
                        [(ngModel)]="localBodyType"
                        value="Panchayat"
                      />
                      <label class="form-check-label" for="flexRadioDefault3">
                        Panchayat
                      </label>
                    </div>
                  </div>

                 
                  <div class="mb-3" *ngIf="localBodyType === 'Corporation'">
                    <label for="meUnitName" class="form-label"
                      >Corporation Name <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="localBodyName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Corporation Name"
                      maxlength="50"
                    />
                  </div>

                  <div class="mb-3" *ngIf="localBodyType === 'Municipality'">
                    <label for="meUnitName" class="form-label"
                      >Municipality Name
                      <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="localBodyName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Municipality Name"
                      maxlength="50"
                    />
                  </div>

                  <div class="mb-3" *ngIf="localBodyType === 'Panchayat'">
                    <label for="meUnitName" class="form-label"
                      >Panchayat Name <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="localBodyName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Panchayat Name"
                      maxlength="50"
                    />
                  </div>

                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Block Name <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="blockName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Block Name"
                      maxlength="50"
                    />
                  </div>

                  <div class="container">
                    <div class="row">
                      <div class="col">Old code ends</div>
                    </div>
                  </div> -->

                  <!--Started NEW IMPLEMENTATION FOR BLOCK, MUNICIPALITY, CORPORATION -->
                  <hr>
                  <div class="row">
                    <br />
                    <div class="col">
                      <label class="form-label">
                        <p class="labelText">Select local body</p>
                      </label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="level"
                          id="blockLevel"
                          value="blockLevel"
                          [(ngModel)]="selectedLevel"
                          (change)="onLevelSelection('blockLevel')"
                        />
                        <label class="form-check-label" for="blockLevel"
                          >Panchayat</label
                        >
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="level"
                          id="municipalityLevel"
                          value="municipalityLevel"
                          [(ngModel)]="selectedLevel"
                          (change)="onLevelSelection('municipalityLevel')"
                        />
                        <label class="form-check-label" for="municipalityLevel"
                          >Municipality</label
                        >
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="level"
                          id="municipalCorporation"
                          value="municipalCorporation"
                          [(ngModel)]="selectedLevel"
                          (change)="onLevelSelection('municipalCorporation')"
                        />
                        <label
                          class="form-check-label"
                          for="municipalCorporation"
                          >Municipal Corporation</label
                        >
                      </div>
                    </div>
                    <br />
                  </div>

                  <!--Radio button ends-->

                  <!-- Add new input fields for the additional information -->
                  <div class="row pt-4">
                    <div class="col">
                      <label for="district" class="form-label required">
                        <p class="labelText">District</p>
                      </label>
                      <!-- Dropdown for District -->
                      <select
                        *ngIf="isStateAdmin() || isSuperAdmin()"
                        class="form-select inputBoxes"
                        id="district"
                        [(ngModel)]="selectedDistrict"
                        name="district"
                        (change)="onDistrictChange()"
                      >
                        <option value="" disabled selected>
                          Select District
                        </option>
                        <option
                          *ngFor="let district of districts"
                          [ngValue]="district"
                        >
                          {{ district.name }}
                        </option>
                      </select>

                  
                      <select
                        *ngIf="isDistrictAdmin() || isMarketingAdmin()"
                        class="form-select inputBoxes"
                        id="district"
                        [(ngModel)]="selectedDistrict"
                        name="district"
                        (change)="onDistrictChange()"
                      >
                        <option value="" disabled selected>
                          Select District
                        </option>
                        <option
                          *ngFor="let district of districtOFDistrictAdmin"
                          [ngValue]="district"
                        >
                          <div
                            *ngIf="district.name == districtName"
                            class="div"
                          >
                            {{ district.name }}
                          </div>
                          <!-- {{ district.name }} -->
                        </option>
                      </select>
                    </div>
                  </div>

                  <br />
                  <!-- New Dropdown for Municipality -->
                  <div class="row pt-4" *ngIf="selectedMunicipality">
                    <div class="col">
                      <label for="municipality" class="form-label required">
                        <p class="labelText">Municipality</p>
                      </label>
                      <select
                        class="form-select inputBoxes"
                        id="municipality"
                        [(ngModel)]="selectedMunicipal"
                        name="municipality"
                        (click)="onMunicipalityChange()"
                      >
                        <option value="" disabled selected>
                          Select Municipality
                        </option>
                        <option
                          *ngFor="let municipality of selectedMunicipality"
                          [ngValue]="municipality"
                        >
                          {{ municipality.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <br *ngIf="selectedMunicipality" />
                  <div class="row pt-4" *ngIf="selectedMunicipalCorporation">
                    <div class="col">
                      <label
                        for="districtMunicipalCorporation"
                        class="form-label required"
                      >
                        <p class="labelText">District Municipal Corporation</p>
                      </label>
                      <select
                        class="form-select inputBoxes"
                        id="districtMunicipalCorporation"
                        [(ngModel)]="selectedMunicipalCorp"
                        name="districtMunicipalCorporation"
                        (click)="onMunicipalCorporationChange()"
                      >
                        <option value="" disabled selected>
                          Select District Municipal Corporation
                        </option>
                        <option
                          *ngFor="let mnc of selectedMunicipalCorporation"
                          [ngValue]="mnc"
                        >
                          {{ mnc.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <br *ngIf="selectedMunicipalCorporation" />

                  <div
                    class="row pt-4"
                    *ngIf="selectedDistrict && gramPanchayatsLoaded"
                  >
                    <div class="col">
                      <label for="block" class="form-label required">
                        <p class="labelText">Block</p>
                      </label>
                      <!-- Dropdown for Block -->
                      <select
                        class="form-select inputBoxes"
                        id="block"
                        [(ngModel)]="selectedBlk"
                        name="block"
                        (change)="onBlockChange()"
                      >
                        <option value="" disabled selected>Select Block</option>
                        <option *ngFor="let block of blocks" [ngValue]="block">
                          {{ block.name }}
                        </option>
                      </select>
                    </div>
                    <br />
                  </div>
                  <br *ngIf="selectedDistrict && gramPanchayatsLoaded" />

                  <div class="row pt-4" *ngIf="selectedBlock">
                    <div class="col">
                      <label for="gramPanchayat" class="form-label required">
                        <p class="labelText">Grama Panchayat</p>
                      </label>
                      <!-- Dropdown for Gram Panchayat -->
                      <select
                        class="form-select inputBoxes"
                        id="gramPanchayat"
                        [(ngModel)]="selectedGP"
                        name="gramPanchayat"
                        (change)="onPChange()"
                      >
                        <option value="" disabled selected>
                          Select Grama Panchayat
                        </option>
                        <option
                          *ngFor="let gp of gramPanchayats"
                          [ngValue]="gp"
                        >
                          {{ gp.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <br /><br /><br /><br />
                  <br /><br /><br />
                  <hr>
                  <!--Ended NEW IMPLEMENTATION FOR BLOCK, MUNICIPALITY, CORPORATION -->

                  <div class="row">
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >Ward Name <span style="color: red">*</span></label
                        >
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="wardName"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          placeholder="Enter Ward Name"
                          maxlength="50"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >Ward Number
                        </label>
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="wardNumber"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          placeholder="Enter Ward Number"
                          #ward
                          maxlength="2"
                          (input)="checkAgeLength()"
                          (keypress)="validateAge($event)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="meUnitName" class="form-label"
                      >Pincode <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="pincode"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter Pincode"
                      #ward
                      maxlength="6"
                      (input)="checkPincodeLength()"
                      (keypress)="validateAge($event)"
                    />
                  </div>

                  <!-- <div class="mb-3" *ngIf="isDistrictAdmin()">
                    <label for="meUnitName" class="form-label"
                      >Enter District <span style="color: red">*</span></label
                    >
                    <input
                      [disabled]="!edit"
                      type="text"
                      [(ngModel)]="districtName"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control"
                      id="meUnitName"
                      placeholder="Enter District"
                      disabled
                      style="
                        background-color: rgb(206, 203, 203);
                        border-color: rgb(206, 203, 203);
                      "
                    />
                  </div> -->

                  <!-- <div class="col" *ngIf="isStateAdmin() || isSuperAdmin()">
                    <select
                      [disabled]="!edit"
                      class="form-select"
                      id="districtSelect"
                      (change)="onDistrictSelected($event)"
                      aria-label="Default select example"
                    >
                      <option value="" disabled selected>
                        Select district
                      </option>
                      <option
                        *ngFor="let district of districts"
                        [value]="district.value"
                      >
                        {{ district.label }}
                      </option>
                    </select>
                  </div> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <br />

            <br />
          </div>

          <div class="col">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Bank Account Details</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!------bank details starts ------------>
                <div class="container p-0">
                  <div class="col">
                    <div class="mb-3">
                      <label for="meUnitName" class="form-label"
                        >Account Holders Name
                        <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        [disabled]="!edit"
                        [(ngModel)]="accountHolderName"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        id="meUnitName"
                        maxlength="25"
                        placeholder="Enter Account Holders Name"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >Bank Name <span style="color: red">*</span></label
                        >
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="bankName"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          maxlength="25"
                          placeholder="Enter Bank Name"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >Bank Branch <span style="color: red">*</span></label
                        >
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="bankBranch"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          maxlength="30"
                          placeholder="Enter Branch Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >Account Number
                          <span style="color: red">*</span></label
                        >
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="bankAccountNumber"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          maxlength="25"
                          placeholder="Enter Account Number"
                          (keypress)="validateAge($event)"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >IFSC Code <span style="color: red">*</span></label
                        >
                        <input
                          [disabled]="!edit"
                          type="text"
                          [(ngModel)]="bankIFSCcode"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          maxlength="25"
                          placeholder="Enter IFSC Code"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-3">
                        <label for="meUnitName" class="form-label"
                          >UPI ID</label
                        >
                        <input
                          type="text"
                          [disabled]="!edit"
                          [(ngModel)]="upiID"
                          [ngModelOptions]="{ standalone: true }"
                          class="form-control"
                          id="meUnitName"
                          maxlength="25"
                          placeholder="Enter UPI "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!------------------------------------>
              </mat-expansion-panel>
            </mat-accordion>

            <br />

            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <p style="margin-bottom: 0">
                      <b>Upload Documents</b>
                    </p>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!--CDS registration documents-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="formFileIdProof" class="form-label"
                        >CDS Registration Documents
                        <span style="color: red">*</span></label
                      >
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'cdsDocuments')"
                        accept="application/pdf"
                        id="formFileIdProof"
                      />
                    </div>
                  </div>
                </div>

                <!--Bank pass book -->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="bankPassBook" class="form-label"
                        >Bank Pass Book / Cancelled cheque
                        <span style="color: red">*</span></label
                      >
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'bankPassBook')"
                        accept="application/pdf"
                        id="bankPassBook"
                      />
                    </div>
                  </div>
                </div>

                <!--GST documents-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="gstDocuments" class="form-label"
                        >GST Documents</label
                      >
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'gstDocuments')"
                        accept="application/pdf"
                        id="gstDocuments"
                      />
                    </div>
                  </div>
                </div>

                <!--FSSAI-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="fssai" class="form-label"
                        >FSSAI Documents
                      </label>
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'fssai')"
                        accept="application/pdf"
                        id="fssai"
                      />
                    </div>
                  </div>
                </div>

                <!--GI Tag-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="GITag" class="form-label">GI Tag</label>
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'GITag')"
                        accept="application/pdf"
                        id="GITag"
                      />
                    </div>
                  </div>
                </div>

                <!--tradeMark-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="tradeMark" class="form-label"
                        >Trade Mark</label
                      >
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'tradeMark')"
                        accept="application/pdf"
                        id="tradeMark"
                      />
                    </div>
                  </div>
                </div>

                <!--UdyamRegistrationNumber-->
                <div class="row">
                  <div class="col">
                    <div class="mb-3">
                      <label for="UdyamRegistration" class="form-label"
                        >Udyam Registration Documents</label
                      >
                      <input
                        [disabled]="!edit"
                        class="form-control"
                        type="file"
                        (change)="onFileSelected($event, 'UdyamRegistration')"
                        accept="application/pdf"
                        id="UdyamRegistration"
                      />
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <br />

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="createPassword()"
                    style="width: 100%"
                    [disabled]="!edit"
                  >
                    Generate keyword button <span style="color: red">*</span>
                  </button>
                </div>
              </div>
              <div class="col">
                <div class="mb-3">
                  <input
                    type="text"
                    [disabled]="!edit"
                    [(ngModel)]="keyword"
                    [ngModelOptions]="{ standalone: true }"
                    class="form-control"
                    id="meUnitName"
                    placeholder="Enter Keyword"
                    maxlength="10"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3">
                <div
                  class="spinner-border text-success"
                  style="float: right"
                  role="status"
                  *ngIf="spinner"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <button
                  *ngIf="!spinner"
                  type="submit"
                  (click)="addServiceProvider()"
                  class="btn btn-success createButton"
                >
                  Add Seller
                </button>

                <a
                  *ngIf="showWhatsAppButton"
                  aria-label="Chat on WhatsApp"
                  [href]="
                    'https://wa.me/' +
                    '+91' +
                    sellerwhatsApp +
                    '?text=' +
                    sellerMessage
                  "
                >
                  <img
                    class="whatsAppButton w-100"
                    alt="Chat on WhatsApp"
                    src="assets\buttons\WhatsAppButtonGreenSmall.png"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
