import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthServiceService } from 'src/app/auth-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private Authservice: AuthServiceService
  ) {}

  user: string = null;
  roleType: string = null;
  district: string = null;



  ngOnInit() {
    this.checkUser();
  }

  checkUser() {
    if (this.isStateAdmin()) {
      this.user = 'State Admin';
    } else if (this.isSuperAdmin()) {
      this.user = 'Super Admin';
    } else if (this.isMarketingAdmin()) {
      
      const district = this.Authservice.getDecodedDistrict();
      this.user = 'Marketing Admin - '+ district;

      console.log('the district is',district);
    }
    else if (this.isDistrictAdmin()) {
      this.user = 'District Admin';

      const district = this.Authservice.getDecodedDistrict();
      if (district) {
        console.log('Decoded district value:', district);
        this.district = district
        
        // this.district = district;
      } else {
        console.log('District information not available.');
        this.onLogout();
      }

      const roleType = this.Authservice.getDecodedRoleType();
      if (roleType) {
        console.log('Decoded district value:', roleType);
        
        this.roleType = roleType;
       
        
        
      } else {
        console.log('District information not available.');
       
        // this.onLogout();
      }

      
      this.user = this.district
      

      
     
    } else if (this.isMEUnit()) {
      this.user = 'ME Unit';
    }
  }

  roleTypeToLabel(roleType: string): string {
    switch (roleType) {
      case 'type_1':
        return '- District Farmlivelihood';
      case 'type_2':
        return '- District NULM';
      case 'type_3':
        return '- District ME';
      case 'type_4':
        return '- District SVEP';
      case 'type_5':
        return '- District Tribal';
      case 'type_6':
        return '- District SD';
      case 'type_7':
        return '- District AH';
      default:
        return '';
    }
  }
  


  toggleFullScreen() {
    if (!document.fullscreenElement) {
      // Enter full-screen mode
      document.documentElement.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  onLogout() {
    this.Authservice.logOut();
  }

  isMEUnit(): boolean {
    return this.Authservice.isAuthenticated() && this.Authservice.isMEUnit();
  }

  isSuperAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isSuperAdmin()
    );
  }
  isStateAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isStateAdmin()
    );
  }
  isDistrictAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isDistrictAdmin()
    );
  }


  isMarketingAdmin(): boolean {
    return (
      this.Authservice.isAuthenticated() && this.Authservice.isMarketingDistrictAdmin()
    );
  }
}
