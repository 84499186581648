<app-side-bar></app-side-bar>
<app-header></app-header>

<div class="container p-4 mainContainer">
  <!--header-->
  <div class="row">
    <div class="col">
      <p class="analytics"><u>Products Listings</u></p>
    </div>

    <div class="col">
      <p class="pageReference">Products > Products Listing</p>
    </div>
  </div>

  <div class="row card headerCard p-1 m-1">
    <div class="col pt-2">
      <mat-form-field appearance="fill" style="margin-right: 10px">
        <mat-label>Enter your start date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [(ngModel)]="startDate"
          [matDatepicker]="firstPicker"
          (dateChange)="onDateChange($event, 'First Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="firstPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #firstPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Enter your end date</mat-label>
        <input
          matInput
          [max]="maxDate"
          [matDatepicker]="secondPicker"
          [(ngModel)]="endDate"
          (dateChange)="onDateChange($event, 'Second Date')"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="secondPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #secondPicker></mat-datepicker>
      </mat-form-field>

      <button
        *ngIf="!spinnerForSearch"
        (click)=" checkUser()"
        style="margin-left: 15px"
        type="button"
        class="btn btn-success"
      >
        Search
      </button>

      <div
        *ngIf="spinnerForSearch"
        style="margin-left: 15px"
        class="spinner-border text-success"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <!-- <div class="row mb-4" *ngIf="!isDistrictAdmin()">
    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="pendingCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get pending request
        </label>
      </div>
      <button
        style="width: 100%"
        type="button"
        (click)="getAllPendingService()"
        class="btn btn-outline-primary"
      >
        Pending
      </button>
    </div>

    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="processingCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get processing request
        </label>
      </div>
      <button
        style="width: 100%"
        type="button"
        (click)="getAllProcessingService()"
        class="btn btn-outline-primary"
      >
        Processing
      </button>
    </div>

    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="assignedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get assigned request
        </label>
      </div>
      <button
        style="width: 100%"
        type="button"
        (click)="getAllAssignedService()"
        class="btn btn-outline-primary"
      >
        Assigned
      </button>
    </div>

    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="acceptedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get accepted request
        </label>
      </div>
      <button
        style="width: 100%"
        type="button"
        (click)="getAllAcceptedService()"
        class="btn btn-outline-primary"
      >
        Accepted
      </button>
    </div>

    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="completedCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get completed request
        </label>
      </div>
      <button
        style="width: 100%"
        type="button"
        (click)="getAllCompletedService()"
        class="btn btn-outline-primary"
      >
        Completed
      </button>
    </div>

    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          [checked]="cancelledCheckBox"
        />
        <label class="form-check-label" for="flexRadioDefault2">
          Get cancelled request
        </label>
      </div>
      <button
        type="button"
        style="width: 100%"
        (click)="getAllCancelledService()"
        class="btn btn-outline-primary"
      >
        Cancelled
      </button>
    </div>
  </div> -->

  <div class="row mt-3">
    <div class="col">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Product ID</th>
            <th scope="col">Name</th>
            <th scope="col">Admin</th>
            <th scope="col">District</th>
            <th scope="col">Date</th>
            <th scope="col">Views</th>
            <th scope="col">Contacts</th>
            <th scope="col">Active</th>
            <th scope="col">District Verification</th>
            <th scope="col">State Approval</th>
            
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody *ngFor="let productList of productList; let i = index">
          <tr>
            <th scope="row">{{ i + 1 }}</th>
            <td>
               {{ productList.productID }}
            </td>
            <td>
              {{ productList.productName }}
              <br>
              <span *ngIf="isProductRejected(productList.rejectStatus)" class="badge rounded-pill bg-danger">
                {{ getReadableStatus(productList.rejectStatus) }}
              </span>
            </td>
            <td>{{ roleTypeToLabel(productList.userCategory) }}</td>
            <td>{{ productList.userDistrict }}</td>
            <td>
           
              <i class="fa-solid fa-calendar-days"></i>

              {{ convertUtcToIst(productList.productRegistrationDate) }}
            </td>
            <td>{{ productList.views }}</td>
            <td>
              
              {{ productList.contacts }}
            </td>
            <td>
              <span
                *ngIf="!productList.instock"
                class="badge rounded-pill bg-danger"
                >Inactive</span
              >
              <span
                *ngIf="productList.instock"
                class="badge rounded-pill bg-success"
                >Active</span
              >
            </td>
            <td> 
             
              <span
              *ngIf="!productList.verifiedStatus"
              class="badge rounded-pill bg-danger"
              >Not Verified</span
            >
            <span
              *ngIf="productList.verifiedStatus"
              class="badge rounded-pill bg-success"
              >Verified</span
              >
            </td>
            <td>
             
              <span
              *ngIf="!productList.approvalStatus"
              class="badge rounded-pill bg-danger"
              >Not Approved</span
            >
            <span
              *ngIf="productList.approvalStatus"
              class="badge rounded-pill bg-success"
              >Approved</span>
              
            </td>
           
            <td>
              <button
                (click)="goToProductDescription(productList.productID)"
                type="button"
                class="btn btn-link"
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      


    </div>
  </div>
</div>
